import React, { useState, useEffect } from "react";
import Button from "../../customComponents/Button";
import Table from "../../customComponents/Table";
import FilterSelect from "../../customComponents/FilterSelect";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "../../customComponents/DatePicker";
import log from "../../assets/img/Itinerary/log.png";
import { post } from "../../utils/api";
import { generateItineraryPDF } from "../pdfGenerator";

const AllItinerary = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [searchText, setSearchData] = useState("");
  const [packageOptions, setPackageOptions] = useState([]);
  const [packageValue, setPackageValue] = useState("");
  const [institutionValue, setInstitutionValue] = useState("");
  const [institutionOptions, setInstitutionOptions] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState();

  console.log(tableData, formattedDate, "formated");

  const navigate = useNavigate();

  console.log(tableData, "tableData");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = {};
      const result = await post("/getAllItinerary", data);

      // Transform result data for table
      const temp = result.data.map((list, i) => ({
        id: i + 1,
        itineraryName: `${list.itineraryname} ${
          list.isitinerary ? "Draft" : ""
        }`,
        package: list.packagename,
        dateoftravel: new Date(list.itinerary_travel)
          .toISOString()
          .split("T")[0],
        manager: "-",
        ...list,
      }));
      const packageSet = new Set(result.data.map((item) => item.packagename));

      const pckArray = [
        { label: "All Package", value: "All Package" },
        ...Array.from(packageSet).map((hotel) => ({
          label: hotel,
          value: hotel,
        })),
      ];

      const institutionSet = new Set(
        result.data.map((item) => item.schoolname)
      );

      const institutionArray = [
        { label: "All Institution", value: "All Institution" },
        ...Array.from(institutionSet).map((institu) => ({
          label: institu,
          value: institu,
        })),
      ];
      setPackageOptions(pckArray);
      setInstitutionOptions(institutionArray);
      setTableData(temp);
      setInitialData(temp);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const filteredData = initialData.filter((item) => {
      const matchesSearch =
        !searchText || // Allow all if searchText is empty
        item.itineraryname?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.packagename?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.schoolname?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.itinerary_travel?.toLowerCase().includes(searchText.toLowerCase());

      const matchesPackage =
        !packageValue ||
        packageValue.value === "All Package" ||
        item.packagename === packageValue.value;

      const matchesInstitution =
        !institutionValue ||
        institutionValue.value === "All Institution" ||
        item.schoolname === institutionValue.value;

      const matchesDay = !formattedDate || item.dateoftravel == formattedDate;

      return (
        matchesSearch && matchesPackage && matchesDay && matchesInstitution
      );
    });

    setTableData(filteredData);
  }, [searchText, packageValue, selectedDate, institutionValue]);

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  // Delete function for itineraries
  const handleDelete = async () => {
    try {
      await post("/deleteItinerary", selectedPackage);
      setShowDeleteModal(false);
      fetchData();
      toast.success("Itinerary deleted successfully");
    } catch (error) {
      console.error("Error deleting itinerary:", error);
      toast.error("Failed to delete itinerary");
    }
  };
  const handleClick = (rowData) => {
    navigate(`/itinerary-view-logs/${rowData.itinerarydetailsid}`);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("slNo", {
      header: "Sl. No",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("itineraryName", {
      header: "ITINERARY NAME",
      cell: (info) => {
        const itineraryName = info.getValue();
        const parts = itineraryName.split("Draft"); // Split around 'Draft'

        return (
          <span className="flex items-center space-x-2">
            <span>{parts[0].trim()}</span>{" "}
            {/* Trim whitespace for clean alignment */}
            {parts.length > 1 && (
              <span className="bg-[#F9834E] text-white px-2 py-1 rounded text-sm">
                Draft
              </span>
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("package", {
      header: "PACKAGE",
    }),
    columnHelper.accessor("itinerary_travel", {
      header: "DATE OF TRAVEL",
      cell: (info) => new Date(info.getValue()).toISOString().split("T")[0],
    }),

    columnHelper.accessor("schoolname", {
      header: "INSTITUTIONS",
    }),
    columnHelper.accessor("actions", {
      header: "ACTIONS",
      cell: (info) => (
        <div className="flex gap-2 sm:gap-1 sm:w-[200px] sm:overflow-x-auto sm:pb-2 sm:pl-2">
          <button className="p-2 bg-gray-200 rounded-md">
            <span
              className="material-symbols-outlined"
              onClick={() => handleEditClick(info.row.original)}
            >
              edit
            </span>
          </button>
          <button
            className={`p-2 rounded-md ${
              user?.role === "Sales Manager" || user?.role === "Sales Executive"
                ? "bg-gray-100 text-gray-400 cursor-not-allowed opacity-50"
                : "bg-gray-200 text-red-500 hover:bg-gray-300"
            }`}
            disabled={
              user?.role === "Sales Manager" || user?.role === "Sales Executive"
            }
            onClick={() => {
              setSelectedPackage(info.row.original); // Include `id`
              setShowDeleteModal(true);
            }}
          >
            <span className="material-symbols-outlined">delete</span>
          </button>
          <button
            className="p-2 bg-gray-200 rounded-md"
            onClick={() => generateItineraryPDF(info.row.original)}
          >
            <span className="material-symbols-outlined">picture_as_pdf</span>
          </button>
          {user?.role !== "Sales Manager" &&
            user?.role !== "Sales Executive" && (
              <button
                className="p-2 bg-gray-200 rounded-md"
                onClick={() => handleClick(info.row.original)}
              >
                <img
                  src={log}
                  alt="Itinerary Logo"
                  className="lg:max-w-full lg:h-auto lg:w-4 w-6 max-w-28"
                />
              </button>
            )}
        </div>
      ),
    }),
  ];

  // const [data, setData] = useState([
  //     {
  //         id: 1, // Unique identifier
  //         itineraryName: "Itinerary_98689 Draft",
  //         package: "Singapore_Premium",
  //         dateoftravel: "-",
  //         manager: "-",
  //     },
  //     {
  //         id: 2, // Unique identifier
  //         itineraryName: "Itinerary_98689",
  //         package: "Kodaikanal_2024",
  //         dateoftravel: "22nd Oct’24",
  //         manager: "Leon Serror",
  //     },
  //     {
  //         id: 3, // Unique identifier
  //         itineraryName: "Itinerary_98689 Draft",
  //         package: "Kodaikanal_2024",
  //         dateoftravel: "22nd Oct’24",
  //         manager: "Leon Serror",
  //     },
  // ]);
  const handleEditClick = (itineraryData) => {
    // debugger;
    navigate("/add-itinerary", {
      state: {
        itineraryData: itineraryData,
        isEdit: true,
      },
    });
  };

  const handleFormSelection = (e, type) => {
    if (type == "package") {
      if (e.label === "All Package") {
        setPackageValue("");
      } else {
        setPackageValue(e);
      }
    } else {
      if (e.label === "All Institution") {
        setInstitutionValue("");
      } else {
        setInstitutionValue(e);
      }
    }
  };

  const handleDateChange = (filterDate) => {
    const date = new Date(filterDate);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    console.log(formattedDate);
    setFormattedDate(formattedDate);
    setSelectedDate(filterDate);
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[28px] font-semibold">
            Itinerary
          </h2>
          <p className="text-[14px]">
            <span className="font-bold">Itinerary</span> /{" "}
            <span className="font-regular">Itinerary List</span>
          </p>
        </div>
        {user?.role !== "Sales Manager" && user?.role !== "Sales Executive" && (
          <div className="flex mt-4 justify-end gap-4">
            <Button
              type="primary"
              label="Add Itinerary"
              onClick={() => navigate("/add-itinerary")}
            />
          </div>
        )}
      </div>

      {/* Filter Fields */}
      <div className="grid grid-cols-1 gap-4 mt-4 md:flex md:justify-between">
        <div className="w-full md:w-5/12">
          <input
            type="text"
            className="outline-none p-2 rounded-l-lg w-full h-[40px]"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <div className="w-full md:w-auto md:flex md:gap-4">
          <div className="sm:w-full md:w-40 lg:w-52">
            <FilterSelect
              className="w-full sm:w-auto"
              value={packageValue}
              options={packageOptions}
              onChange={(e) => handleFormSelection(e, "package")}
              placeholder="Select Package..."
            />
          </div>
          <div className="sm:w-full md:w-40 lg:w-52">
            <FilterSelect
              className="w-full sm:w-auto"
              value={institutionValue}
              options={institutionOptions}
              onChange={(e) => handleFormSelection(e, "institution")}
              placeholder="Select Institution..."
            />
          </div>
          <div>
            <DatePicker
              selectedDate={selectedDate}
              onDateChange={(date) => handleDateChange(date)}
              placeholderText="Select Date..."
              className="w-full mt-0"
            />
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="bg-white rounded-xl border-gray-300 mt-7 pb-6">
        <Table columns={columns} data={tableData} />
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Delete Itinerary</h2>
            <p>
              Are you sure you want to delete itinerary{" "}
              <strong>{selectedPackage?.package}</strong>?
            </p>
            <div className="flex justify-end mt-6">
              <button
                className="px-4 py-2 bg-gray-200 rounded-md mr-2"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default AllItinerary;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import TextArea from "../customComponents/TextArea";
import DatePicker from "../customComponents/DatePicker";
import Select from "../customComponents/Select";
import useFetchOptions from "./hooks/useFetchOptions";
import { post } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from "../customComponents/Dropdown";

const AddEnquiry = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const location = useLocation();
  const {
    enquiry = {},
    institution = {},
    editenquiry,
    institutionId,
    packageData,
  } = location.state || {};
  console.log(packageData);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(
    enquiry?.name || institution?.name || institution?.label || null
  );
  const [packageOptions, setPackageOptions] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [travelDate, setTravelDate] = useState(new Date());
  const initialEnquiryStatusId = { value: 1, label: "Opened" } || null;
  const [showAdditionalDatePicker, setShowAdditionalDatePicker] = useState(
    initialEnquiryStatusId?.value === 1
  );
  const [formData, setFormData] = useState({
    enquiryname: "",
    enquirystatusid: { value: 1, label: "Opened" } || null,
    packagetypeid: null,
    selectedAccommodation: null,
    selectedHotel: null,
    selectedays: null,
    selectedpax: "",
    selectedreturn: null,
    selectedOnward: null,
    selectednights: null,
    enquirysource: "",
    destination: "",
    referenceId: "",
    referenceComment: "",
    comments: "",
    prices: "",
  });

  const handleDateChange = (date, type) => {
    const adjustedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    if (type === "dateoftravel") {
      setSelectedDate(adjustedDate);
    } else {
      setTravelDate(adjustedDate);
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (["selectedpax", "selectedays", "selectednights"].includes(name)) {
      // Validate for numeric inputs with max length of 3 digits
      if (!/^\d{0,3}$/.test(value)) return;
    } else if (name === "referenceId") {
      // Validate for numeric input with max length of 10 digits
      if (!(/^\d*$/.test(value) && value.length <= 10)) return;
    } else if (
      ["enquiryname", "comments", "enquirysource", "destination"].includes(name)
    ) {
      // Validate Enquiry Name and Comments with max length of 100 characters
      if (value.length > 100) return;
    } else if (["referenceComment"].includes(name)) {
      // Validate Destination and Reference Comment with max length of 300 characters
      if (value.length > 300) return;
    }
    // Update formData if all validations pass
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, option) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: option,
    }));

    // Update showAdditionalDatePicker based on the selected status
    if (name === "enquirystatusid") {
      setShowAdditionalDatePicker(option?.value === 1);
    }
  };

  useEffect(() => {
    // Handle existing enquiry data
    if (Object.keys(enquiry).length > 0) {
      const statusId = enquiry.enquirystatusid || 1;
      setFormData({
        enquiryname: enquiry.enquiryname || "",
        enquirystatusid: {
          value: statusId,
          label: statusId === 1 ? "Opened" : statusId === 2 ? "Confirmed" : "",
        },
        packagetypeid:
          { value: enquiry.packageTypeId, label: enquiry.packageType } || null,
        selectedAccommodation:
          {
            value: enquiry.accommodationtypeid,
            label: enquiry.accommodationtype,
          } || null,
        selectedHotel:
          {
            value: enquiry.hoteltypeid,
            label: enquiry.hoteltype,
          } || null,
        selectedays: enquiry.noofdays || null,
        selectedpax: enquiry.noofpax?.toString() || "",
        selectedreturn:
          { value: enquiry.returnId, label: enquiry.return } || null,
        selectedOnward:
          { value: enquiry.onwardId, label: enquiry.onward } || null,
        selectednights: enquiry.noofnight || null,
        noofpax: enquiry?.noofpax || "",
        enquirysource: enquiry.enquirysource || "",
        destination: enquiry.destination || "",
        referenceId: enquiry.referenceid || "",
        referenceComment: enquiry.referencecomment || "",
        comments: enquiry.enquirycomments || "",
        prices: enquiry.prices || "",
        selectedLocal:
          {
            value: enquiry.localtrnid,
            label: enquiry.localtrn,
          } || null,
      });

      setSelectedDate(new Date(enquiry.dateoftravel));
      setTravelDate(new Date(enquiry.nextfollowupdate));
      setShowAdditionalDatePicker(statusId === 1);
    } else if (packageData) {
      const extractHotelType = (fullName) => {
        if (!fullName) return "";
        return fullName.split("-")[1]?.trim() || fullName;
      };
      const extractTransportType = (fullName) => {
        if (!fullName) return "";
        return fullName.split("-")[0].trim();
      };
      const onwardType = extractTransportType(packageData.onward);
      const returnType = extractTransportType(packageData.return);
      const hotelType = extractHotelType(packageData.hoteltype);
      const transferType = extractHotelType(packageData.transfer);


      
      setFormData({
        enquiryname: "",
        enquirystatusid: { value: 1, label: "Opened" },
        packagetypeid: packageData.packagetypeid
          ? {
              value: packageData?.packagetypeid,
              label: packageData.packagetype,
            }
          : null,
        selectedHotel: packageData.hotelType
          ? {
              value: packageData?.hotelType?.value,
              label: packageData?.hotelType?.label,
            }
          : null,
          selectedAccommodation: packageData.accomdaTion
          ? {
              value: packageData?.accomdaTion?.value,
              label: packageData?.accomdaTion?.label,
            }
          : null,
        selectedOnward: packageData.onwardtrnid
          ? {
              value: packageData.onwardtrnid,
              label: onwardType,
            }
          : null,
        selectedreturn: packageData.returnid
          ? {
              value: packageData.returnid,
              label: returnType,
            }
          : null,
          selectedLocal:
          {
            value: packageData.transferid,
            label: transferType,
          } || null,
        selectedays: packageData.noofdays || "",
        selectedpax: packageData.noofpax?.toString() || "",
        selectednights: packageData.noofnights || "",
        enquirysource: packageData.source || "",
        destination: packageData.destination || "",
        comments: packageData.comments || "",
        prices: packageData.total_amount?.toString() || "",
        referenceId: "",
        referenceComment: "",
      });

      if (packageData.dateoftravel) {
        setSelectedDate(new Date(packageData.dateoftravel));
      }
    }
    // }, [enquiry, packageData]);
  }, [packageData]);

  const handleSave = async () => {
    if (
      !formData.enquiryname ||
      !formData.enquirystatusid ||
      !formData.packagetypeid ||
      !formData.selectedAccommodation ||
      !formData.selectedays ||
      !formData.selectedpax ||
      !formData.selectedreturn ||
      !formData.selectedOnward ||
      !formData.selectednights ||
      !formData.enquirysource ||
      !formData.destination ||
      !formData.comments ||
      (formData.enquirystatusid?.value === 2 && !formData.prices) ||
      !selectedDate ||
      (formData.enquirystatusid?.value === 1 && !selectedDate) ||
      !travelDate ||
      (formData.enquirystatusid?.value === 1 && !travelDate)
    ) {
      toast.error("Please fill out all required fields");
      return;
    }
    const payload = {
      institutionid:
        institution?.institutionid || institutionId || selectedId || null,
      enquiryname: formData.enquiryname || "",
      enquirystatusid: formData.enquirystatusid?.value || null,
      packagetypeid:
        formData.packagetypeid?.id || formData.packagetypeid?.value || null,
      accommodationtypeid: formData.selectedAccommodation?.value || null,
      hoteltypeid: formData.selectedHotel?.value || null,
      onwardtrnid: formData.selectedOnward?.value || null,
      returntrnid: formData.selectedreturn?.value || null,
      noofpax: formData.selectedpax || null,
      noofdays: formData.selectedays || null,
      noofnight: formData.selectednights || null,
      enquirysource: formData.enquirysource || "",
      destination: formData.destination || "",
      referenceid: formData.referenceId || "",
      referencecomment: formData.referenceComment || "",
      enquirycomments: formData.comments || "",
      prices: formData.prices || "",
      dateoftravel: selectedDate.toISOString().split("T")[0],
      type: editenquiry ? "" : "insert",
      ...(enquiry?.enquiryid && { enquiryid: enquiry.enquiryid }),
      userid: user?.userid || null,
      nextfollowupdate: travelDate.toISOString().split("T")[0],
      localtrnid: formData.selectedLocal?.value || null,
      user: user?.username || "N/A",
      packagedetailsid: packageData?.packagedetailsid || null,
    };

    try {
      const response = await post("/addInstitutionEnquiry", payload);
      if (response.status) {
        toast.success("Enquiry saved successfully");
        const navigateTo = location.state?.packageData ? "/all-itinerary" : -1;

        // Delay navigation to allow toast to appear
        setTimeout(() => {
          if (typeof navigateTo === "string") {
            navigate(navigateTo);
          } else {
            navigate(navigateTo, { state: { institution } });
          }
        }, 1000);
      } else {
        toast.error("Error: Non-successful response");
      }
    } catch (error) {
      toast.error("Error saving enquiry");
    }
  };

  const fieldConfig = [
    { label: "Enquiry Name", name: "enquiryname", component: InputText },
    {
      label: "Enquiry Status",
      name: "enquirystatusid",
      component: Select,
      fetchOptions: useFetchOptions("enquirystatus"),
      disabled: !editenquiry,
    },
    {
      label: "Package Type",
      name: "packagetypeid",
      component: Select,
      fetchOptions: useFetchOptions("packagetype"),
    },
    { label: "Source", name: "enquirysource", component: InputText },
    { label: "Destination", name: "destination", component: InputText },
    {
      label: "Destination Reference Comment",
      name: "referenceComment",
      component: TextArea,
    },
    {
      label: "Package",
      name: "referenceId",
      component: Select,
      options: packageOptions,
      skipAsterisk: true,
      disabled: location.state?.packageData ? true : false,
      value: packageOptions.find(
        (option) => option.id === packageData?.packagedetailsid
      ),
    },
    {
      label: "Hotel Type",
      name: "selectedHotel",
      component: Select,
      fetchOptions: useFetchOptions("hoteltype"),
    },
    {
      label: "Accommodation Type",
      name: "selectedAccommodation",
      component: Select,
      fetchOptions: useFetchOptions("accommodationtype"),
    },
    {
      label: "Onward Transportation",
      name: "selectedOnward",
      component: Select,
      fetchOptions: useFetchOptions("onwardtrn"),
    },
    {
      label: "Return Transportation",
      name: "selectedreturn",
      component: Select,
      fetchOptions: useFetchOptions("returntrn"),
    },
    {
      label: "Local Transportation",
      name: "selectedLocal",
      component: Select,
      fetchOptions: useFetchOptions("localtrn"),
      skipAsterisk: true,
    },
    {
      label: "No. of Pax",
      name: "selectedpax",
      component: InputText,
    },
    {
      label: "No. of Days",
      name: "selectedays",
      component: InputText,
    },
    {
      label: "No. of Nights",
      name: "selectednights",
      component: InputText,
    },
    { label: "Comments", name: "comments", component: InputText },
    {
      label: "Prices",
      name: "prices",
      component: InputText,
      condition: formData.enquirystatusid?.value === 2,
    },
    // {
    //   label: "Reference Package",
    //   name: "referenceId",
    //   component: Select,
    //   options: packageOptions,
    //   skipAsterisk: true,
    // },
  ];

  const handleSelect = (value) => {
    setSelectedValue(value?.value);
    setSelectedId(value?.institutionid);
  };

  const fetchPackages = async () => {
    try {
      const payload = { type: "packages" };
      const result = await post("/getOptionList", payload);
      setPackageOptions(result.data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);
  useEffect(() => {
    if (packageData && packageOptions.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        referenceId: {
          value: packageData.packagedetailsid,
          label: packageData.packagename,
        },
      }));
    }
  }, [packageData, packageOptions]);

  return (
    <div>
      <div className="block md:flex justify-between ">
        <div>
          <h2 className="font-medium text-2xl">Add Enquiry</h2>
          <p className="text-[12px]">
            <span className=" font-bold">
              {institution?.name || selectedValue || "Institutions"}
            </span>{" "}
            /<span className="font-regular"> Add Enquiry</span>
          </p>
        </div>

        <div className="flex mt-4 justify-end gap-4">
          <Button type="primary" label="Save" onClick={handleSave} />
          <Button
            type="secondary"
            label="Cancel"
            onClick={handleNavigateBack}
          />
        </div>
      </div>

      <Paper className="my-6 py-8 border">
        <div className=" block md:flex  justify-between">
          <div>
            <h1 className="text-[20px] font-bold mb-6">
              Enquiry{" "}
              <span className="bg-[#797979] text-white pt-1 pb-1 px-[12px] rounded-[8px] text-[14px] font-semibold">
                {formData.enquirystatusid.label}
              </span>
            </h1>
          </div>
          <div className="pb-4">
            <Dropdown
              apiUrl="/getOptionList"
              payload={{ type: "institution" }}
              placeholder="Select Institutions"
              onSelect={handleSelect}
              value={selectedValue || institution?.institutionid}
              disabled={
                editenquiry || (institution?.institutionid ? true : false)
              }
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {fieldConfig.map((field, index) => {
            if (field.condition === false) return null;
            const { options, loading } = field.fetchOptions || {};
            return (
              <div key={index}>
                <label className="text-[16px] font-semibold mb-2">
                  {field.label}
                  {!field.skipAsterisk && field.name !== "referenceComment" && (
                    <span className="text-red-500">*</span>
                  )}
                </label>
                {field.component === InputText && (
                  <InputText
                    placeholder={field.label}
                    value={formData[field.name]}
                    onChange={handleChange}
                    name={field.name}
                  />
                )}
                {field.component === Select && (
                  <Select
                    options={options || field.options}
                    value={formData[field.name]}
                    onChange={(option) =>
                      handleSelectChange(field.name, option)
                    }
                    placeholder={`Select ${field.label}`}
                    isLoading={loading}
                    disabled={field.disabled}
                  />
                )}
                {field.component === TextArea && (
                  <TextArea
                    placeholder={field.label}
                    value={formData[field.name]}
                    onChange={handleChange}
                    name={field.name}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5">
          <div>
            <label className="text-[16px] font-semibold mb-2">
              Date of Travel <span className="text-red-500">*</span>
            </label>
            <DatePicker
              selectedDate={selectedDate}
              onDateChange={(date) => handleDateChange(date, "dateoftravel")}
            />
          </div>
          {showAdditionalDatePicker && (
            <div>
              <label className="text-[16px] font-semibold mb-2">
                Next Follow Up Date
                <span className="text-red-500">*</span>
              </label>
              <DatePicker
                selectedDate={travelDate}
                onDateChange={(date) =>
                  handleDateChange(date, "nextfollowupdate")
                }
              />
            </div>
          )}
        </div>
      </Paper>
      <ToastContainer />
    </div>
  );
};

export default AddEnquiry;

import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { post } from "../utils/api";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "../customComponents/DatePicker";
import { list } from "postcss";





const EditAssignedInstitution = () => {

  const [dropdownDataLoaded, setDropdownDataLoaded] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());


  const location = useLocation();
  const navigate = useNavigate();
  const { assignInfo, type } = location.state || {};
  const isEdit = location.state?.isEdit;

  console.log(assignInfo, 'assignInfo');

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        await Promise.all([
          fetchStatusOptions(),
        ]);
        setDropdownDataLoaded(true); // Mark options as loaded
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchAllOptions();
  }, []);

  const fetchStatusOptions = async () => {
    try {
      const payload = { type: "status" };
      const result = await post("/getOptionList", payload);
      setStatusOptions(result.data);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };


  // const handleFormSelection = (e) => {
  //   setStatus(e)
  // };

  const handleChange = (e) => {
    setRemarks(e.target.value)
  }
  useEffect(() => {
    if (isEdit) {
      prePopulateValue(); // Now dropdown options are ready
    }
  }, [dropdownDataLoaded]);

  const prePopulateValue = () => {
    setRemarks(assignInfo.remarks || "");
    const statusVal = statusOptions.find(
      (list) => list?.id === parseInt(assignInfo?.statusid)
    );

    setStatus(statusVal);
    if(assignInfo?.assignedins_plan){
      assignInfo?.assignedins_plan.map((list) =>{
        list.date =new Date(list.date);
        // let inputDate =   new Date(list.date);
        // list.date =  inputDate.toString();
      })
      setEntries(assignInfo?.assignedins_plan)
    }

  }

  const handleUpdate = async () => {
    try {
      console.log(entries, 'entries');

      
      let entriesPlan = [];

      entries.forEach((field, index) => {
        entriesPlan.push({
          remarks: field?.remarks,
          date : field.date ? field.date : selectedDate,
          status :field.status

        });
      });

      const payload = {
        ...assignInfo,
        statusid: status?.value,
        remarks,
        assignedins_plan:entriesPlan

      }
      const response = await post("/updateAssignedInstitution", payload);
      if (response.status) {
        toast.success("Assigned institution updated successfully!");
        navigate("/assigned-institution");
      }
    } catch (error) {
      toast.error("Failed to update institution: " + error.message);
      console.error("Error updating assign info:", error);
    }
  }
  const [entries, setEntries] = useState([{ institution: '', status: '', remarks: '', date: '' }]);

  console.log(entries, 'entries')


  const updateStatus = (index, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index].status = value;
    setEntries(updatedEntries);
  };

  const updateField = (index, e) => {
    const { name, value } = e.target;
    const updatedEntries = [...entries];
    updatedEntries[index][name] = value;
    setEntries(updatedEntries);
  };

  const handleFormSelection = (index, e) => {
    const updatedEntries = [...entries];
    updatedEntries[index]['status'] = e
    setEntries(updatedEntries);
  };

  const handleDateChange = (index,date) => {
    const updatedEntries = [...entries];
    updatedEntries[index]['date'] = date;
    setEntries(updatedEntries);
  };

  const addEntry = () => {
    setEntries([...entries, { institution: '', status: '', remarks: '' }]);
  };

  const deleteEntry = (index) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
  };



  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            Edit Assigned Institution
          </h2>
          <p className="text-[12px]">
            <span className="font-regular">Edit Assigned Institution</span>
          </p>
        </div>

        <div className="flex gap-4">
          <Button type="secondary" onClick={() =>
            navigate("/assigned-institution", {
            })
          } label="Cancel" />
          <Button type="primary" onClick={handleUpdate} label="Update" />
        </div>
      </div>
      <Paper className="my-8 pt-6 pb-6">
        <Paper className="my-8 border">
          <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <label className="text-[16px] font-semibold">
                Sales Person
              </label>
              <InputText
                placeholder="Package Name..."
                name="packagename"
                value={assignInfo.username}
                disabled={true}

              />
            </div>
            <div>
              <label className="text-[16px] font-semibold">
                Institution
              </label>
              <InputText
                placeholder="Package Name..."
                name="packagename"
                value={assignInfo.institution}
                disabled={true}
              />
            </div>
          </div>
          <h1 className="text-[20px] font-bold pt-6">Visited Status</h1>
          <div className="grid grid-cols-1 gap-2 pt-2">
            {entries.map((entry, index) => (
              <div key={index} className="grid grid-cols-4 gap-4 items-center">
                {/* Institution Field */}
                <div>
                  <label className="text-[16px] font-semibold">
                    Date
                  </label>
                  <DatePicker
                    selectedDate={ entry.date ? entry.date : selectedDate}
                    onDateChange={(date) => handleDateChange(index,date)}
                    placeholderText="Select Date..."
                    className="w-full mt-0"
                    name="date"
                  />
                </div>

                {/* Status Field */}
                <div>
                  <label className="text-[16px] font-semibold mb-2">Status</label>
                  <Select
                    placeholder="Select status..."
                    options={statusOptions}
                    onChange={(e) => handleFormSelection(index, e)}
                    value={entry.status}
                    name="status"
                  />
                </div>

                {/* Remarks Field */}
                <div>
                  <label className="text-[16px] font-semibold">Remarks</label>
                  <textarea
                    className="w-full h-[60px] p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Remark here..."
                    name="remarks"
                    onChange={(e) => updateField(index, e)}
                    value={entry.remarks}
                  ></textarea>
                </div>

                {/* Add/Delete Button */}
                <div className="flex gap-2 mt-6 ml-6">
                  {/* Show Delete button for all rows except the last one */}
                  {index < entries.length - 1 && (
                    <button
                      className="p-2 rounded bg-red-500 text-white flex justify-center items-center"
                      onClick={() => deleteEntry(index)}
                    >
                      <span className="material-symbols-outlined">delete</span>
                    </button>
                  )}

                  {/* Show Add button only for the last row */}
                  {index === entries.length - 1 && (
                    <button
                      className="p-2 rounded bg-black text-white flex justify-center items-center"
                      onClick={addEntry}
                    >
                      <span className="material-symbols-outlined">add</span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>

        </Paper>
      </Paper>
    </div>
  );
};

export default EditAssignedInstitution;

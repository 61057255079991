import { useState, useEffect } from "react";
import "../App.css";
import Select from "../customComponents/Select";
import SearchComponent from "../customComponents/SearchInput";
import Card from "../customComponents/Card";
import Button from "../customComponents/Button";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { post } from "../utils/api";
import noImg from "../assets/noImg.png";
import noData from "../assets/nodata.png";
import FilterSelect from "../customComponents/FilterSelect";
import LoaderComponent from "./LoaderComponent";

const stageOptions = [
  { value: "all stage", label: "All Stage" },
  { value: "lead", label: "Lead Stage" },
  { value: "prospect", label: "Prospect Stage" },
  { value: "account", label: "Account Stage" },
];

const Institutions = () => {
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [institutionsCount, setInstitutionsCount] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [enquiryOptions, setEnquiryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const institutionsPerPage = 12;

  const navigate = useNavigate();

  useEffect(() => {
    fetchEnquiryStatus();
    fetchData("");
  }, []);

  const fetchData = async (filterObj) => {
    setIsLoading(true);
    try {
      const filtObj = filterObj
        ? filterObj
        : {
            stage: selectedStage?.label || "",
            enquirystatusid: selectedEnquiry?.id || "",
          };

      filtObj.stage = filtObj.stage === "All Stage" ? "" : filtObj.stage;
      const payload = { filterObj: filtObj };
      const result = await post("/getAllInstitutions", payload);
      setInstitutions(result?.data || []);
      setInstitutionsCount(result?.stages || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEnquiryStatus = async () => {
    try {
      const payload = { type: "enquirystatus" };
      const result = await post("/getOptionList", payload);
      const options = [
        { label: "All Enquiries", value: "all", id: "" }, // Add this option
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.enquirystatusid,
        })),
      ];
      setEnquiryOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = ({ selected }) => setCurrentPage(selected);

  const offset = currentPage * institutionsPerPage;
  const currentInstitutions = institutions.slice(
    offset,
    offset + institutionsPerPage
  );
  const pageCount = Math.ceil(institutions.length / institutionsPerPage);

  const StageCounts = ({ label, count, className }) => (
    <p className="flex items-center gap-2 col-span-1 pb-2 md:pb-0">
      {label}
      <span
        className={`w-[41px] h-[20px] px-[12px] rounded-[8px] ${className} text-white flex items-center justify-center`}
      >
        {count}
      </span>
    </p>
  );

  // Apply filtering based on searchQuery
  const filteredInstitutions = currentInstitutions.filter((institution) =>
    institution?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleFormSelection = (e, type) => {
    let filtObj = {
      stage: "",
      enquirystatusid: "",
    };
    if (type === "stage") {
      setSelectedStage(e);
      filtObj = {
        stage: e.label || "",
        enquirystatusid: selectedEnquiry?.id || "",
      };
    } else {
      setSelectedEnquiry(e);
      filtObj = {
        stage: selectedStage?.label || "",
        enquirystatusid: e?.id || "",
      };
    }
    fetchData(filtObj); // Fetch data based on selected filters
  };

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <div>
            <div className="block md:flex lg:flex justify-between">
              <h2 className="text-primary-black text-[28px] font-semibold mb-4 md:mb-0">
                All Institutions
              </h2>
              {(user?.role === "Admin" || user?.role === "Sales Manager") && (
                <div className="flex justify-end">
                  <Button
                    type="primary"
                    label="Add Institutions"
                    onClick={() => navigate("/add-institutions")}
                    className={"mb-4 md:mb-0"}
                  />
                </div>
              )}
            </div>
            <div className="xs:grid grid-cols-3 gap-2 mt-4 md:flex xs:contents">
              <StageCounts
                label="Lead Stage"
                count={institutionsCount["Lead Stage"] || 0}
                className="bg-lead-stage"
              />
              <StageCounts
                label="Prospect Stage"
                count={institutionsCount["Prospect Stage"] || 0}
                className="bg-prospect-stage"
              />
              <StageCounts
                label="Account Stage"
                count={institutionsCount["Account Stage"] || 0}
                className="bg-account-stage"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-1 mt-4 md:flex md:justify-between">
            <div className="w-full md:w-4/12">
              <SearchComponent onChange={setSearchQuery} />
            </div>
            <div className="w-full md:w-auto md:flex md:gap-4">
              <div className="sm:w-full md:w-40 lg:w-52">
                <FilterSelect
                  options={stageOptions}
                  value={selectedStage}
                  onChange={(e) => handleFormSelection(e, "stage")}
                  placeholder="Select Stage"
                />
              </div>
              <div className="sm:w-full md:w-40 lg:w-52">
                <FilterSelect
                  options={enquiryOptions}
                  value={selectedEnquiry}
                  onChange={(e) => handleFormSelection(e, "enquiry")}
                  placeholder="Select Enquiry"
                />
              </div>
            </div>
          </div>
          {filteredInstitutions.length === 0 ? (
            <div className="mt-8 text-center">
              <img
                src={noData}
                alt="No institutions found"
                className="mx-auto h-[25rem] mb-4"
              />
              <p className="text-gray-600 text-lg">No institutions found.</p>
              <p className="text-gray-400 mt-2">
                Please add a new institution using the button above.
              </p>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4 mb-10">
                {filteredInstitutions.map((institution, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      navigate("/institution-details", { state: institution })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Card
                      institutionImg={institution.logo || noImg}
                      institutionName={institution.name || "N/A"}
                      stageName={institution.stage || "Lead Stage"}
                      created_on={institution.created_on || ""}
                      institution={institution}
                    />
                  </div>
                ))}
              </div>
              <div className="mt-4 flex justify-center mb-4">
                <ReactPaginate
                  previousLabel={
                    <span className="material-symbols-outlined">
                      chevron_left
                    </span>
                  }
                  nextLabel={
                    <span className="material-symbols-outlined">
                      chevron_right
                    </span>
                  }
                  breakLabel="..."
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Institutions;

import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import { toast, ToastContainer } from "react-toastify";
import DayPlan from "../customComponents/DayPlan";
import TextEditor from "../customComponents/TextEditor";
import DatePicker from "../customComponents/DatePicker";
import { post } from "../utils/api";
import MultiSelect from "../customComponents/MultiSelect";
import { MultiNewSelect } from "../customComponents/NewMultiSelect";
import LoaderComponent from "./LoaderComponent";
import Upload from "../customComponents/Upload";
import Table from "../customComponents/Table";

const AddPackages = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [days, setDays] = useState([]);
  const [isFirstDayAdded, setIsFirstDayAdded] = useState(false);
  const [selectedValue, setSelectedValue] = useState("single");
  const [formData, setFormData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSeasonalFields, setShowSeasonalFields] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Default to today's date
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedOnwardTransport, setSelectedOnwardTransport] = useState(null);
  const [selectedReturnTransport, setSelectedReturnTransport] = useState(null);
  const [selectedTransfers, setSelectedTransfers] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [extraBFCount, setExtraBFCount] = useState("");
  const [extraLunchCount, setExtraLunchCount] = useState("");
  const [extraDinnerCount, setExtraDinnerCount] = useState("");
  const [numberOfPax, setNumberOfPax] = useState("");
  const [addOnsAmount, setAddOnsAmount] = useState("");
  const [deviationAmount, setDeviationAmount] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [packageTypeOptions, setPackageTypeOptions] = useState([]);
  const [hotelTypeOptions, setHotelTypeOptions] = useState([]);
  const [onwardTransportOptions, setOnwardTransportOptions] = useState([]);
  const [returnTransportOptions, setReturnTransportOptions] = useState([]);
  const [transferOptions, setTransferOptions] = useState([]);
  const [addonOptions, setAddonOptions] = useState([]);
  const [selectedPackageType, setSelectedPackageType] = useState(null);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [hotelType, setHotelType] = useState("");
  const [accommodationValue, selectedAccommodation] = useState("");

  const [formValues, setFormValues] = useState({
    packagetypeid: null,
    packagename: "",
    city: "",
    destination: "",
    noofdays: "",
    noofnights: "",
    hoteltypeid: null,
    onwardtrnid: null,
    returnid: null,
    transferid: null,
    addonid: null,
    extra_bfcount: 0,
    extra_lunchcount: 0,
    extra_dinnercount: 0,
    noofpax: 0,
    foc: "",
    addon_amount: 0,
    deviation_amount: 0,
    price_perhead: 0,
    daywise_plan: [],
    package_image: null,
    currency: "INR",
    total_amount: 0,
  });
  const [dropdownDataLoaded, setDropdownDataLoaded] = useState(false);
  const [showExtraBF, setShowExtraBF] = useState(false);
  const [showExtraLunch, setShowExtraLunch] = useState(false);
  const [showExtraDinner, setShowExtraDinner] = useState(false);
  const [mealHotelPlanOptions, setHotelMealPlanOptions] = useState([]);
  const [selectedHotelMealPlan, setSelectedHotelMealPlan] = useState("");
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [hotelSelections, setHotelSelections] = useState([
    { hotelType: null, mealPlan: null, day: null },
  ]);
  const [dayFields, setDayFields] = useState([{}]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [itineraryName, setItineraryName] = useState("");
  const [isDayOuting, setIsDayOuting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dayOptions, setDayOptions] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { packageData, type } = location.state || {};
  const isEdit = location.state?.isEdit;

  console.log(hotelSelections, "hotelSelections");

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        await Promise.all([
          fetchPackageTypes(),
          fetchHotelTypes(),
          fetchOnwardTransport(),
          // fetchReturnTransport(),
          //fetchTransfers(),
          fetchAddons(),
        ]);
        setDropdownDataLoaded(true); // Mark options as loaded
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchAllOptions();
  }, []);

  useEffect(() => {
    if (isEdit) {
      fetchHotelMealPlan(packageData?.hoteltypeid);
      prePopulateValue(); // Now dropdown options are ready
    }
  }, [dropdownDataLoaded]);

  useEffect(() => {
    if (selectedAddons?.length > 0) {
      handleAddonsChange(selectedAddons);
    }
  }, [selectedAddons, extraBFCount, extraLunchCount, extraDinnerCount]);

  useEffect(() => {
    const response = generateOptions(formValues.noofnights);
    setDayOptions(response);
  }, [formValues.noofnights]);

  const generateOptions = (maxValue) => {
    return Array.from({ length: maxValue }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    }));
  };

  const prePopulateValue = async () => {
    try {
      setShowSeasonalFields(packageData?.seasonal || false);
      const packageType = {
        label: packageData.packagetype,
        value: packageData.packagetype,
        id: packageData.packagetypeid,
      };
      setSelectedPackageType(packageType);
      setIsDayOuting(packageData.packagetype === "Day Outing");

      setFormValues({
        packagename: packageData.packagename,
        city: packageData.city,
        destination: packageData.destination,
        noofdays: packageData.noofdays,
        noofnights: packageData.noofnights,
      });

      if (packageData.packagetype !== "Day Outing") {
        const hotelType = hotelTypeOptions.find(
          (hotel) => hotel?.id === parseInt(packageData?.hoteltypeid)
        );
        setSelectedHotel(hotelType);
        fetchHotelMealPlan(packageData?.hoteltypeid);
      }

      const hotelType = hotelTypeOptions.find(
        (hotel) => hotel?.id === parseInt(packageData?.hoteltypeid)
      );
      const onWardTrn = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.onwardtrnid)
      );
      const returnWardTrn = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.returnid)
      );
      const transfers = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.transferid)
      );
      // const addons = addonOptions.find(
      //   (list) => list?.id === parseInt(packageData?.addonid)
      // );

      const addons = addonOptions.filter((list) =>
        packageData?.addonid.includes(list.id.toString())
      );

      const foc = options.find((list) => list?.value === packageData?.foc);

      setSelectedOnwardTransport(onWardTrn);
      setSelectedReturnTransport(returnWardTrn);
      setSelectedTransfers(transfers);
      setSelectedHotel(hotelType);
      setSelectedAddons(addons);
      setShowExtraBF(addons?.some((addon) => addon.label === "Extra BF"));
      setShowExtraLunch(addons?.some((addon) => addon.label === "Extra Lunch"));
      setShowExtraDinner(
        addons?.some((addon) => addon.label === "Extra Dinner")
      );
      setNumberOfPax(Number(packageData?.noofpax));
      setSelectedOption(foc);
      setAddOnsAmount(Number(packageData?.addon_amount));
      setDeviationAmount(Number(packageData?.deviation_amount));
      setEditorContents({
        transportation_details:
          packageData?.texteditordata?.transportation_details,
        hotel_details: packageData?.texteditordata?.hotel_details,
        meal_plan: packageData?.texteditordata?.meal_plan,
        inclusions: packageData?.texteditordata?.inclusions,
        exclusions: packageData?.texteditordata?.exclusions,
        terms_conditions: packageData?.texteditordata?.terms_conditions,
        learning_outcomes: packageData?.texteditordata?.learning_outcomes,
      });
      setSelectedDate(new Date(packageData?.dateoftravel));
      let isSeason = packageData?.dateoftravel ? true : false;
      setShowSeasonalFields(isSeason);
      setFormData({
        logo: packageData?.package_image ? packageData?.package_image : null,
      });

      setIsFirstDayAdded(true);
      setDays(packageData?.daywise_plan || []);
      // Call the function
      fetchPackagePlan();
    } catch (error) {}
  };

  const fetchPackagePlan = async () => {
    let response = [];
    if (!packageData?.packagewise_plan) {
      setHotelSelections([]);
      return;
    }

    const packagePlan = await Promise.all(
      packageData.packagewise_plan.map(async (list, index) => {
        const hotel = hotelTypeOptions.find(
          (item) => item.id === list.hotelType
        );
        const mealPlanOptions = await fetchHotelMealPlan(list.hotelType);
        const mealPlan = mealPlanOptions.find(
          (item) => item.id === list.mealPlan
        );

        response = generateOptions(packageData.noofnights);
        const addons = response.filter((item) =>
          list.day.includes(item.value.toString())
        );

        if (index == 0) {
          setHotelType({
            label: hotel?.hoteltype,
            value: hotel?.hoteltypeid,
          });

          selectedAccommodation(mealPlan?.accommodationType);
        }
        return {
          hotelType: hotel,
          day: addons,
          options: mealPlanOptions,
          mealPlan: mealPlan,
        };
      })
    );
    setDayOptions(response);
    setHotelSelections(packagePlan);
  };

  const fetchInstitutions = async () => {
    try {
      const payload = { type: "institution" };
      const result = await post("/getOptionList", payload);
      setInstitutionOptions(result.data);
    } catch (error) {
      console.error("Error fetching institutions:", error);
    }
  };

  const handleAddDay = useCallback(() => {
    setIsFirstDayAdded(true);
    setDays((prevDays) => {
      const newDayNumber = prevDays.length + 1;
      return [
        ...prevDays,
        { dayNumber: newDayNumber, timeSlots: [], planName: "" },
      ];
    });
  }, []);
  const handleNavigateBack = () => {
    // navigate(-1);
    navigate("/all-packages");
  };

  const handleUploadSelect = (file) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFormData({ ...formData, logo: reader.result });
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64:", error);
    };
  };

  const validateFile = (file) => {
    if (!file.type.startsWith("image/")) {
      return "Only image files are allowed.";
    }
    if (file.size > 5 * 1024 * 1024) {
      return "File size should not exceed 5MB.";
    }
    return null;
  };
  const options = [
    { value: "10:1", label: "10:1" },
    { value: "20:1", label: "20:1" },
    { value: "30:1", label: "30:1" },
    { value: "40:1", label: "40:1" },
  ];

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  // Add this function to calculate the total price
  const calculateTotalPrice = () => {
    if (!numberOfPax || numberOfPax <= 0) return 0;

    let totalPrice = 0;

    // Base components
    const baseComponents = [
      _getPrice(selectedHotel),
      _getPrice(selectedOnwardTransport),
      _getPrice(selectedReturnTransport),
      _getPrice(selectedTransfers),
    ].reduce((sum, price) => sum + Number(price || 0), 0);

    // Addons calculation
    const addonsTotal = selectedAddons.reduce((sum, addon) => {
      const basePrice =
        showSeasonalFields && addon.sph
          ? Number(addon.sph)
          : Number(addon.pph || 0);

      switch (addon.label) {
        case "Extra BF":
          return sum + basePrice * (extraBFCount || 1);
        case "Extra Lunch":
          return sum + basePrice * (extraLunchCount || 1);
        case "Extra Dinner":
          return sum + basePrice * (extraDinnerCount || 1);
        default:
          return sum + basePrice;
      }
    }, 0);

    totalPrice = baseComponents + addonsTotal;

    // Apply FOC ratio
    if (selectedOption) {
      const [ratio] = selectedOption.value.split(":").map(Number);
      totalPrice = (totalPrice * (ratio + 1)) / ratio;
    }

    // Add hotel selections and additional amounts
    hotelSelections.forEach((selection) => {
      if (selection.mealPlan?.calculatedPrice) {
        totalPrice += Number(selection.mealPlan.calculatedPrice);
      }
    });

    totalPrice += Number(deviationAmount) || 0;

    return Math.round(totalPrice);
  };

  // Modify the existing handlers and add new ones
  const handleHotelChange = (option) => {
    const selected = hotelTypeOptions.find(
      (hotel) => hotel.id === option.value
    );
    setSelectedHotel(selected);
    fetchHotelMealPlan(option.value);
  };

  const handleOnwardTransportChange = (option) => {
    const selected = onwardTransportOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedOnwardTransport(selected);
  };

  const handleReturnTransportChange = (option) => {
    const selected = returnTransportOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedReturnTransport(selected);
  };

  const handleTransfersChange = (option) => {
    const selected = transferOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedTransfers(selected);
  };

  const handleAddonsChange = (selectedOptions) => {
    setSelectedAddons(selectedOptions);

    const hasExtraBF = selectedOptions?.some(
      (option) => option.label === "Extra BF"
    );
    const hasExtraLunch = selectedOptions?.some(
      (option) => option.label === "Extra Lunch"
    );
    const hasExtraDinner = selectedOptions?.some(
      (option) => option.label === "Extra Dinner"
    );

    setShowExtraBF(hasExtraBF);
    setShowExtraLunch(hasExtraLunch);
    setShowExtraDinner(hasExtraDinner);

    // Calculate addons amount
    const addonsAmount = selectedOptions.reduce((sum, addon) => {
      const basePrice =
        showSeasonalFields && addon.sph
          ? Number(addon.sph)
          : Number(addon.pph || 0);

      switch (addon.label) {
        case "Extra BF":
          return sum + basePrice * (extraBFCount || 1);
        case "Extra Lunch":
          return sum + basePrice * (extraLunchCount || 1);
        case "Extra Dinner":
          return sum + basePrice * (extraDinnerCount || 1);
        default:
          return sum + basePrice;
      }
    }, 0);

    setAddOnsAmount(addonsAmount);
  };

  useEffect(() => {
    const newTotal = calculateTotalPrice();
    setTotalPrice(newTotal);
  }, [
    selectedHotel,
    selectedOnwardTransport,
    selectedReturnTransport,
    selectedTransfers,
    selectedAddons,
    extraBFCount,
    extraLunchCount,
    extraDinnerCount,
    numberOfPax,
    selectedOption,
    addOnsAmount,
    deviationAmount,
    days.length,
    showSeasonalFields,
    selectedDate,
  ]);
  const fetchPackageTypes = async () => {
    try {
      const payload = { type: "packagetype" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((item) => ({
        value: item.value,
        label: item.label,
        id: item.value,
      }));
      setPackageTypeOptions(options);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const fetchHotelTypes = async () => {
    try {
      const payload = { type: "hotelname_hoteltype" };
      const result = await post("/getOptionList", payload);
      // const options = result.data.map((item) => ({
      //   value: item.label,
      //   label: item.label,
      //   pph: item.pph,
      //   id: item.id
      // }));
      setHotelTypeOptions(result.data);
    } catch (error) {
      console.error("Error fetching hotel types:", error);
    }
  };

  const fetchOnwardTransport = async () => {
    try {
      const payload = { type: "vehicaltype_vehicalname" };
      const result = await post("/getOptionList", payload);
      // const options = result.data.map((item) => ({
      //   value: item.id,
      //   label: item.label,
      //   pph: item.pph,
      // }));
      setOnwardTransportOptions(result.data);
      setReturnTransportOptions(result.data);
      setTransferOptions(result.data);
    } catch (error) {
      console.error("Error fetching onward transport:", error);
    }
  };

  const fetchReturnTransport = async () => {
    try {
      const payload = { type: "vehicaltype_vehicalname" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((item) => ({
        value: item.id,
        label: item.label,
        pph: item.pph,
      }));
      setReturnTransportOptions(options);
    } catch (error) {
      console.error("Error fetching return transport:", error);
    }
  };

  const filterTransportOptions = (options, type) => {
    if (!options) return [];

    switch (type) {
      case "transfers":
        // Show only van/traveller options for transfers
        return options.filter(
          (option) =>
            !option.label.toLowerCase().includes("flight") &&
            !option.label.toLowerCase().includes("train") &&
            !option.label.toLowerCase().includes("bus")
        );

      case "onward":
      case "return":
        // Show only bus, train and flight options
        return options.filter(
          (option) =>
            option.label.toLowerCase().includes("flight") ||
            option.label.toLowerCase().includes("train") ||
            option.label.toLowerCase().includes("bus")
        );

      default:
        return options;
    }
  };

  const fetchAddons = async () => {
    try {
      const payload = { type: "addons_price" };
      const result = await post("/getOptionList", payload);
      // const options = result.data.map((item) => ({
      //   value: item.id,
      //   label: item.label,
      //   pph: item.pph,
      // }));
      setAddonOptions(result.data);
    } catch (error) {
      console.error("Error fetching addons:", error);
    }
  };
  // Add handlers for form inputs
  const handlePackageTypeChange = (option) => {
    setSelectedPackageType(option);
    setFormValues((prev) => ({ ...prev, packagetypeid: option.value }));

    // Check if Day Outing is selected
    const isDayOutingSelected = option.label === "Day Outing";
    setIsDayOuting(isDayOutingSelected);

    // Reset hotel and meal plan if Day Outing is selected
    if (isDayOutingSelected) {
      setSelectedHotel(null);
      setSelectedHotelMealPlan(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const validateFields = () => {
    if (!selectedPackageType?.id) {
      toast.error("Please select package type");
      return false;
    }

    if (!formValues.packagename?.trim()) {
      toast.error("Package name is required");
      return false;
    }

    if (!formValues.city?.trim()) {
      toast.error("Source city is required");
      return false;
    }

    if (!formValues.destination?.trim()) {
      toast.error("Destination is required");
      return false;
    }

    if (!isDayOuting) {
      if (!formValues.noofdays || formValues.noofdays <= 0) {
        toast.error("Number of days must be greater than 0");
        return false;
      }

      if (!formValues.noofnights || formValues.noofnights <= 0) {
        toast.error("Number of nights must be greater than 0");
        return false;
      }
    }
    if (!isDayOuting) {
      hotelSelections.forEach((field, index) => {
        if (!field?.hotelType?.value) {
          // throw new Error(
          //   `Hotel Type is required for plan ${index + 1}.`
          // );
          toast.error(`Hotel Type is required for plan ${index + 1}.`);
          return false;
        }
        if (!field?.mealPlan?.value) {
          toast.error(`Meal Plan is required for plan ${index + 1}.`);
          return false;
        }
        if (field?.day?.length == 0) {
          toast.error(`Day is required for plan ${index + 1}.`);
          return false;
        }
      });
    }

    if (!selectedOnwardTransport?.id) {
      toast.error("Please select onward transportation");
      return false;
    }

    if (
      !selectedReturnTransport?.id &&
      selectedPackageType?.label === "International"
    ) {
      toast.error("Please select return transportation");
      return false;
    }

    if (!numberOfPax || numberOfPax <= 0) {
      toast.error("Number of pax must be greater than 0");
      return false;
    }

    if (showSeasonalFields && !selectedDate) {
      toast.error("Please select date of travel");
      return false;
    }

    if (!days.length) {
      toast.error("Please add at least one day plan");
      return false;
    }

    for (let day of days) {
      if (!day.planName?.trim()) {
        toast.error(`Please add plan name for day ${day.dayNumber}`);
        return false;
      }
      if (!day.timeSlots?.length) {
        toast.error(`Please add time slots for day ${day.dayNumber}`);
        return false;
      }
    }

    return true;
  };

  const handleSavePackage = async () => {
    if (!validateFields()) {
      return;
    }
    try {
      const formattedDays = days.map((day) => ({
        dayNumber: day.dayNumber,
        timeSlots: day.timeSlots || [],
        planName: day.planName,
        daywiseImage: day.daywiseImage,
      }));
      const addonIds = Array.isArray(selectedAddons)
        ? `{${selectedAddons.map((addon) => addon.id).join(",")}}`
        : selectedAddons?.id
        ? `{${selectedAddons.id}}`
        : null;

      let hotelPlan = [];

      hotelSelections.forEach((field, index) => {
        hotelPlan.push({
          hotelType: field?.hotelType?.value,
          mealPlan: field?.mealPlan?.value,
          day: Array.isArray(field.day)
            ? `{${field.day.map((addon) => addon.value).join(",")}}`
            : field.day?.value
            ? `{${field.day.value}}`
            : null,
        });
      });

      const basePayload = {
        packagetypeid: selectedPackageType?.id,
        packagename: formValues.packagename,
        cityid: formValues.city,
        destination: formValues.destination,
        noofdays: Number(formValues.noofdays),
        noofnights: Number(formValues.noofnights),
        hoteltypeid: selectedHotel?.id,
        onwardtrnid: selectedOnwardTransport?.id,
        returnid: selectedReturnTransport?.id,
        transferid: selectedTransfers?.id,
        addonid: addonIds,
        extra_bfcount: Number(extraBFCount),
        extra_lunchcount: Number(extraLunchCount),
        extra_dinnercount: Number(extraDinnerCount),
        noofpax: Number(numberOfPax),
        foc: selectedOption?.value,
        addon_amount: Number(addOnsAmount),
        deviation_amount: Number(deviationAmount),
        price_perhead: Number(totalPrice),
        daywise_plan: formattedDays,
        package_image: formData?.logo ? formData?.logo : "",
        currency: "INR",
        total_amount: Number(totalPrice * numberOfPax),
        type: location.state?.isEdit ? "update" : "insert",
        textEditorData: editorContents,
        city: formValues.city,
        dateoftravel: selectedDate,
        isAudit: true,
        user: user?.username || "N/A",
        isitinerary: false,
        hotel_mealplan: selectedHotelMealPlan?.value,
        packagewise_plan: hotelPlan,
      };

      const payload = location.state?.isEdit
        ? {
            ...basePayload,
            packagedetailsid: location.state?.packageData?.packagedetailsid,
          }
        : basePayload;

      const response = await post("/addPackage", payload);

      if (response.status) {
        toast.success(
          isEdit ? "Package updated successfully" : "Package added successfully"
        );
        setTimeout(() => {
          navigate("/all-packages");
        }, 1500);
      } else {
        toast.error("Failed to save package");
      }
    } catch (error) {
      toast.error("An error occurred while saving the package");
    }
  };

  const handleConvertToItinerary = () => {
    setIsModalOpen(true);
    fetchInstitutions();
  };
  const [editorContents, setEditorContents] = useState({
    transportation_details: "",
    hotel_details: "",
    meal_plan: "",
    inclusions: "",
    exclusions: "",
    terms_conditions: "",
    learning_outcomes: "",
  });

  const handleEditorChange = (content, field) => {
    setEditorContents((prev) => ({
      ...prev,
      [field]: content,
    }));
  };

  const handleDayPlanChange = useCallback((dayNumber, planName, planData) => {
    setDays((prevDays) => {
      const dayIndex = prevDays.findIndex((day) => day.dayNumber === dayNumber);
      if (dayIndex === -1) return prevDays;

      const newDays = [...prevDays];
      newDays[dayIndex] = {
        dayNumber,
        timeSlots: planData.timeSlots,
        planName: planData.planName,
        daywiseImage: planData?.formData?.logo,
      };
      return newDays;
    });
  }, []);

  const handleInstitutionSelect = (option) => {
    setSelectedInstitution(option);
  };
  // const handleConvertToEnquiry = async () => {
  //   if (!selectedInstitution || !itineraryName) return;

  //   const itineraryPayload = {
  //     isPackageType: "insert",
  //     isitinerary: true,
  //   };

  //   try {
  //     const response = await post("/addItinerary", itineraryPayload);

  //     if (response.status) {
  //       navigate("/add-enquiry", {
  //         state: {
  //           institution: selectedInstitution,
  //           packageData: packageData,
  //           type: "insert",
  //           itineraryName: itineraryName,
  //         },
  //       });
  //       setIsModalOpen(false);
  //     }
  //   } catch (error) {
  //     toast.error("Failed to create itinerary");
  //   }
  // };

  const isInSeasonalRange = (startDate, endDate, selectedDate) => {
    if (!startDate || !endDate || !selectedDate) return false;
    const currentDate = new Date(selectedDate);
    const seasonStart = new Date(startDate);
    const seasonEnd = new Date(endDate);
    return currentDate >= seasonStart && currentDate <= seasonEnd;
  };

  const handleConvertToEnquiry = async () => {
    if (!validateFields()) {
      return;
    }

    if (!selectedInstitution) {
      toast.error("Please select an institution");
      return;
    }

    if (!itineraryName?.trim()) {
      toast.error("Please enter itinerary name");
      return;
    }
    const formattedDays = days.map((day) => ({
      dayNumber: day.dayNumber,
      timeSlots: day.timeSlots || [],
      planName: day.planName,
    }));

    const addonIds = Array.isArray(selectedAddons)
      ? `{${selectedAddons.map((addon) => addon.id).join(",")}}`
      : selectedAddons?.id
      ? `{${selectedAddons.id}}`
      : null;

    let hotelPlan = [];

    hotelSelections.forEach((field, index) => {
      hotelPlan.push({
        hotelType: field?.hotelType?.value,
        mealPlan: field?.mealPlan?.value,
        day: Array.isArray(field.day)
          ? `{${field.day.map((addon) => addon.value).join(",")}}`
          : field.day?.value
          ? `{${field.day.value}}`
          : null,
      });
    });
    let accomdaTion;
    const payload = { type: "accommodationtype" };
    const result = await post("/getOptionList", payload);
    if (result.data.length > 0) {
      accomdaTion = result.data.filter(
        (list) => list.label == accommodationValue
      );
    }

    const basePayload = {
      packagetypeid: selectedPackageType?.id,
      packagetype: selectedPackageType?.label,
      packagename: formValues.packagename,
      cityid: formValues.city,
      destination: formValues.destination,
      noofdays: Number(formValues.noofdays),
      noofnights: Number(formValues.noofnights),
      hoteltypeid: selectedHotel?.id,
      hoteltype: selectedHotel?.label,
      onwardtrnid: selectedOnwardTransport?.id,
      onward: selectedOnwardTransport?.label,
      returnid: selectedReturnTransport?.id,
      return: selectedReturnTransport?.label,
      transferid: selectedTransfers?.id,
      transfer: selectedTransfers?.label,
      addonid: addonIds,
      extra_bfcount: Number(extraBFCount),
      extra_lunchcount: Number(extraLunchCount),
      extra_dinnercount: Number(extraDinnerCount),
      noofpax: Number(numberOfPax),
      foc: selectedOption?.value,
      addon_amount: Number(addOnsAmount),
      deviation_amount: Number(deviationAmount),
      price_perhead: Number(totalPrice),
      total_amount: totalPrice,
      daywise_plan: formattedDays,
      package_image: formData?.logo ? formData?.logo : "",
      currency: "INR",
      total_amount: Number(totalPrice * numberOfPax),
      textEditorData: editorContents,
      city: formValues.city,
      source: formValues.city,
      dateoftravel: selectedDate,
      isAudit: true,
      user: user?.username || "N/A",
      isitinerary: false,
      hotel_mealplan: selectedHotelMealPlan?.value,
      isPackageType: location.state?.packageData?.packagedetailsid
        ? "update"
        : "insert",
      isitinerary: true,
      itineraryname: itineraryName,
      institutionid: selectedInstitution?.id,
      type: "insert",
      itinerary_travel: selectedDate,
      schoolname: selectedInstitution?.label,
      user: user?.username || "N/A",
      packagewise_plan: hotelPlan,
      packagedetailsid: location.state?.packageData?.packagedetailsid,
      hotelType: hotelType,
      accomdaTion: accomdaTion && accomdaTion.length > 0 ? accomdaTion[0] : {},
    };

    try {
      const response = await post("/addItinerary", basePayload);
      // if (response.status) {
      const payload = {
        ...basePayload,
        packagedetailsid:
          response?.data?.packagedetailsid ||
          location.state?.packageData?.packagedetailsid,
      };

      navigate("/add-enquiry", {
        state: {
          institution: selectedInstitution,
          packageData: payload,
          type: "insert",
          itineraryName: itineraryName,
        },
      });
      setIsModalOpen(false);
      // } else {
      //   toast.error("Failed to create itinerary");
      // }
    } catch (error) {
      toast.error("Error creating itinerary");
    }
  };

  const calculateAddonAmount = useCallback(() => {
    if (!selectedAddons || selectedAddons.length === 0) return 0;

    const totalAmount = selectedAddons.reduce((sum, addon) => {
      // Skip calculation for Extra BF, Extra Lunch, Extra Dinner
      if (["Extra BF", "Extra Lunch", "Extra Dinner"].includes(addon.label)) {
        return sum;
      }

      // Check if we're using seasonal pricing
      if (showSeasonalFields && addon.seasonstart && addon.seasonend) {
        const seasonStart = new Date(addon.seasonstart);
        const seasonEnd = new Date(addon.seasonend);
        const currentDate = new Date(selectedDate);

        // If date falls within seasonal period and sph exists, use seasonal price
        if (
          currentDate >= seasonStart &&
          currentDate <= seasonEnd &&
          addon.sph
        ) {
          return sum + Number(addon.sph);
        }
      }
      // Default to regular price
      return sum + Number(addon.pph);
    }, 0);

    return totalAmount;
  }, [selectedAddons, showSeasonalFields, selectedDate]);

  useEffect(() => {
    const calculatedAmount = calculateAddonAmount();
    setAddOnsAmount(calculatedAmount);
  }, [selectedAddons, showSeasonalFields, selectedDate, calculateAddonAmount]);

  const handleDeleteDay = (dayNumber) => {
    setDays((prevDays) =>
      prevDays.filter((day) => day.dayNumber !== dayNumber)
    );
  };
  const fetchHotelMealPlan = async (id) => {
    try {
      const payload = { type: "hotelname_hotelwiseplan", hotelid: id };
      const result = await post("/getOptionList", payload);

      const formattedOptions = result.data.map((item) => ({
        value: item.id,
        label: item.label,
        pph: item.pph,
        id: item.id,
        accommodationType: item.accommodationtype,
      }));

      return formattedOptions;
    } catch (error) {
      console.error("Error fetching hotel meal plans:", error);
      return [];
    }
  };

  const handleHotelMealPlanChange = (option) => {
    const selected = mealHotelPlanOptions.find(
      (hotel) => hotel.value === option.value
    );
    setSelectedHotelMealPlan(selected);
  };
  const handleHotelChanges = async (selectedOption, index, field) => {
    const updatedSelections = [...hotelSelections];

    if (field === "hotelType") {
      if (index == 0) {
        setHotelType({
          label: selectedOption.hoteltype,
          value: selectedOption.hoteltypeid,
        });
      }
      const mealOptions = await fetchHotelMealPlan(selectedOption.id);
      updatedSelections[index] = {
        ...updatedSelections[index],
        hotelType: selectedOption,
        options: mealOptions,
        mealPlan: null,
      };
    } else if (field === "mealPlan") {
      const price =
        showSeasonalFields &&
        isInSeasonalRange(
          selectedOption.seasonstart,
          selectedOption.seasonend,
          selectedDate
        )
          ? Number(selectedOption.sph)
          : Number(selectedOption.pph);

      if (index == 0) {
        selectedAccommodation(selectedOption.accommodationType);
      }

      updatedSelections[index] = {
        ...updatedSelections[index],
        mealPlan: {
          ...selectedOption,
          calculatedPrice: price,
        },
      };
    } else if (field === "day") {
      // Get all selected days from all hotel selections
      const selectedDays = hotelSelections.reduce((acc, selection) => {
        if (selection.day) {
          const days = Array.isArray(selection.day)
            ? selection.day.map((d) => d.value)
            : [selection.day.value];
          return [...acc, ...days];
        }
        return acc;
      }, []);

      // Filter out already selected days from available options
      const availableDays = dayOptions.filter(
        (option) => !selectedDays.includes(option.value)
      );

      updatedSelections[index] = {
        ...updatedSelections[index],
        day: selectedOption,
        availableDayOptions: availableDays,
      };
    }

    setHotelSelections(updatedSelections);
  };

  const handleAddDayField = () => {
    setHotelSelections([
      ...hotelSelections,
      { hotelType: null, mealPlan: null, day: null },
    ]);
  };

  const handleDeleteDayField = (index) => {
    const updatedSelections = hotelSelections.filter((_, i) => i !== index);
    setHotelSelections(updatedSelections);
  };

  const handleOpenPriceModal = () => {
    setIsPriceModalOpen(true);
  };

  const handleClosePriceModal = () => {
    setIsPriceModalOpen(false);
  };

  const isInSeason = (startDate, endDate) => {
    if (!startDate || !endDate || !selectedDate) return false;
    const currentDate = new Date(selectedDate);
    const seasonStart = new Date(startDate);
    const seasonEnd = new Date(endDate);
    return currentDate >= seasonStart && currentDate <= seasonEnd;
  };

  const _getPrice = (item) => {
    if (!showSeasonalFields || !item) return Number(item?.pph || 0);
    if (isInSeason(item?.seasonstart, item?.seasonend) && item?.sph) {
      return Number(item?.sph);
    }
    return Number(item?.pph || 0);
  };

  const _getHotelTotalAmout = (item) => {
    // if (!showSeasonalFields || !item) return Number(item?.pph || 0);
    // if (isInSeason(item?.seasonstart, item?.seasonend) && item?.sph) {
    //   return Number(item?.sph);
    // }
    // return Number(item?.pph || 0);
    let totalAmount = 0;

    item.map((list) => {
      totalAmount += list.hotelType?.pph || 0;
    });
    return Number(totalAmount);
  };

  const extractHotelType = (fullName) => {
    if (!fullName) return "";
    return fullName.split("-")[1]?.trim() || fullName;
  };
  useEffect(() => {
    const calculateHotelAmount = () => {
      const hotelTotal = hotelSelections.reduce((sum, selection) => {
        if (selection?.hotelType?.pph) {
          const basePrice =
            showSeasonalFields && selection.hotelType.sph
              ? Number(selection.hotelType.sph)
              : Number(selection.hotelType.pph);
          return sum + basePrice;
        }
        return sum;
      }, 0);

      const newTotalPrice = calculateTotalPrice() + hotelTotal;
      setTotalPrice(newTotalPrice);
    };

    if (hotelSelections.length > 0) {
      calculateHotelAmount();
    }
  }, [hotelSelections, showSeasonalFields]);

  useEffect(() => {
    const calculateInitialAddonAmount = () => {
      const addonsAmount = selectedAddons.reduce((sum, addon) => {
        const basePrice =
          showSeasonalFields && addon.sph
            ? Number(addon.sph)
            : Number(addon.pph || 0);

        switch (addon.label) {
          case "Extra BF":
            return sum + basePrice * 1; // Default to 1
          case "Extra Lunch":
            return sum + basePrice * 1; // Default to 1
          case "Extra Dinner":
            return sum + basePrice * 1; // Default to 1
          default:
            return sum + basePrice;
        }
      }, 0);

      setAddOnsAmount(addonsAmount);
    };

    if (selectedAddons?.length > 0) {
      calculateInitialAddonAmount();
    }
  }, [selectedAddons, showSeasonalFields]);

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <div>
          <div className="block md:block lg:flex justify-between">
            <div>
              <h2 className="text-primary-black text-[24px] font-semibold">
                Packages
              </h2>
              <p className="text-[12px]">
                <span className="font-bold">Packages</span> /
                <span className="font-regular">Add Package</span>
              </p>
            </div>
            <div className="flex flex-wrap gap-4 mt-4 md:my-4 justify-end">
              <Button
                className="w-full md:w-auto"
                label="Convert To Itinerary"
                onClick={handleConvertToItinerary}
              />
              <Button
                className="w-full md:w-auto"
                label={
                  <span className="inline-flex items-center gap-1">
                    Export PDF
                    <span
                      className="material-symbols-outlined ml-2"
                      style={{
                        fontVariationSettings:
                          "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24",
                      }}
                    >
                      picture_as_pdf
                    </span>
                  </span>
                }
              />
              {selectedValue === "single" &&
                user?.role !== "Sales Manager" &&
                user?.role !== "Sales Executive" && (
                  <Button
                    className="w-full md:w-auto"
                    type="primary"
                    label={isEdit ? "Update" : "Save"}
                    onClick={handleSavePackage}
                  />
                )}

              <Button
                className="w-full md:w-auto"
                type="secondary"
                label="Cancel"
                onClick={handleNavigateBack}
              />
            </div>
          </div>
          <Paper className="my-8 pt-6 pb-6">
            <Paper className="my-8 mt-4 border">
              <h1 className="text-[20px] font-bold mb-4">Package Details</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <div>
                    <label className="text-[16px] font-semibold mb-2">
                      Package Type <span className="text-red-500">*</span>
                    </label>
                    <Select
                      options={packageTypeOptions}
                      onChange={handlePackageTypeChange}
                      value={selectedPackageType}
                      placeholder="Select Package Type..."
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Package Name <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      placeholder="Package Name..."
                      name="packagename"
                      value={formValues.packagename}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold mb-2">
                      Source <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      name="city"
                      value={formValues.city}
                      onChange={handleInputChange}
                      placeholder="Enter source..."
                    />{" "}
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold mb-2">
                      Destination <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      name="destination"
                      value={formValues.destination}
                      onChange={handleInputChange}
                      placeholder="Enter destination..."
                    />{" "}
                  </div>
                  {!isDayOuting && (
                    <>
                      <div>
                        <label className="text-[16px] font-semibold">
                          No. of Days{" "}
                          {!isDayOuting && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <InputText
                          value={formValues.noofdays}
                          type={"number"}
                          onChange={(e) =>
                            setFormValues((prev) => ({
                              ...prev,
                              noofdays: e.target.value,
                            }))
                          }
                          placeholder="Enter Days Count..."
                        />
                      </div>
                      <div>
                        <label className="text-[16px] font-semibold">
                          No. of Nights{" "}
                          {!isDayOuting && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <InputText
                          value={formValues.noofnights}
                          type={"number"}
                          onChange={(e) =>
                            setFormValues((prev) => ({
                              ...prev,
                              noofnights: e.target.value,
                            }))
                          }
                          placeholder="Enter Nights Count..."
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-3">
                  <Upload
                    onFileSelect={handleUploadSelect}
                    labelText="Upload Image"
                    initialPreviewUrl={formData?.logo ? formData?.logo : null}
                    fileValidation={validateFile}
                    handleRemove={() => {
                      setFormData(null);
                    }}
                  />
                </div>
              </div>
            </Paper>
            <Paper className="my-8 border p-4">
              <div className="col-span-8 grid grid-cols-1  lg:grid-cols-5 mt-1 mb-4 items-center">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="seasonalPrice"
                    checked={showSeasonalFields}
                    onChange={(e) => setShowSeasonalFields(e.target.checked)}
                    className="mr-1"
                  />
                  <label
                    htmlFor="seasonalPrice"
                    className="text-[14px] font-semibold"
                  >
                    Seasonal
                  </label>
                </div>

                {/* Display seasonal fields when checkbox is checked */}
                {showSeasonalFields && (
                  <div className="flex items-center gap-1">
                    <div>
                      <label className="text-[14px] font-semibold mb-0">
                        Date Of Travel <span className="text-red-500">*</span>
                      </label>
                      <DatePicker
                        selectedDate={selectedDate}
                        onDateChange={(date) => handleDateChange(date)}
                        placeholderText="Start Date"
                        className="w-full mt-0"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* Main Form Fields */}
                <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <div className="col-span-3 flex flex-col gap-4 rounded-xl border-gray-300 p-4 mt-4 mb-2 border">
                    <h1 className="text-[20px] font-bold ">Select Hotel</h1>
                    {hotelSelections.map((selection, index) => (
                      <div key={index} className="w-full flex gap-4">
                        {/* Hotel Type */}
                        <div className="w-1/3">
                          <label className="text-[16px] font-semibold mb-2">
                            Hotel Type <span className="text-red-500">*</span>
                          </label>

                          <Select
                            options={hotelTypeOptions}
                            onChange={(selectedOption) =>
                              handleHotelChanges(
                                selectedOption,
                                index,
                                "hotelType"
                              )
                            }
                            value={hotelTypeOptions.find(
                              (option) =>
                                option.value === selection.hotelType?.id
                            )}
                            placeholder="Select Hotel Type..."
                          />
                        </div>

                        {/* Hotel Meal Plan */}
                        <div className="w-1/3">
                          <label className="text-[16px] font-semibold mb-2">
                            Hotel Meal Plan{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <Select
                            options={selection?.options || []}
                            placeholder="Select Hotel Meal Plan..."
                            onChange={(selectedOption) =>
                              handleHotelChanges(
                                selectedOption,
                                index,
                                "mealPlan"
                              )
                            }
                            value={selection?.mealPlan || null}
                            isDisabled={!selection.hotelType}
                          />
                        </div>

                        {/* Day */}
                        {!isDayOuting && (
                          <div className="w-1/3">
                            <label className="text-[16px] font-semibold mb-2">
                              Day <span className="text-red-500">*</span>
                            </label>
                            <MultiNewSelect
                              placeHolder="Select Day..."
                              options={
                                selection.availableDayOptions ||
                                dayOptions.filter(
                                  (day) =>
                                    !hotelSelections.some(
                                      (hotel) =>
                                        hotel.day &&
                                        hotel.day.some(
                                          (selectedDay) =>
                                            selectedDay.value === day.value
                                        )
                                    )
                                )
                              }
                              value={selection.day || null}
                              onChange={(selectedOption) =>
                                handleHotelChanges(selectedOption, index, "day")
                              }
                              isMulti
                              id="fileTypes"
                            />
                            {/* <Select
                            options={dayOptions}
                            placeholder="Select Day..."
                            onChange={(selectedOption) =>
                              handleHotelChanges(selectedOption, index, "day")
                            }
                            value={selection.day || null}
                          /> */}
                          </div>
                        )}
                        {/* Add/Delete Buttons */}
                        {!isDayOuting && (
                          <div className="flex gap-2 mt-6">
                            {index === hotelSelections.length - 1 ? (
                              <button
                                className="p-2 rounded bg-black text-white flex justify-center items-center"
                                onClick={handleAddDayField}
                              >
                                <span className="material-symbols-outlined">
                                  add
                                </span>
                              </button>
                            ) : (
                              <button
                                className="p-2 rounded bg-red-500 text-white flex justify-center items-center"
                                onClick={() => handleDeleteDayField(index)}
                              >
                                <span className="material-symbols-outlined">
                                  delete
                                </span>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="col-span-3 flex flex-col gap-4 rounded-xl border-gray-300 p-4 mt-4 mb-2 border">
                    <h1 className="text-[20px] font-bold mb-4">
                      Select Transportation
                    </h1>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                      {/* Onward Transportation */}
                      <div>
                        <label className="text-[16px] font-semibold">
                          {selectedPackageType?.label === "Day Outing"
                            ? "Transportation"
                            : "Onward Transportation"}
                          <span className="text-red-500">*</span>
                        </label>
                        <Select
                          options={filterTransportOptions(
                            onwardTransportOptions,
                            "onward"
                          )}
                          value={onwardTransportOptions.find(
                            (option) =>
                              option.value === selectedOnwardTransport?.id
                          )}
                          onChange={handleOnwardTransportChange}
                          placeholder={`Select ${
                            selectedPackageType?.label === "Day Outing"
                              ? "Transportation"
                              : "Onward Transportation"
                          }...`}
                        />
                      </div>

                      {/* Return Transportation */}
                      {selectedPackageType?.label !== "Day Outing" ? (
                        <div>
                          <label className="text-[16px] font-semibold mb-2">
                            Return Transportation
                            {selectedPackageType?.label === "International" && (
                              <span className="text-red-500">*</span>
                            )}
                          </label>
                          <Select
                            options={filterTransportOptions(
                              returnTransportOptions,
                              "return"
                            )}
                            value={returnTransportOptions.find(
                              (option) =>
                                option.value === selectedReturnTransport?.id
                            )}
                            onChange={handleReturnTransportChange}
                            placeholder="Select Return Transportation..."
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* Transfers */}
                      <div>
                        <label className="text-[16px] font-semibold mb-2">
                          Transfers
                        </label>
                        <Select
                          options={filterTransportOptions(
                            transferOptions,
                            "transfers"
                          )}
                          value={transferOptions.find(
                            (option) => option.value === selectedTransfers?.id
                          )}
                          onChange={handleTransfersChange}
                          placeholder="Select Transfers..."
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="text-[16px] font-semibold">
                      No. of Pax <span className="text-red-500">*</span>
                      <span className="text-gray-700 text-sm mb-2 pl-2">
                        Exclude FOC
                      </span>
                    </label>
                    <InputText
                      placeholder="Enter number..."
                      type={"number"}
                      value={numberOfPax}
                      onChange={(e) => setNumberOfPax(Number(e.target.value))}
                    />{" "}
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Addons <span className="text-red-500">*</span>
                    </label>
                    <MultiNewSelect
                      // className="form-control"
                      placeHolder="Select Addons..."
                      options={addonOptions}
                      // name="fileTypes"
                      value={selectedAddons}
                      onChange={(e) => handleAddonsChange(e, "addons")}
                      isMulti
                      id="fileTypes"
                    />
                    {/* <MultiSelect
                    options={addonOptions}
                    value={selectedAddons}
                    onChange={handleAddonsChange}
                    placeholder="Select Addons..."
                  /> */}
                  </div>

                  {showExtraBF && (
                    <div>
                      <label className="text-[16px] font-semibold">
                        Extra BF Count <span className="text-red-500">*</span>
                      </label>
                      <InputText
                        type="number"
                        min="1"
                        placeholder="Enter Count..."
                        value={extraBFCount || 1}
                        onChange={(e) =>
                          setExtraBFCount(Math.max(1, Number(e.target.value)))
                        }
                      />
                    </div>
                  )}

                  {showExtraLunch && (
                    <div>
                      <label className="text-[16px] font-semibold">
                        Extra Lunch Count{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <InputText
                        type="number"
                        min="1"
                        placeholder="Enter Count..."
                        value={extraLunchCount || 1}
                        onChange={(e) =>
                          setExtraLunchCount(
                            Math.max(1, Number(e.target.value))
                          )
                        }
                      />
                    </div>
                  )}

                  {showExtraDinner && (
                    <div>
                      <label className="text-[16px] font-semibold">
                        Extra Dinner Count{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <InputText
                        type="number"
                        min="1"
                        placeholder="Enter Count..."
                        value={extraDinnerCount || 1}
                        onChange={(e) =>
                          setExtraDinnerCount(
                            Math.max(1, Number(e.target.value))
                          )
                        }
                      />
                    </div>
                  )}

                  <div>
                    <label className="text-[16px] font-semibold">FOC</label>
                    <Select
                      options={options}
                      value={selectedOption}
                      onChange={handleChange}
                      placeholder="Select FOC..."
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Add Ons Calculated Amount{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      placeholder="Enter Amount..."
                      type={"number"}
                      value={addOnsAmount}
                      onChange={(e) => setAddOnsAmount(Number(e.target.value))}
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Deviation Allowed Amount{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <InputText
                      placeholder="Enter Amount..."
                      type={"number"}
                      value={deviationAmount}
                      onChange={(e) =>
                        setDeviationAmount(Number(e.target.value))
                      }
                    />{" "}
                  </div>
                </div>

                {/* Price Box */}
                <div className="bg-[#CDD8CB] p-6 rounded-lg max-w-sm mx-auto text-left flex flex-col justify-center items-center min-h-[200px]">
                  {/* Content Centered */}
                  <span className="material-symbols-outlined text-gray-700 text-3xl mb-2">
                    payments
                  </span>
                  <div className="text-gray-700 text-sm mb-2 text-center">
                    Price/ head (INR)
                  </div>
                  <div className="text-4xl font-bold text-black mb-4 text-center">
                    ₹ {totalPrice.toLocaleString("en-IN")}
                  </div>
                  <div className="text-xs text-gray-600 mb-4 text-center">
                    **Above number is based on the head count and other add-ons
                    entered, this may change based on the count change.
                  </div>

                  {/* Button Centered */}
                  <button
                    className="bg-transparent text-black border border-black hover:bg-gray-100 h-[40px] md:h-[42px] leading-[10px] text-[16px] md:text-[16px]"
                    onClick={handleOpenPriceModal}
                  >
                    Price Breakdown
                  </button>
                </div>

                {isPriceModalOpen && (
                  <div
                    id="modal-overlay"
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    onClick={handleClosePriceModal}
                  >
                    <div
                      className="bg-white p-6 rounded-lg shadow-lg max-w-fit w-full relative"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <h2 className="text-xl font-bold mb-4">
                        Price Breakdown
                      </h2>
                      <table className="table-auto w-full border-collapse">
                        <thead>
                          <tr>
                            {/* {hotelSelections.map((hotel) => (
                              <th
                                key={hotel?.hotelType?.label}
                                className="border p-2"
                              >
                                {hotel?.hotelType?.label}
                              </th>
                            ))} */}
                            <th className="border p-2">Hotels</th>

                            <th className="border p-2">
                              Onward Transportation
                            </th>
                            <th className="border p-2">
                              Return Transportation
                            </th>
                            <th className="border p-2">Transfers</th>
                            <th className="border p-2">Addons</th>
                            <th className="border p-2">
                              {" "}
                              Deviation Allowed Amount
                            </th>
                            <th className="border p-2">Markup (20%)</th>
                            <th className="border p-2">GST (5%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>*</td>
                            <td className="border p-2">
                              {Number(_getPrice(selectedOnwardTransport))}
                            </td>
                            <td className="border p-2">
                              {Number(_getPrice(selectedReturnTransport))}
                            </td>
                            <td className="border p-2">
                              {Number(_getPrice(selectedTransfers))}
                            </td>
                            <td className="border p-2">
                              {selectedAddons
                                .map((item) => item.label)
                                .join(",")}
                            </td>
                            <td className="border p-2">{deviationAmount}</td>
                            <td className="border p-2">₹ -</td>
                            <td className="border p-2">₹ -</td>
                          </tr>

                          {hotelSelections.map((hotel) => (
                            <tr>
                              <td
                                key={hotel?.hotelType?.pph || 0}
                                className="border p-2"
                              >
                                {hotel?.hotelType?.label.split("-")[0]?.trim()}{" "}
                                - {hotel?.hotelType?.pph || 0}
                              </td>
                              <td className="border p-2"></td>
                              <td className="border p-2"></td>
                              <td className="border p-2"></td>
                              <td className="border p-2"></td>
                              <td className="border p-2"></td>
                            </tr>
                          ))}

                          {/* <tr>
                            <td className="border p-2">Hotel 2 - 500</td>
                            <td className="border p-2">
                              Return Transportation - 500
                            </td>
                            <td className="border p-2">Extra Lunch - 500</td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                          </tr>
                          <tr>
                            <td className="border p-2">Hotel 3 - 500</td>
                            <td className="border p-2">Transfers - 500</td>
                            <td className="border p-2">Sightseeing - 500</td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                          </tr>
                          <tr>
                            <td className="border p-2">Hotel 4 - 500</td>
                            <td className="border p-2"></td>
                            <td className="border p-2">Activity - 500</td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                          </tr>
                          <tr>
                            <td className="border p-2">Hotel 5 - 500</td>
                            <td className="border p-2"></td>
                            <td className="border p-2">Gifts - 400</td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                          </tr>
                          <tr>
                            <td className="border p-2">Hotel 6 - 500</td>
                            <td className="border p-2"></td>
                            <td className="border p-2">Snacks - 600</td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                          </tr>
                          <tr>
                            <td className="border p-2">Hotel 7 - 500</td>
                            <td className="border p-2"></td>
                            <td className="border p-2">Extra Bf - 400</td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                          </tr>
                          <tr>
                            <td className="border p-2">Hotel 8 - 500</td>
                            <td className="border p-2"></td>
                            <td className="border p-2">Lunch - 400</td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                            <td className="border p-2"></td>
                     
                          </tr> */}
                          <tr>
                            <td className="border p-2">
                              Total ={" "}
                              {Number(_getHotelTotalAmout(hotelSelections))}
                            </td>
                            <td className="border p-2">
                              Total ={" "}
                              {Number(_getPrice(selectedOnwardTransport))}
                            </td>
                            <td className="border p-2">
                              Total ={" "}
                              {Number(_getPrice(selectedReturnTransport))}
                            </td>
                            <td className="border p-2">
                              Total = {Number(_getPrice(selectedTransfers))}
                            </td>
                            <td className="border p-2"></td>
                            <td className="border p-2">
                              Total = {deviationAmount}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <button
                        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                        onClick={handleClosePriceModal}
                      >
                        ✕
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Paper>

            <div className="bg-white rounded-xl border-gray-300 p-4 my-8 border">
              <h1 className="text-[20px] font-bold mb-4">Day-wise Planning</h1>
              <div className="grid grid-cols-1 gap-4">
                {!isFirstDayAdded ? (
                  <Paper className="border-2 border-dashed border-black p-8  flex justify-center items-center flex-col">
                    <button
                      onClick={handleAddDay}
                      className=" bg-white text-black border border-black rounded-md py-2 px-12 text-lg mb-4 mt-4"
                    >
                      Add Day
                    </button>
                    <p className="text-center text-gray-500 mt-4">
                      No day plan to display
                    </p>
                  </Paper>
                ) : (
                  <div className="grid grid-cols-1  lg:grid-cols-2 gap-4 ">
                    {days.map((day, index) => (
                      <div key={index} className="w-full">
                        <DayPlan
                          dayNumber={day.dayNumber}
                          initialTimeSlots={day.timeSlots}
                          planName01={day.planName} // Assuming planName is the same for all time slots
                          daywiseImage={day.daywiseImage}
                          onChange={(planData) =>
                            handleDayPlanChange(
                              day.dayNumber,
                              day.planName,
                              planData
                            )
                          }
                          onDelete={() => handleDeleteDay(day.dayNumber)}
                        />
                      </div>
                    ))}

                    <Paper className="border-2 border-dashed border-black p-8  flex justify-center items-center flex-col">
                      <button
                        onClick={handleAddDay}
                        className=" bg-white text-black border border-black rounded-md py-2 px-12 text-lg"
                      >
                        Add Day
                      </button>
                    </Paper>
                  </div>
                )}
              </div>
            </div>
            <div className="container mx-auto py-6">
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Transportation Details{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <TextEditor
                    initialValue={editorContents.transportation_details}
                    onChange={(content) =>
                      handleEditorChange(content, "transportation_details")
                    }
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Hotel Details <span className="text-red-500">*</span>
                  </label>
                  <TextEditor
                    initialValue={editorContents.hotel_details}
                    onChange={(content) =>
                      handleEditorChange(content, "hotel_details")
                    }
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Meal Plan <span className="text-red-500">*</span>
                  </label>
                  <TextEditor
                    initialValue={editorContents.meal_plan}
                    onChange={(content) =>
                      handleEditorChange(content, "meal_plan")
                    }
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Inclusions <span className="text-red-500">*</span>
                  </label>
                  <TextEditor
                    initialValue={editorContents.inclusions}
                    onChange={(content) =>
                      handleEditorChange(content, "inclusions")
                    }
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Exclusions<span className="text-red-500">*</span>
                  </label>
                  <TextEditor
                    initialValue={editorContents.exclusions}
                    onChange={(content) =>
                      handleEditorChange(content, "exclusions")
                    }
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Terms and Conditions<span className="text-red-500">*</span>
                  </label>
                  <TextEditor
                    initialValue={editorContents.terms_conditions}
                    onChange={(content) =>
                      handleEditorChange(content, "terms_conditions")
                    }
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Learning Outcomes<span className="text-red-500">*</span>
                  </label>
                  <TextEditor
                    initialValue={editorContents.learning_outcomes}
                    onChange={(content) =>
                      handleEditorChange(content, "learning_outcomes")
                    }
                  />
                </div>
              </div>
            </div>
            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                <div className="bg-white rounded-lg p-6 w-96">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Convert to Itinerary</h2>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <span className="material-symbols-outlined">close</span>
                    </button>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <label className="text-[16px] font-semibold mb-2 block">
                        Itinerary Name<span className="text-red-500">*</span>
                      </label>
                      <InputText
                        placeholder="Enter itinerary name..."
                        value={itineraryName}
                        onChange={(e) => setItineraryName(e.target.value)}
                      />
                    </div>

                    <div>
                      <label className="text-[16px] font-semibold mb-2 block">
                        Select Institution
                        <span className="text-red-500">*</span>
                      </label>
                      <Select
                        options={institutionOptions}
                        onChange={handleInstitutionSelect}
                        value={selectedInstitution}
                        placeholder="Select Institution..."
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-2 mt-6">
                    <Button
                      type="secondary"
                      label="Cancel"
                      onClick={() => setIsModalOpen(false)}
                    />
                    <Button
                      type="primary"
                      label="Add Enquiry"
                      onClick={handleConvertToEnquiry}
                      disabled={!selectedInstitution || !itineraryName}
                    />
                  </div>
                </div>
              </div>
            )}
          </Paper>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default AddPackages;

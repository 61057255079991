import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import { useEffect, useState } from "react";
import UploadHotel from "../customComponents/UploadHotel";
import RadioButton from "../customComponents/RadioButton";
import DatePicker from "../customComponents/DatePicker";
import { post } from "../utils/api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddAddons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editData = location.state?.editData;
  const isEditMode = !!editData;

  const [selectedStartDate, setSelectedStartDate] = useState(
    editData?.seasonstart ? new Date(editData.seasonstart) : new Date()
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    editData?.seasonend ? new Date(editData.seasonend) : new Date()
  );
  const [selectedValue, setSelectedValue] = useState("single");
  const [showSeasonalFields, setShowSeasonalFields] = useState(false);

  const [addonOptions, setAddonOptions] = useState([]);
  const [addonItem, setAddonItem] = useState(editData?.addonitem || "");
  const [addonPrice, setAddonPrice] = useState(editData?.addonprice || "");
  const [seasonalPrice, setSeasonalPrice] = useState(
    editData?.season_priceperhead || ""
  );
  const [selectedAddon, setSelectedAddon] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleAddonSelect = async () => {
    try {
      const response = await post("/getOptionList", { type: "addons" });
      setAddonOptions(response.data);
    } catch (error) {
      console.error("Error fetching addon options:", error);
      toast.error("Error loading addon options");
    }
  };

  const validateFields = () => {
    if (!selectedAddon) {
      toast.error("Please select an addon item");
      return false;
    }

    if (!addonPrice || isNaN(addonPrice) || addonPrice <= 0) {
      toast.error("Please enter a valid price per head");
      return false;
    }

    if (showSeasonalFields) {
      if (!selectedStartDate || !selectedEndDate) {
        toast.error("Please select both season start and end dates");
        return false;
      }

      if (selectedStartDate > selectedEndDate) {
        toast.error("Season end date must be after start date");
        return false;
      }

      if (!seasonalPrice || isNaN(seasonalPrice) || seasonalPrice <= 0) {
        toast.error("Please enter a valid seasonal price per head");
        return false;
      }
    }

    return true;
  };

  const handleSave = async () => {
    if (!selectedAddon?.value) {
      toast.error("Please select an addon item");
      return;
    }

    if (!addonPrice) {
      toast.error("Please enter price per head");
      return;
    }

    if (showSeasonalFields) {
      if (!selectedStartDate || !selectedEndDate) {
        toast.error("Please select seasonal dates");
        return;
      }
      if (!seasonalPrice) {
        toast.error("Please enter seasonal price");
        return;
      }
    }

    const payload = {
      type: isEditMode ? "update" : "insert",
      addonitem: selectedAddon?.value || "",
      addonprice: addonPrice,
      seasonstart: showSeasonalFields ? selectedStartDate.toISOString() : null,
      seasonend: showSeasonalFields ? selectedEndDate.toISOString() : null,
      season_priceperhead: showSeasonalFields ? seasonalPrice : null,
      addonid: isEditMode ? editData.addonid : selectedAddon?.id || null,
    };

    try {
      const response = await post("/addAddon", payload);
      if (response.status) {
        toast.success(
          isEditMode ? "Addon updated successfully" : "Addon saved successfully"
        );
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        toast.error("Error: Non-successful response");
      }
    } catch (error) {
      toast.error(isEditMode ? "Error updating addon" : "Error saving addon");
    }
  };

  const handleNavigateCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    handleAddonSelect();
    if (editData) {
      setSelectedAddon({
        id: editData.addonid,
        value: editData.addonitem,
      });
      setShowSeasonalFields(!!editData.seasonstart);
    }
  }, [editData]);

  return (
    <div>
      <div className="block md:flex lg:flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            {isEditMode ? "Edit Addon" : "Add Addon"}
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Master Data</span> /
            <span className="font-regular">Add Addons</span>
          </p>
        </div>

        <div className="flex mt-4 justify-end gap-4">
          <Button
            type="primary"
            label={isEditMode ? "Update" : "Save"}
            onClick={handleSave}
          />
          <Button
            type="secondary"
            label="Cancel"
            onClick={handleNavigateCancel}
          />
        </div>
      </div>

      <Paper className="my-8 pt-6 pb-6">
        <h1 className="text-[16px] font-semibold">Upload Type</h1>
        <div className="mt-4 space-y-4">
          <RadioButton
            name="customRadio"
            value="single"
            checked={selectedValue === "single"}
            onChange={handleRadioChange}
            label="Single Upload"
          />
          <RadioButton
            name="customRadio"
            value="bulk"
            checked={selectedValue === "bulk"}
            onChange={handleRadioChange}
            label="Bulk Upload"
            className={"ml-4"}
          />
        </div>

        {selectedValue === "single" && (
          <Paper className="my-8 border">
            <h1 className="text-[20px] font-bold mb-4">Transport Details</h1>
            <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Addon Item <span className="text-red-500">*</span>
                </label>
                <Select
                  options={addonOptions}
                  placeholder="Select..."
                  value={addonOptions.find(
                    (option) => option.value === selectedAddon?.value
                  )}
                  onChange={(selectedOption) => {
                    setSelectedAddon(selectedOption);
                    setAddonItem(selectedOption.value);
                  }}
                  onClick={handleAddonSelect}
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Price Per Head <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Enter Amount..."
                  value={addonPrice}
                  onChange={(e) => setAddonPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="col-span-4 flex items-center gap-2 mt-4 mb-4">
              <input
                type="checkbox"
                id="seasonalPrice"
                checked={showSeasonalFields}
                onChange={(e) => setShowSeasonalFields(e.target.checked)}
              />
              <label
                htmlFor="seasonalPrice"
                className="text-[16px] font-semibold"
              >
                Seasonal Price
              </label>
            </div>
            {showSeasonalFields && (
              <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div>
                  <label className="text-[16px] font-semibold">
                    Season Start Date <span className="text-red-500">*</span>
                  </label>
                  <DatePicker
                    selectedDate={selectedStartDate}
                    onDateChange={handleStartDateChange}
                    placeholderText="Start Date"
                    className="w-full"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">
                    Season End Date <span className="text-red-500">*</span>
                  </label>
                  <DatePicker
                    selectedDate={selectedEndDate}
                    onDateChange={handleEndDateChange}
                    placeholderText="End Date"
                    className="w-full"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Seasonal Price Per Head{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    placeholder="Enter Seasonal Price..."
                    value={seasonalPrice}
                    onChange={(e) => setSeasonalPrice(e.target.value)}
                  />
                </div>
              </div>
            )}
          </Paper>
        )}
        <ToastContainer />
        {selectedValue === "bulk" && (
          <Paper className="my-8 border">
            <h1 className="text-[20px] font-bold mb-4">Upload File</h1>
            <UploadHotel />
          </Paper>
        )}
      </Paper>
    </div>
  );
};

export default AddAddons;

import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ element: Component }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    if (user.role === "Backend Team" || user.role === "Backend Team Manager") {
      return <Navigate to="/dashboard" replace />;
    }
    return <Navigate to="/institutions" replace />;
  }

  return <Component />;
};

export default PublicRoute;

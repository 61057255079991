import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import packageslogo from "../assets/img/export-pdf-icons/packages-logo.svg";
export const GeneratePackagePDF = async (packageData) => {
    // Helper function to format time slots
    const formatTimeSlots = (slots) => {
        if (!slots || !Array.isArray(slots)) return "";
        return slots
            .map(
                (slot) => ` 
      <li class="list-item">
        ${slot.time} - ${slot.activity}
      </li>`
            )
            .join("");
    };
    const content = `

    <table
    style="width: 100%; border-collapse: collapse; font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;">

    <tr style="position: relative;">
        <td style="padding:0px; text-align: center; position: relative;" colspan="4">
            <img src="https://www.coorgexpress.com/wp-content/uploads/slider/cache/3387fe5e62e5a562f94923fca25978f6/1712158210.jpg"
                alt="Kodaikanal Background" style="width: 100%; height: 500px; object-fit: cover;">
            <table style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                    text-align: center;">
                <tr>
                    <td>
                        <img src="https://www.coorgexpress.com/wp-content/uploads/2024/07/logo.png" alt="Logo"
                            style="width: 150px; height: auto;">
                    </td>
                </tr>
            </table>
        </td>
    </tr>


    <tr style="position: relative;">
        <td style="padding: 10px;text-align: center;" colspan="4" style="padding: 0;">
            <div>
                <h1 style="margin: 0;font-size: 50px;color: #D22029;font-style: italic;
                    ">Kodaikanal Itinerary</h1>
                <div>
                    <p style=" margin:30px 0 0 0;
                        font-size: 20px;
                        color: #4E4F4E;">Domestic</p>
                </div>
            </div>
        </td>
    </tr>

    <tr>
        <td style="padding: 10px; text-align: center;" colspan="4">
            <img src="${packageslogo}" alt="Packages Logo"
                style="width: 50px; height: auto; display: block; margin: 0 auto;" />
            <h1 style="font-size: 18px;">
                3 Nights 4 Days
            </h1>
        </td>
    </tr>
    <tr>
        <td style="margin:0;padding: 10px;text-align: center;" colspan="4"><span style="display: inline-block;
                background-color: #D22029;
                font-size: 26px;
                color: white;
                 padding: 0 30px;padding-bottom: 20px;
                border-radius: 15px;margin: 0;">Package Details</span>
        </td>
    </tr>



    <tr>
        <td colspan="2" style="text-align: center;">
            <h3>Onward Transportation:</h3>
            <table style="width: 100%; border: none; text-align: center;">
                <tr>
                    <td bgcolor="#336666"
                        style="display: inline-block;font-size: 20px; color: white; padding: 0 50px;padding-bottom: 20px; border-radius: 14px; margin-top: 10px;">
                        Flight</td>
                </tr>
            </table>
        </td>
        <td colspan="2" style="text-align: center;">
            <h3>Hotel Type:</h3>
            <table style="width: 100%; border: none; text-align: center;">
                <tr>
                    <td bgcolor="#336666"
                        style="display: inline-block; font-size: 20px; color: white; padding: 0 50px;padding-bottom: 20px; border-radius: 14px; margin-top: 10px;">
                        5 Star</td>
                </tr>
            </table>
        </td>
    </tr>

    <tr>
        <td colspan="2" style="text-align: center;">
            <h3>Return Transportation:</h3>
            <table style="width: 100%; border: none; text-align: center;">
                <tr>
                    <td bgcolor="#336666"
                        style="display: inline-block;font-size: 20px; color: white; padding: 0 50px;padding-bottom: 20px; border-radius: 14px; margin-top: 10px;">
                        Flight</td>
                </tr>
            </table>
        </td>
        <td colspan="2" style="text-align: center;">
            <h3>Price Per Head:</h3>
            <table style="width: 100%; border: none; text-align: center;">
                <tr>
                    <td bgcolor="#336666"
                        style="display: inline-block;font-size: 20px; color: white; padding: 0 50px;padding-bottom: 20px; border-radius: 14px; margin-top: 10px;">
                        4,500/- INR</td>
                </tr>
            </table>
        </td>
    </tr>

    <table
        style="width: 100%; border-collapse: collapse; font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif; page-break-before: always;margin-top:150px;">

        <tr>
        <tr>
            <td colspan="4" style="padding: 20px 0; text-align: center;">
                <h1 style="font-size: 42px;color: #336666;margin: 0;text-align: center;font-style:italic;">Itinerary
                    Details</h1>
            </td>
        </tr>

        <tr>
            <td colspan="4" style="padding: 20px;">
                <table style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <!-- Day 1 Column -->
                        <td style="width: 50%; padding: 20px;text-align: right; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">
                                <h2 style="font-size: 32px;color: #D22029;margin: 0;font-style: italic;">Day 1</h2>
                                <p style="font-size: 20px;color: #336666;margin: 12px 0;font-weight: 400;">School
                                    -
                                    Kodaikanal</p>
                                <img src="https://www.coorgexpress.com/wp-content/uploads/slider/cache/3387fe5e62e5a562f94923fca25978f6/1712158210.jpg"
                                    width="500px" style="border-radius: 20px;margin-top: 20px;"
                                    alt="Kodaikanal Background">
                            </div>
                        </td>

                        <!-- Day 2 Column -->
                        <td style="width: 50%; padding: 20px; text-align:left; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">

                                <ul style="padding: 0; margin: 0; list-style: none;">
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 6;"></span>
                                        <span>01.00 am - All are assemble at school.</span>

                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        01.30am - Starts from Campus.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08.30am - Breakfast at Namakkal.

                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        01.00pm - Check-in

                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 6;"></span>
                                        01.30pm - Lunch at Resort.

                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        03.30pm - Proceed to Kodaikanal Lake

                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        04.00pm - Reach at Kodaikanal Lake.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        05.30pm - Return to Resort
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        06.00pm - Reach at Resort
                                    </li>
                                    <li
                                        style="padding-bottom: 10px;  position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        Overnight stay at Resort.
                                    </li>
                                </ul>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>

        </tr>
        <tr>
            <td colspan="4" style="padding: 20px;">
                <table style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <!-- Day 1 Column -->
                        <td style="width: 50%; padding: 20px; text-align: right; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">
                                <h2 style="font-size: 32px;color: #D22029;margin: 0;font-style: italic;">Day 1</h2>
                                <p style="font-size: 20px;color: #336666;margin: 9px 0;font-weight: 400;">School
                                    -
                                    Kodaikanal</p>
                                <img src="https://www.coorgexpress.com/wp-content/uploads/slider/cache/3387fe5e62e5a562f94923fca25978f6/1712158210.jpg"
                                    width="500px" height="auto" style="border-radius: 20px;margin-top: 20px;"
                                    alt="Kodaikanal Background">
                            </div>
                        </td>

                        <!-- Day 2 Column -->
                        <td style="width: 50%; padding: 20px; text-align:left; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">

                                <ul style="padding: 0; margin: 0; list-style: none;">
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        01:30 PM – Return to Resort.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        01:30 PM – Return to Resort.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>

                                </ul>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>

        </tr>
    </table>

    <table
        style="width: 100%; border-collapse: collapse; font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif; page-break-before: always;margin-top:160px;">

        <tr>
            <td colspan="4" style="padding: 20px;">
                <table style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <!-- Day 1 Column -->
                        <td style="width: 50%; padding: 20px; text-align: right; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">
                                <h2 style="font-size: 32px;color: #D22029;margin: 0;font-style: italic;">Day 3</h2>
                                <p style="font-size: 20px;color: #336666;margin: 9px 0;font-weight: 400;">School
                                    -
                                    Kodaikanal</p>
                                <img src="https://www.coorgexpress.com/wp-content/uploads/slider/cache/3387fe5e62e5a562f94923fca25978f6/1712158210.jpg"
                                    width="500px" height="auto" style="border-radius: 20px;margin-top: 20px;"
                                    alt="Kodaikanal Background">
                            </div>
                        </td>

                        <!-- Day 2 Column -->
                        <td style="width: 50%; padding: 20px; text-align:left; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">

                                <ul style="padding: 0; margin: 0; list-style: none;">
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        01:30 PM – Return to Resort.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        01:30 PM – Return to Resort.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>

                                </ul>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>

        </tr>
        <tr>
            <td colspan="4" style="padding: 20px;">
                <table style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <!-- Day 1 Column -->
                        <td style="width: 50%; padding: 20px; text-align: right; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">
                                <h2 style="font-size: 32px; color: #D22029;margin: 0;font-style: italic;">Day 2</h2>
                                <p style="font-size: 20px;color: #336666;margin: 9px 0;font-weight: 400;">School
                                    -
                                    Kodaikanal</p>
                                <img src="https://www.coorgexpress.com/wp-content/uploads/slider/cache/3387fe5e62e5a562f94923fca25978f6/1712158210.jpg"
                                    width="500px" height="auto" style="border-radius: 20px;margin-top: 20px;"
                                    alt="Kodaikanal Background">
                            </div>
                        </td>

                        <!-- Day 2 Column -->
                        <td style="width: 50%; padding: 20px; text-align:left; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">

                                <ul style="padding: 0; margin: 0; list-style: none;">
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        01:30 PM – Return to Resort.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        01:30 PM – Return to Resort.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        07:30 AM – Breakfast.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        08:30 AM – Proceed to Vadakaraparai Tribal School.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px; border-left: 1px solid #336666; position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px; height: 10px; background: white; border: 1px solid #336666; border-radius: 50%; position: absolute; left: -7px; top: 0;"></span>
                                        09:45 AM – Reach at School.
                                    </li>

                                </ul>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>

        </tr>
    </table>

    

   <table
        style="width: 100%; border-collapse: collapse; font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;margin-top:90px;">

        <tr>
            <td colspan="4" style="padding: 20px 0; text-align: center;">
                <h1 style="font-size: 36px; color: #336666; margin: 0; text-align: center;">Other Details</h1>
            </td>
        </tr>
        <tr>
            <td colspan="4" style="padding: 0px;">
                <table style=" width: 100%; border-collapse: collapse;">
                    <tr>
                        <!-- Day 1 Column -->
                        <td style="width: 50%; padding: 0px; text-align: center; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">
                                <h2 style="font-size: 24px; color: #336666; margin: 0;padding-bottom:18px"">INCLUSIONS</h2>
                                <ul style="padding: 0; margin: 0; list-style: none;">
                                 
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Breakfast/Lunch/Dinner

                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Water bottle/ Snacks

                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        General Entrance Fees

                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Sightseeing as per the itinerary

                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Service Expert Tour Manager

                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        GST
                                    </li>
                                      <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        GST
                                    </li>
                                </ul>
                            </div>
                        </td>

                        <!-- Day 2 Column -->
                        <td style="width: 50%; padding: 0px; text-align: center; vertical-align: top;">
                            <div style="display: inline-block; text-align: left;">
                                <h2 style="font-size: 24px; color: #336666; margin: 0;padding-bottom:18px"">EXCLUSIONS</h2>
                                <ul style="padding: 0; margin: 0; list-style: none;">
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Tips to waiters/drivers/any other person.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Monument fees at all places and gardens.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Any other thing which is not mentioned in
                                        inclusions.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Tour Itinerary can be changed depending
                                        upon the availability of Hotels.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Any other thing which is not mentioned in
                                        inclusions.
                                    </li>
                                    <li
                                        style="padding-bottom: 10px;position: relative; padding-left: 20px; margin-left: 10px;">
                                        <span
                                            style="content: ''; width: 10px;height: 10px;background: #336666;border: 1px solid #336666;position: absolute;left: -2px;top: 14px;border-radius: 50px;"></span>
                                        Tour Itinerary can be changed depending
                                        upon the availability of Hotels.
                                    </li>
                                </ul>
                            </div>
                        </td>

                    </tr>
                </table>
            </td>

        </tr>
    </table>

    <table style="width: 100%; border-collapse: collapse; font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif; margin-top: -10px">
    <tr>
        <td colspan="4" style="padding: 0px 80px;">
            <table style="width: 100%; border-collapse: collapse;">
                <tr>
                    <!-- Day 1 Column -->
                    <td style="width: 50%; padding: 0px; text-align: left; vertical-align: top;">
                        <div style="display: inline-block; text-align: left;">
                            <h2 style="font-size: 24px; color: #336666; margin: 0;padding-bottom:18px;padding-left:14px;padding-right:14px;">Transportation Details</h2>
                            <p style="padding:0px 14px"> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                    </td>

                    <!-- Day 2 Column -->
                    <td style="width: 50%; padding: 0px; text-align: left; vertical-align: top;">
                        <div style="display: inline-block; text-align: left;">
                            <h2 style="font-size: 24px; color: #336666; margin: 0;padding-bottom:18px;padding-left:16px;padding-right:14px;">Hotel Details</h2>
                            <p style="padding:0px 16px"> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                        </div>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>

<table style="width: 100%; border-collapse: collapse; font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;">
    <tr>
        <td colspan="4" style="padding:0px 80px;">
            <table style="width: 100%; border-collapse: collapse;">
                <tr>
                    <!-- Day 1 Column -->
                    <td style="width: 50%; padding: 0px; text-align: left; vertical-align: top;">
                        <div style="display: inline-block; text-align: left;">
                            <h2 style="font-size: 24px; color: #336666; margin: 0;padding-bottom:18px;padding-left:16px;padding-right:14px;">Meal Plan</h2>
                          <p style="padding:0px 14px"> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                        </div>
                    </td>

                    <!-- Day 2 Column -->
                    <td style="width: 50%; padding: 0px; text-align: left; vertical-align: top;">
                        <div style="display: inline-block; text-align: left;">
                            <h2 style="font-size: 24px; color: #336666; margin: 0;padding-bottom:18px;padding-left:16px;padding-right:14px;"">Terms and Conditions</h2>
                             <p style="padding:0px 16px"> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                        </div>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>

<table style="width: 100%; border-collapse: collapse; font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;">
    <tr>
        <td colspan="4" style="padding:0px 80px;">
            <table style="width: 50%; border-collapse: collapse;">
                <tr>
                    <!-- Day 1 Column -->
                    <td style="width: 60%; padding: 10px; text-align: left; vertical-align: top;">
                        <div style="display: inline-block; text-align: left;">
                            <h2 style="font-size: 24px; color: #336666; margin: 0;padding-bottom:18px;padding-left:12px;padding-right:10px;"">Learning Outcomes</h2>
                         <p style="padding:0px 12px"> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently.</p>

                        </div>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>

</table>

<footer>
    <table
        style="width: 100%; background-color: #EFEFEF; color: #336666; font-family: 'DM Sans'; font-size: 8px; font-weight: 800; text-align: center; padding: 20px; border-collapse: collapse;padding-top:50px;padding-bottom:50px;margin-top:100px;">
        <!-- Logo Section -->
        <tr>
            <td colspan="2" style="text-align: center; padding: 10px 0;">
                <div style="display: flex; justify-content: center; align-items: center; width: 100%;">
                    <img src="https://crm.fuelifeexplorers.com/crmImages/logo/1680004129Fuelife-Explorers.png"
                        alt="Fuelife Explorers Logo" style="width: 150px;">
                </div>
            </td>
        </tr>
        <!-- Message Section -->
        <tr>
            <td colspan="2" style="padding: 10px;">
                <div style="font-size: 12px;line-height:26px; font-weight: 400;">We are most delighted to offer
                    you a quote. Please find the same and advise us for further on the booking process.</div>
            </td>
        </tr>
        <!-- Contact Information -->
        <tr>
            <td colspan="2" style="padding: 6px;font-size: 20px;">
                <span>Fuelife Explorers Pvt Ltd</span>
            </td>
        </tr>
        <tr>
            <td colspan="2" style="padding:12px;">
                <table style="margin: 0 auto; text-align: center; width: auto;">
                    <tr>
                        <!-- WhatsApp -->
                        <td style="padding: 0 15px;">
                            <a href="tel:+918147771333" style="text-decoration: none; color: #336666;">
                                <img src="images/whatsup.svg" alt="WhatsApp"
                                    style="width: 20px; vertical-align: middle;">
                                Phone +91 8147771333
                            </a>
                        </td>
                        <!-- Phone -->
                        <td style="padding: 0 15px;">
                            <a href="tel:+918147771333" style="text-decoration: none; color: #336666;">
                                <img src="images/phone.svg" alt="Phone" style="width: 20px; vertical-align: middle;">
                                Phone +91 8147771333
                            </a>
                        </td>
                        <!-- Email -->
                        <td style="padding: 0 15px;">
                            <a href="mailto:enquiry@fuelifeexplorers.com"
                                style="text-decoration: none; color: #336666;">
                                <img src="images/mail.svg" alt="Email" style="width: 20px; vertical-align: middle;">
                                Email enquiry@fuelifeexplorers.com
                            </a>
                        </td>
                        <!-- Website -->
                        <td style="padding: 0 15px;">
                            <a href="https://www.fuelifeexplorers.com" style="text-decoration: none; color: #336666;">
                                <img src="images/web.svg" alt="Website" style="width: 20px; vertical-align: middle;">
                                Website www.fuelifeexplorers.com
                            </a>
                        </td>
                    </tr>
                </table>
            </td>

        </tr>
    </table>
</footer>

  `;

  const container = document.createElement("div");
  container.style.position = "absolute";
  container.style.left = "-9999px"; // Position the container off-screen
  container.style.width = "800px";
  container.style.background = "#fff";
  container.innerHTML = content;
  document.body.appendChild(container);

  try {
    // Generate canvas from the container
    const canvas = await html2canvas(container, {
      scale: 2, // Higher scale for better quality
      useCORS: true, // Use cross-origin resources if needed
      logging: true, // For debugging
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate image height for the PDF
    const imgHeight = (canvas.height * pdfWidth) / canvas.width;
    let position = 0;

    // Adjust the page breaks to fit content properly
    while (position < imgHeight) {
      pdf.addImage(imgData, "PNG", 0, position ? -position : 0, pdfWidth, imgHeight);
      position += pdfHeight;
      if (position < imgHeight) pdf.addPage(); // Add a new page if content exceeds one page
    }

    // Generate the PDF as a Blob
    const pdfBlob = pdf.output("blob");

    // Create a URL for the Blob object
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab immediately after generation
    const newTab = window.open(pdfUrl, "_blank");
    if (!newTab) {
      // Alert the user if popups are blocked
      alert("Please allow popups for this site to view the PDF.");
    }

    // Trigger the download in the new tab
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = `package_${packageData.packagename}.pdf`;
    downloadLink.click();
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    // Clean up the container from the DOM
    document.body.removeChild(container);
  }
};

export const PackagePDFButton = ({ packageData }) => {
  return (
    <button
      className="p-2 bg-gray-200 rounded-md"
      onClick={() => GeneratePackagePDF(packageData)}
    >
      <span className="material-symbols-outlined">picture_as_pdf</span>
    </button>
  );
};

export default GeneratePackagePDF;

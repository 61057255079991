import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../customComponents/Button";
import Paper from "../../widgets/Paper";
import InputText from "../../customComponents/InputText";
import Select from "../../customComponents/Select";
import Upload from "../../customComponents/Upload";
import { post } from "../../utils/api";
import { toast, ToastContainer } from "react-toastify";
import DayPlan from "../../customComponents/DayPlan";
import TextEditor from "../../customComponents/TextEditor";
import DatePicker from "../../customComponents/DatePicker";
import SignatureCanvas from "react-signature-canvas";
import React, { useRef } from "react";
import { MultiNewSelect } from "../../customComponents/NewMultiSelect";
import PackageUpload from "../../customComponents/PackageUpload";

const AddItinerary = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [days, setDays] = useState([]);
  const [isFirstDayAdded, setIsFirstDayAdded] = useState(false);
  const [selectedValue, setSelectedValue] = useState("single");
  const [formData, setFormData] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSeasonalFields, setShowSeasonalFields] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedItineraryDate, setItineraryDate] = useState(new Date());
  const [selectedRepresentDate, setRepresentDate] = useState(new Date());

  const [selectedPackageType, setSelectedPackageType] = useState(null);
  const sigCanvas = useRef(null);

  const [packageOptions, setPackagOptions] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [dropdownDataLoaded, setDropdownDataLoaded] = useState(false);
  const [enquiryOptions, setEnquiryOptions] = useState([]);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);

  const [packageTypeOptions, setPackageTypeOptions] = useState([]);
  const [hotelTypeOptions, setHotelTypeOptions] = useState([]);
  const [onwardTransportOptions, setOnwardTransportOptions] = useState([]);
  const [returnTransportOptions, setReturnTransportOptions] = useState([]);
  const [transferOptions, setTransferOptions] = useState([]);
  const [addonOptions, setAddonOptions] = useState([]);

  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedOnwardTransport, setSelectedOnwardTransport] = useState(null);
  const [selectedReturnTransport, setSelectedReturnTransport] = useState(null);
  const [selectedTransfers, setSelectedTransfers] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [extraBFCount, setExtraBFCount] = useState("");
  const [extraLunchCount, setExtraLunchCount] = useState("");
  const [extraDinnerCount, setExtraDinnerCount] = useState("");
  const [addOnsAmount, setAddOnsAmount] = useState("");
  const [deviationAmount, setDeviationAmount] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [numberOfPax, setNumberOfPax] = useState("");
  const [showExtraBF, setShowExtraBF] = useState(false);
  const [showExtraLunch, setShowExtraLunch] = useState(false);
  const [showExtraDinner, setShowExtraDinner] = useState(false);
  const [isRender, setRender] = useState(false);
  const [isDayOuting, setIsDayOuting] = useState(false);

  const [editorContents, setEditorContents] = useState({
    transportation_details: "",
    hotel_details: "",
    meal_plan: "",
    inclusions: "",
    exclusions: "",
    terms_conditions: "",
    learning_outcomes: "",
  });

  const [formValues, setFormValues] = useState({
    packagetypeid: null,
    packagename: "",
    city: "",
    destination: "",
    noofdays: "",
    noofnights: "",
    hoteltypeid: null,
    onwardtrnid: null,
    returnid: null,
    transferid: null,
    addonid: null,
    extra_bfcount: 0,
    extra_lunchcount: 0,
    extra_dinnercount: 0,
    noofpax: 0,
    foc: "",
    addon_amount: 0,
    deviation_amount: 0,
    price_perhead: 0,
    daywise_plan: [],
    package_image: null,
    currency: "INR",
    total_amount: 0,
  });

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        await Promise.all([
          fetchPackages(),
          fetchSchools(),
          fetchEnquirys(),

          fetchPackageTypes(),
          fetchHotelTypes(),
          fetchOnwardTransport(),
          fetchAddons(),
        ]);
        setDropdownDataLoaded(true); // Mark options as loaded
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchAllOptions();
  }, []);

  //   useEffect(() => {
  //     if (isEdit && dropdownDataLoaded) {
  //       console.log(packageOptions);
  //       fetchPackagesInfo(itineraryData?.packagedetailsid);
  //     }
  //   }, [dropdownDataLoaded]);

  useEffect(() => {
    if (isEdit && dropdownDataLoaded) {
      fetchPackagesInfo(itineraryData?.packagedetailsid);

      // Add signature prepopulation
      if (itineraryData?.itinerary_sign && sigCanvas.current) {
        const img = new Image();
        img.onload = () => {
          const ctx = sigCanvas.current.getCanvas().getContext("2d");
          ctx.drawImage(img, 0, 0);
        };
        img.src = itineraryData.itinerary_sign;
      }
    }
  }, [dropdownDataLoaded]);

  const fetchPackages = async () => {
    try {
      const payload = { type: "packages" };
      const result = await post("/getOptionList", payload);
      setPackagOptions(result.data);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const fetchSchools = async () => {
    try {
      const payload = { type: "institution" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((item) => ({
        value: item.id,
        label: item.label,
        id: item.id,
      }));

      setSchoolOptions(options);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const fetchEnquirys = async () => {
    try {
      const payload = { type: "enquirys" };
      const result = await post("/getOptionList", payload);
      setEnquiryOptions(result.data);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleItineraryDateChange = (date) => {
    const now = new Date();
    const exactDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    );
    setItineraryDate(exactDate);
  };

  const handleRepresentDateChange = (date) => {
    setRepresentDate(date);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { itineraryData } = location.state || {};
  const isEdit = location.state?.isEdit;

  const handleAddDay = useCallback(() => {
    setIsFirstDayAdded(true);
    setDays((prevDays) => {
      const newDayNumber = prevDays.length + 1;
      return [
        ...prevDays,
        { dayNumber: newDayNumber, timeSlots: [], planName: "" },
      ];
    });
  }, []);

  const handleNavigateBack = () => {
    navigate("/all-itinerary");
  };

  const handleUploadSelect = (file) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        logo: reader.result,
      }));
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64:", error);
    };
  };

  const validateFile = (file) => {
    if (!file.type.startsWith("image/")) {
      return "Only image files are allowed.";
    }
    if (file.size > 5 * 1024 * 1024) {
      return "File size should not exceed 5MB.";
    }
    return null;
  };
  const options = [
    { value: "10:1", label: "10:1" },
    { value: "20:1", label: "20:1" },
    { value: "30:1", label: "30:1" },
    { value: "40:1", label: "40:1" },
  ];

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const handlePackageTypeChange = (selectedOption) => {
    setSelectedPackageType(selectedOption);
    const isDayOutingSelected = selectedOption.label === "Day Outing";
    setIsDayOuting(isDayOutingSelected);

    if (isDayOutingSelected) {
      setSelectedHotel(null);
      setSelectedHotelMealPlan(null);
    }
  };

  // Clear the signature canvas when the "Clear" button is clicked
  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };
  const validateForm = () => {
    const { itineraryName, selectedPackage, selectedDate, selectedEnquiry } =
      formData;

    // Check for missing fields and show specific error messages
    if (!itineraryName || !itineraryName.trim()) {
      toast.error("Itinerary Name is required.");
      return false;
    }
    if (!selectedPackage) {
      toast.error("Package is required.");
      return false;
    }
    if (!selectedDate) {
      toast.error("Date of Travel is required.");
      return false;
    }
    if (!selectedEnquiry) {
      toast.error("Enquiry is required.");
      return false;
    }

    return true;
  };
  const validateFields = () => {
    if (!formData.itineraryName) {
      toast.error("Itinerary name is required");
      return false;
    }
    if (!selectedPackage?.id) {
      toast.error("Please select a package");
      return false;
    }

    if (!selectedItineraryDate) {
      toast.error("Date of travel is required");
      return false;
    }

    if (!selectedEnquiry?.id) {
      toast.error("Please select an enquiry");
      return false;
    }

    if (!selectedSchool?.id) {
      toast.error("Please select a school");
      return false;
    }
    // Only validate representative fields if checkbox is checked
    if (isDeclarationChecked) {
      if (!formValues.representative?.trim()) {
        toast.error("Representative name is required");
        return false;
      }

      if (!formValues.school?.trim()) {
        toast.error("School name is required");
        return false;
      }

      if (!selectedRepresentDate) {
        toast.error("Representative date is required");
        return false;
      }
    }
    if (!selectedPackageType?.id) {
      toast.error("Package type is required");
      return false;
    }

    if (!formValues.packagename?.trim()) {
      toast.error("Package name is required");
      return false;
    }

    if (!formValues.city?.trim()) {
      toast.error("Source city is required");
      return false;
    }

    if (!formValues.destination?.trim()) {
      toast.error("Destination is required");
      return false;
    }

    if (!formValues.noofdays || formValues.noofdays <= 0) {
      toast.error("Number of days must be greater than 0");
      return false;
    }

    if (!formValues.noofnights || formValues.noofnights <= 0) {
      toast.error("Number of nights must be greater than 0");
      return false;
    }

    if (!selectedHotel?.id) {
      toast.error("Hotel type is required");
      return false;
    }

    if (!selectedOnwardTransport?.id) {
      toast.error("Onward transport is required");
      return false;
    }

    if (!selectedReturnTransport?.id) {
      toast.error("Return transport is required");
      return false;
    }

    if (!numberOfPax || numberOfPax <= 0) {
      toast.error("Number of pax must be greater than 0");
      return false;
    }

    if (!days.length) {
      toast.error("At least one day plan is required");
      return false;
    }

    for (const day of days) {
      if (!day.planName?.trim()) {
        toast.error(`Plan name for Day ${day.dayNumber} is required`);
        return false;
      }
      if (!day.timeSlots?.length) {
        toast.error(`Time slots for Day ${day.dayNumber} are required`);
        return false;
      }
    }

    // if (!formValues.representative?.trim()) {
    //   toast.error("Representative name is required");
    //   return false;
    // }

    // if (!formValues.school?.trim()) {
    //   toast.error("School name is required");
    //   return false;
    // }

    // if (!selectedRepresentDate) {
    //   toast.error("Representative date is required");
    //   return false;
    // }

    return true;
  };

  const handleSavePackage = async () => {
    if (!validateFields()) {
      return;
    }
    try {
      const formattedDays = days.map((day) => ({
        dayNumber: day.dayNumber,
        timeSlots: day.timeSlots || [],
        planName: day.planName,
        daywiseImage: day.daywiseImage,
      }));
      const signatureData = getSignatureData();

      const addonIds = Array.isArray(selectedAddons)
        ? `{${selectedAddons.map((addon) => addon.id).join(",")}}`
        : selectedAddons?.id
        ? `{${selectedAddons.id}}`
        : null;

      const basePayload = {
        packagetypeid: selectedPackageType?.id,
        packagename: formValues.packagename,
        cityid: formValues.city,
        destination: formValues.destination,
        noofdays: Number(formValues.noofdays),
        noofnights: Number(formValues.noofnights),
        hoteltypeid: selectedHotel?.id,
        onwardtrnid: selectedOnwardTransport?.id,
        returnid: selectedReturnTransport?.id,
        transferid: selectedTransfers?.id,
        addonid: addonIds,
        extra_bfcount: Number(extraBFCount),
        extra_lunchcount: Number(extraLunchCount),
        extra_dinnercount: Number(extraDinnerCount),
        noofpax: Number(numberOfPax),
        foc: selectedOption?.value,
        addon_amount: Number(addOnsAmount),
        deviation_amount: Number(deviationAmount),
        price_perhead: Number(totalPrice),
        daywise_plan: formattedDays,
        package_image: formData?.logo ? formData?.logo : "",
        currency: "INR",
        total_amount: Number(totalPrice * numberOfPax),
        type: location.state?.isEdit ? "update" : "insert",
        textEditorData: editorContents,
        city: formValues.city,
        dateoftravel: selectedDate,
        // hotel_mealplan: selectedHotelMealPlan?.value || null,
        itinerary_sign: signatureData,
        itineraryname: formData.itineraryName,
        itinerary_travel: selectedItineraryDate,
        enquiryid: selectedEnquiry?.id,
        representative_name: formValues.representative,
        schoolname: selectedSchool?.label,
        representative_date: selectedRepresentDate,
        packagedetailsid: selectedPackage?.id,
        institutionid: selectedSchool?.id,
        isPackageType: "update",
        isitinerary: false,
        user: user?.username || "N/A",
      };

      const payload = location.state?.isEdit
        ? {
            ...basePayload,
            itinerarydetailsid:
              location.state?.itineraryData?.itinerarydetailsid,
          }
        : basePayload;

      const response = await post("/addItinerary", payload);

      if (response.status) {
        toast.success("Itinerary added successfully!");
        navigate("/all-itinerary");
      }
    } catch (error) {
      toast.error("Failed to save package: " + error.message);
    }
  };

  const handleItineraryNameChange = (e) => {
    setFormData({
      ...formData,
      itineraryName: e.target.value,
    });
  };
  const handlePackageNameChange = (e) => {
    setFormData({
      ...formData,
      itineraryName: e.target.value,
    });
  };

  const handleFormSelection = (e, type) => {
    if (type == "packages") {
      const selected = packageOptions.find((item) => item.id === e.value);
      setSelectedPackage(selected);
      fetchPackagesInfo(e.value);
    } else if (type == "schools") {
      const selected = schoolOptions.find((item) => item.id === e.value);
      setSelectedSchool(selected);
    } else {
      const selected = enquiryOptions.find((item) => item.id === e.value);
      setSelectedEnquiry(selected);
    }
  };

  const fetchPackagesInfo = async (id) => {
    try {
      const payload = { packagedetailsid: id };
      const result = await post("/getAllPackages", payload);

      prePopulateValue(result.data?.[0]);
    } catch (error) {
      console.error("Error fetching hotel types:", error);
    }
  };

  const prePopulateValue = async (data) => {
    try {
      let packageData = data ? data : "";
      setShowSeasonalFields(packageData?.seasonal || false);
      setSelectedPackageType({
        label: packageData.packagetype,
        value: packageData.packagetype,
        id: packageData.packagetypeid,
      });
      setFormValues({
        packagename: packageData.packagename,
        city: packageData.city,
        destination: packageData.destination,
        noofdays: packageData.noofdays,
        noofnights: packageData.noofnights,
        representative: itineraryData?.representative_name,
        school: itineraryData?.schoolname,
      });
      const hotelType = hotelTypeOptions.find(
        (hotel) => hotel?.id === parseInt(packageData?.hoteltypeid)
      );
      setIsDeclarationChecked(!!itineraryData?.representative_name);

      const onWardTrn = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.onwardtrnid)
      );
      const returnWardTrn = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.returnid)
      );
      const transfers = onwardTransportOptions.find(
        (list) => list?.id === parseInt(packageData?.transferid)
      );
      // const addons = addonOptions.find(
      //   (list) => list?.id === parseInt(packageData?.addonid)
      // );

      const addons = addonOptions.filter((list) =>
        packageData?.addonid?.includes(list?.id?.toString())
      );

      const foc = options.find((list) => list?.value === packageData?.foc);

      setSelectedOnwardTransport(onWardTrn);
      setSelectedReturnTransport(returnWardTrn);
      setSelectedTransfers(transfers);
      setSelectedHotel(hotelType);
      setSelectedAddons(addons);
      setShowExtraBF(addons?.some((addon) => addon.label === "Extra BF"));
      setShowExtraLunch(addons?.some((addon) => addon.label === "Extra Lunch"));
      setShowExtraDinner(
        addons?.some((addon) => addon.label === "Extra Dinner")
      );
      setExtraBFCount(Number(packageData?.extra_bfcount));
      setExtraLunchCount(Number(packageData?.extra_lunchcount));
      setExtraDinnerCount(Number(packageData?.extra_dinnercount));
      setNumberOfPax(Number(packageData?.noofpax));
      setSelectedOption(foc);
      setAddOnsAmount(Number(packageData?.addon_amount));
      setDeviationAmount(Number(packageData?.deviation_amount));
      setEditorContents({
        transportation_details:
          packageData?.texteditordata?.transportation_details,
        hotel_details: packageData?.texteditordata?.hotel_details,
        meal_plan: packageData?.texteditordata?.meal_plan,
        inclusions: packageData?.texteditordata?.inclusions,
        exclusions: packageData?.texteditordata?.exclusions,
        terms_conditions: packageData?.texteditordata?.terms_conditions,
        learning_outcomes: packageData?.texteditordata?.learning_outcomes,
      });
      setSelectedDate(new Date(packageData?.dateoftravel));
      let isSeason = packageData?.dateoftravel ? true : false;
      setShowSeasonalFields(isSeason);
      setFormData({
        logo: packageData?.package_image ? packageData?.package_image : null,
      });

      setIsFirstDayAdded(true);
      setDays(packageData?.daywise_plan || []);
      //  setFormData(packageData?.package_image);
      // setDays(packageData?.daywise_plan)
      if (data?.signature && sigCanvas.current) {
        const img = new Image();
        img.onload = () => {
          const ctx = sigCanvas.current.getCanvas().getContext("2d");
          ctx.drawImage(img, 0, 0);
        };
        img.src = data.signature;
      }

      //iteneray info prepopulate
      if (isEdit) {
        setFormData({ itineraryName: itineraryData?.itineraryname });
        setItineraryDate(new Date(itineraryData?.itinerary_travel));
        setRepresentDate(
          itineraryData?.representative_date
            ? new Date(itineraryData?.representative_date)
            : new Date()
        );
        const selectedPck = packageOptions.find(
          (item) => item.id === parseInt(itineraryData?.packagedetailsid)
        );
        const selectedScl = schoolOptions.find(
          (item) => item.id === parseInt(itineraryData?.institutionid)
        );
        const selectedEqy = enquiryOptions.find(
          (item) => item.id === parseInt(itineraryData?.enquiryid)
        );
        setSelectedEnquiry(selectedEqy);
        setSelectedPackage(selectedPck);
        setSelectedSchool(selectedScl);
      }

      // setRender(!isRender)
    } catch (error) {}
  };
  const fetchPackageTypes = async () => {
    try {
      const payload = { type: "packagetype" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((item) => ({
        value: item.value,
        label: item.label,
        id: item.value,
      }));
      setPackageTypeOptions(options);
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const fetchHotelTypes = async () => {
    try {
      const payload = { type: "hotelname_hoteltype" };
      const result = await post("/getOptionList", payload);
      setHotelTypeOptions(result.data);
    } catch (error) {
      console.error("Error fetching hotel types:", error);
    }
  };

  const fetchOnwardTransport = async () => {
    try {
      const payload = { type: "vehicaltype_vehicalname" };
      const result = await post("/getOptionList", payload);
      setOnwardTransportOptions(result.data);
      setReturnTransportOptions(result.data);
      setTransferOptions(result.data);
    } catch (error) {
      console.error("Error fetching onward transport:", error);
    }
  };

  const fetchAddons = async () => {
    try {
      const payload = { type: "addons_price" };
      const result = await post("/getOptionList", payload);
      setAddonOptions(result.data);
    } catch (error) {
      console.error("Error fetching addons:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleHotelChange = (option) => {
    const selected = hotelTypeOptions.find(
      (hotel) => hotel.id === option.value
    );
    setSelectedHotel(selected);
  };

  const filterTransportOptions = (options, type) => {
    if (!options) return [];

    switch (type) {
      case "transfers":
        // Show only van/traveller options for transfers
        return options.filter(
          (option) =>
            !option.label.toLowerCase().includes("flight") &&
            !option.label.toLowerCase().includes("train") &&
            !option.label.toLowerCase().includes("bus")
        );

      case "onward":
      case "return":
        // Show only bus, train and flight options
        return options.filter(
          (option) =>
            option.label.toLowerCase().includes("flight") ||
            option.label.toLowerCase().includes("train") ||
            option.label.toLowerCase().includes("bus")
        );

      default:
        return options;
    }
  };

  const handleOnwardTransportChange = (option) => {
    const selected = onwardTransportOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedOnwardTransport(selected);
  };

  const handleReturnTransportChange = (option) => {
    const selected = returnTransportOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedReturnTransport(selected);
  };

  const handleTransfersChange = (option) => {
    const selected = transferOptions.find(
      (transport) => transport.id === option.value
    );
    setSelectedTransfers(selected);
  };

  const handleAddonsChange = (selectedOptions) => {
    setSelectedAddons(selectedOptions);
    setShowExtraBF(
      selectedOptions?.some((option) => option.label === "Extra BF")
    );
    setShowExtraLunch(
      selectedOptions?.some((option) => option.label === "Extra Lunch")
    );
    setShowExtraDinner(
      selectedOptions?.some((option) => option.label === "Extra Dinner")
    );
  };

  const handleDayPlanChange = useCallback((dayNumber, planName, planData) => {
    setDays((prevDays) => {
      const updatedDays = [...prevDays];
      const dayIndex = updatedDays.findIndex(
        (day) => day.dayNumber === dayNumber
      );

      if (dayIndex !== -1) {
        updatedDays[dayIndex] = {
          dayNumber,
          timeSlots: planData.timeSlots,
          planName: planData.planName,
          daywiseImage: planData?.formData?.logo,
        };
      }
      return updatedDays;
    });
  }, []);

  const handleDeleteDay = (dayNumber) => {
    setDays((prevDays) =>
      prevDays.filter((day) => day.dayNumber !== dayNumber)
    );
  };

  const handleEditorChange = (content, field) => {
    setEditorContents((prev) => ({
      ...prev,
      [field]: content,
    }));
  };
  const calculateTotalPrice = () => {
    if (!numberOfPax || numberOfPax <= 0) return 0;

    const isInSeason = (startDate, endDate) => {
      if (!startDate || !endDate || !selectedDate) return false;
      const currentDate = new Date(selectedDate);
      const seasonStart = new Date(startDate);
      const seasonEnd = new Date(endDate);
      return currentDate >= seasonStart && currentDate <= seasonEnd;
    };

    const getPrice = (item) => {
      if (!showSeasonalFields || !item) return Number(item?.pph || 0);
      if (isInSeason(item?.seasonstart, item?.seasonend) && item?.sph) {
        return Number(item?.sph);
      }
      return Number(item?.pph || 0);
    };

    let totalPrice =
      Number(getPrice(selectedHotel)) +
      Number(getPrice(selectedOnwardTransport)) +
      Number(getPrice(selectedReturnTransport)) +
      Number(getPrice(selectedTransfers));

    // Calculate addons total
    const addonsTotal = Array.isArray(selectedAddons)
      ? selectedAddons.reduce((sum, addon) => {
          if (
            !["Extra BF", "Extra Lunch", "Extra Dinner"].includes(addon.label)
          ) {
            return sum + Number(getPrice(addon));
          }
          return sum;
        }, 0)
      : 0;

    // Calculate extras
    const extraBFTotal = showExtraBF
      ? Number(extraBFCount) *
        Number(selectedAddons.find((a) => a.label === "Extra BF")?.pph || 0)
      : 0;

    const extraLunchTotal = showExtraLunch
      ? Number(extraLunchCount) *
        Number(selectedAddons.find((a) => a.label === "Extra Lunch")?.pph || 0)
      : 0;

    const extraDinnerTotal = showExtraDinner
      ? Number(extraDinnerCount) *
        Number(selectedAddons.find((a) => a.label === "Extra Dinner")?.pph || 0)
      : 0;

    totalPrice +=
      addonsTotal + extraBFTotal + extraLunchTotal + extraDinnerTotal;

    // Apply FOC ratio
    if (selectedOption) {
      const [ratio] = selectedOption.value.split(":").map(Number);
      totalPrice = (totalPrice * (ratio + 1)) / ratio;
    }

    totalPrice += Number(addOnsAmount) || 0;
    totalPrice += Number(deviationAmount) || 0;

    return Math.round(totalPrice);
  };

  useEffect(() => {
    const newTotal = calculateTotalPrice();
    setTotalPrice(newTotal);
  }, [
    selectedHotel,
    selectedOnwardTransport,
    selectedReturnTransport,
    selectedTransfers,
    selectedAddons,
    extraBFCount,
    extraLunchCount,
    extraDinnerCount,
    numberOfPax,
    selectedOption,
    addOnsAmount,
    deviationAmount,
    days.length,
    showSeasonalFields,
    selectedDate,
  ]);

  const getSignatureData = () => {
    if (sigCanvas.current) {
      return sigCanvas.current.toDataURL();
    }
    return null;
  };

  return (
    <div className="bg">
      <div className="block md:flex lg:flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            Add Itinerary
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Itinerary</span> /
            <span className="font-regular">Add Itinerary</span>
          </p>
        </div>
        <div className="flex flex-wrap gap-4 mt-4 md:mt-0 justify-end">
          <Button
            label={
              <span className="inline-flex items-center gap-1">
                Export PDF
                <span className="material-symbols-outlined">
                  {" "}
                  picture_as_pdf{" "}
                </span>
              </span>
            }
            className="w-full md:w-auto"
          />
          {selectedValue === "single" &&
            user?.role !== "Sales Manager" &&
            user?.role !== "Sales Executive" && (
              <Button
                type="primary"
                label={isEdit ? "Update" : "Save"}
                onClick={handleSavePackage}
                className="w-full md:w-auto"
              />
            )}
          <Button
            type="secondary"
            label="Cancel"
            onClick={handleNavigateBack}
            className="w-full md:w-auto"
          />
        </div>
      </div>
      <Paper className="my-8 pt-6 pb-6">
        <h1 className="text-[20px] font-bold mb-4">Itinerary Name</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <label className="text-[16px] font-semibold mb-2">
              Itinerary Name <span className="text-red-500">*</span>
            </label>
            <InputText
              placeholder="Enter Itinerary Name..."
              value={formData.itineraryName}
              onChange={handleItineraryNameChange}
            />
          </div>
          <div>
            <label className="text-[16px] font-semibold">
              {" "}
              Select Package <span className="text-red-500">*</span>
            </label>
            <Select
              placeholder="Package Name..."
              value={packageOptions.find(
                (option) => option.value === selectedPackage?.id
              )}
              options={packageOptions}
              onChange={(e) => handleFormSelection(e, "packages")}
              disabled={isEdit}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  cursor: isEdit ? "not-allowed" : "pointer",
                  backgroundColor: isEdit ? "#f3f4f6" : "white",
                }),
                option: (baseStyles) => ({
                  ...baseStyles,
                  cursor: isEdit ? "not-allowed" : "pointer",
                }),
              }}
            />
          </div>
          <div>
            <label className="text-[16px] font-semibold mb-2">
              Date of Travel <span className="text-red-500">*</span>
            </label>
            <DatePicker
              selectedDate={selectedItineraryDate}
              value={formData.DateChange}
              onDateChange={handleItineraryDateChange}
              placeholder="Date"
            />
          </div>
          <div>
            <label className="text-[16px] font-semibold mb-2">
              Enquiry <span className="text-red-500">*</span>
            </label>
            <Select
              placeholder="Enquiry Name..."
              value={enquiryOptions.find(
                (option) => option.value === selectedEnquiry?.id
              )}
              options={enquiryOptions}
              onChange={(e) => handleFormSelection(e, "enquirys")}
            />
          </div>
          <div>
            <label className="text-[16px] font-semibold mb-2">
              School Name <span className="text-red-500">*</span>
            </label>
            <Select
              placeholder="School Name..."
              value={schoolOptions.find(
                (option) => option.value === selectedSchool?.id
              )}
              options={schoolOptions}
              onChange={(e) => handleFormSelection(e, "schools")}
            />
          </div>
        </div>
      </Paper>

      <Paper className="my-8 pt-6 pb-6">
        <Paper className="my-8 border">
          <h1 className="text-[20px] font-bold mb-4">Package Details</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Package Type <span className="text-red-500">*</span>
                </label>
                <Select
                  options={packageTypeOptions}
                  value={selectedPackageType}
                  onChange={handlePackageTypeChange}
                  placeholder="Select  Type..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  Package Name <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Package Name..."
                  name="packagename"
                  value={formValues.packagename}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Source <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Enter Source..."
                  name="city"
                  value={formValues.city}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Destination <span className="text-red-500">*</span>
                </label>
                <InputText
                  name="destination"
                  value={formValues.destination}
                  onChange={handleInputChange}
                  placeholder="Enter Destination..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  No. of Days <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Enter Days Count..."
                  value={formValues.noofdays}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      noofdays: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold">
                  No. of Nights <span className="text-red-500">*</span>
                </label>
                <InputText
                  value={formValues.noofnights}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      noofnights: e.target.value,
                    }))
                  }
                  placeholder="Enter Nights Count..."
                />
              </div>
            </div>
            <div className="mt-3">
              <Upload
                onFileSelect={handleUploadSelect}
                labelText="Upload Image"
                initialPreviewUrl={formData?.logo ? formData?.logo : null}
                fileValidation={validateFile}
                handleRemove={() => {
                  setFormData(null);
                }}
              />
            </div>
          </div>
        </Paper>
        <Paper className="my-8 border p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Seasonal Checkbox and Label */}
            <div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 items-center gap-4">
              {/* Seasonal Checkbox */}
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="seasonalPrice"
                  checked={showSeasonalFields}
                  onChange={(e) => setShowSeasonalFields(e.target.checked)}
                  className="mr-2"
                />
                <label
                  htmlFor="seasonalPrice"
                  className="text-[14px] font-semibold"
                >
                  Seasonal <span className="text-red-500">*</span>
                </label>
              </div>

              {/* Date Of Travel Field */}
              {showSeasonalFields && (
                <div>
                  <label className="text-[14px] font-semibold mb-1 block">
                    Date Of Travel <span className="text-red-500">*</span>
                  </label>
                  <DatePicker
                    selectedDate={selectedDate}
                    onDateChange={(date) => handleDateChange(date)}
                    placeholderText="Start Date"
                    className="w-full mt-1"
                  />
                </div>
              )}
            </div>

            {/* Main Form Fields */}
            <div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {!isDayOuting && (
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Hotel Type <span className="text-red-500">*</span>
                  </label>
                  <Select
                    options={hotelTypeOptions}
                    onChange={handleHotelChange}
                    value={hotelTypeOptions.find(
                      (option) => option.value === selectedHotel?.id
                    )}
                    placeholder="Select Hotel Type..."
                  />
                </div>
              )}
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Onward Transportation <span className="text-red-500">*</span>
                </label>
                <Select
                  options={filterTransportOptions(
                    onwardTransportOptions,
                    "onward"
                  )}
                  value={onwardTransportOptions.find(
                    (option) => option.value === selectedOnwardTransport?.id
                  )}
                  onChange={handleOnwardTransportChange}
                  placeholder="Select Onward Transportation..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Return Transportation <span className="text-red-500">*</span>
                </label>
                <Select
                  options={filterTransportOptions(
                    returnTransportOptions,
                    "return"
                  )}
                  value={returnTransportOptions.find(
                    (option) => option.value === selectedReturnTransport?.id
                  )}
                  onChange={handleReturnTransportChange}
                  placeholder="Select Return Transportation..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Transfers
                </label>
                <Select
                  options={filterTransportOptions(transferOptions, "transfers")}
                  value={transferOptions.find(
                    (option) => option.value === selectedTransfers?.id
                  )}
                  onChange={handleTransfersChange}
                  placeholder="Select Transfers..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Addons <span className="text-red-500">*</span>
                </label>
                <MultiNewSelect
                  // className="form-control"
                  placeHolder="Select Addons..."
                  options={addonOptions}
                  // name="fileTypes"
                  value={selectedAddons}
                  onChange={(e) => handleAddonsChange(e, "addons")}
                  isMulti
                  id="fileTypes"
                />
              </div>
              {showExtraBF && (
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Extra BF Count <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    type="number"
                    min="1"
                    placeholder="Enter Count..."
                    value={extraBFCount || 1}
                    onChange={(e) =>
                      setExtraBFCount(Math.max(1, Number(e.target.value)))
                    }
                  />
                </div>
              )}
              {showExtraLunch && (
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Extra Lunch Count <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    type="number"
                    min="1"
                    placeholder="Enter Count..."
                    value={extraLunchCount || 1}
                    onChange={(e) =>
                      setExtraLunchCount(Math.max(1, Number(e.target.value)))
                    }
                  />
                </div>
              )}
              {showExtraDinner && (
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Extra Dinner Count <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    type="number"
                    min="1"
                    placeholder="Enter Count..."
                    value={extraDinnerCount || 1}
                    onChange={(e) =>
                      setExtraDinnerCount(Math.max(1, Number(e.target.value)))
                    }
                  />
                </div>
              )}
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  No. of Pax <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Enter number..."
                  value={numberOfPax}
                  onChange={(e) => setNumberOfPax(Number(e.target.value))}
                />{" "}
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">FOC</label>
                <Select
                  options={options}
                  value={selectedOption}
                  onChange={handleChange}
                  placeholder="Select FOC..."
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Add Ons Calculated Amount{" "}
                  <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Enter Amount..."
                  value={addOnsAmount}
                  onChange={(e) => setAddOnsAmount(Number(e.target.value))}
                />
              </div>
              <div>
                <label className="text-[16px] font-semibold mb-2">
                  Deviation Allowed Amount{" "}
                  <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Enter Amount..."
                  value={deviationAmount}
                  onChange={(e) => setDeviationAmount(Number(e.target.value))}
                />{" "}
              </div>
            </div>

            {/* Price Box */}
            <div className="bg-[#CDD8CB] p-6 rounded-lg max-w-sm mx-auto text-left">
              <span className="material-symbols-outlined text-gray-700 text-3xl mb-2">
                payments
              </span>
              <div className="text-gray-700 text-sm mb-2">
                Price/ head (INR)
              </div>
              <div className="text-4xl font-bold text-black mb-4">
                ₹ {totalPrice.toLocaleString("en-IN")}
              </div>
              <div className="text-xs text-gray-600">
                **Above number is based on the head count and other add-ons
                entered, this may change based on the count change.
              </div>
            </div>
          </div>
        </Paper>

        <div className="bg-white rounded-xl border-gray-300 p-4 my-8 border">
          <h1 className="text-[20px] font-bold mb-4">Day-wise Planning</h1>
          <div className="grid grid-cols-1 gap-4">
            {!isFirstDayAdded ? (
              <Paper className="border-2 border-dashed border-black p-8  flex justify-center items-center flex-col">
                <button
                  onClick={handleAddDay}
                  className=" bg-white text-black border border-black rounded-md py-2 px-12 text-lg mb-4 mt-4"
                >
                  Add Day
                </button>
                <p className="text-center text-gray-500 mt-4">
                  No day plan to display
                </p>
              </Paper>
            ) : (
              <div className="grid grid-cols-1  lg:grid-cols-2 gap-4 ">
                {days.map((day, index) => (
                  <div key={index} className="w-full">
                    <DayPlan
                      dayNumber={day.dayNumber}
                      initialTimeSlots={day.timeSlots}
                      planName01={day.planName} // Assuming planName is the same for all time slots
                      daywiseImage={day?.daywiseImage}
                      onChange={(planData) =>
                        handleDayPlanChange(
                          day.dayNumber,
                          day.planName,
                          planData
                        )
                      }
                      onDelete={() => handleDeleteDay(day.dayNumber)}
                    />
                  </div>
                ))}

                <Paper className="border-2 border-dashed border-black p-8  flex justify-center items-center flex-col">
                  <button
                    onClick={handleAddDay}
                    className=" bg-white text-black border border-black rounded-md py-2 px-12 text-lg"
                  >
                    Add Day
                  </button>
                </Paper>
              </div>
            )}
          </div>
        </div>
        <div className="container mx-auto px-4 py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Transportation Details <span className="text-red-500">*</span>
              </label>
              <TextEditor
                initialValue={editorContents.transportation_details}
                onChange={(content) =>
                  handleEditorChange(content, "transportation_details")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Hotel Details <span className="text-red-500">*</span>
              </label>
              <TextEditor
                initialValue={editorContents.hotel_details}
                onChange={(content) =>
                  handleEditorChange(content, "hotel_details")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Meal Plan <span className="text-red-500">*</span>
              </label>
              <TextEditor
                initialValue={editorContents.meal_plan}
                onChange={(content) => handleEditorChange(content, "meal_plan")}
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Inclusions <span className="text-red-500">*</span>
              </label>
              <TextEditor
                initialValue={editorContents.inclusions}
                onChange={(content) =>
                  handleEditorChange(content, "inclusions")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Exclusions <span className="text-red-500">*</span>
              </label>
              <TextEditor
                initialValue={editorContents.exclusions}
                onChange={(content) =>
                  handleEditorChange(content, "exclusions")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Terms and Conditions <span className="text-red-500">*</span>
              </label>
              <TextEditor
                initialValue={editorContents.terms_conditions}
                onChange={(content) =>
                  handleEditorChange(content, "terms_conditions")
                }
              />
            </div>
            <div>
              <label className="text-[16px] font-semibold mb-4 block">
                Learning Outcomes <span className="text-red-500">*</span>
              </label>
              <TextEditor
                initialValue={editorContents.learning_outcomes}
                onChange={(content) =>
                  handleEditorChange(content, "learning_outcomes")
                }
              />
            </div>
          </div>
        </div>
      </Paper>

      <ToastContainer />
      <Paper className="my-8 pt-6 pb-6 px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Declaration */}
          <div className="col-span-1 lg:col-span-4 mb-4">
            <label className="flex items-start gap-2">
              <input
                type="checkbox"
                className="mt-1"
                checked={isDeclarationChecked}
                onChange={(e) => setIsDeclarationChecked(e.target.checked)}
              />
              <span className="text-sm">
                I hereby declare that the above information is true and accurate
                to the best of my knowledge and belief.
              </span>
            </label>
          </div>

          {isDeclarationChecked && (
            <>
              {/* Representative Name */}
              <div>
                <label className="text-[16px] font-semibold mb-2 block">
                  Representative Name <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Enter Representative Name..."
                  name="representative"
                  value={formValues.representative}
                  onChange={handleInputChange}
                />
              </div>

              {/* School */}
              <div>
                <label className="text-[16px] font-semibold mb-2 block">
                  School <span className="text-red-500">*</span>
                </label>
                <InputText
                  placeholder="Enter School Name"
                  name="school"
                  value={formValues.school}
                  onChange={handleInputChange}
                />
              </div>

              {/* Date */}
              <div>
                <label className="text-[16px] font-semibold mb-2 block">
                  Date <span className="text-red-500">*</span>
                </label>
                <DatePicker
                  selectedDate={selectedRepresentDate}
                  onDateChange={handleRepresentDateChange}
                  placeholder="Date"
                />
              </div>

              {/* Signature */}
              <div>
                <label className="text-sm font-semibold block mb-2">
                  Signature <span className="text-red-500">*</span>
                </label>
                <div className="relative border border-gray-300 rounded-md">
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{
                      width: 300,
                      height: 120,
                      className: "rounded-md",
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={clearSignature}
                  className="text-red-500 mt-2 block text-sm font-medium hover:no-underline focus:outline-none border-none"
                  style={{ cursor: "pointer" }}
                >
                  Clear
                </button>
              </div>
            </>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default AddItinerary;

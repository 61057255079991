import React, { useEffect, useCallback, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import AdminDashboard from "./components/AdminDashboard";
import AddInstitution from "./components/AddInstitution";
import AddEnquiry from "./components/AddEnquiry";
import Institutions from "./components/Institutions";
import Users from "./components/Users";
import Profile from "./components/Profile";
import Layout from "./layout";
import AddUser from "./components/AddUser";
import InstitutionDetails from "./components/InstitutionDetails";
import ResetPassword from "./components/ResetPassword";
import PrivateRoute from "./route/PrivateRoute";
import PublicRoute from "./route/PublicRoute";
import AddPackages from "./components/AddPackages";
import AllPackages from "./components/AllPackages";
import Hotels from "./components/Hotels";
import AddHotel from "./components/AddHotel";
import Transportations from "./components/Transportations";
import AddTransportation from "./components/AddTransportation";
import Addons from "./components/Addons";
import AddAddons from "./components/AddAddons ";
import AddItinerary from "./components/Itinerary/AddItinerary";
import AllItinerary from "./components/Itinerary/AllItinerary";
import AssignedInstitution from "./components/AssignedInstitution";
import EditAssignedInstitution from "./components/EditAssignedInstitution";
import ItenaryViewLog from "./components/Itinerary/ItenaryViewLog";
import PackageViewLogs from "./components/PackageViewLogs";
import EnquiryViewLogs from "./components/EnquiryViewlogs";
import LoaderComponent from "./components/LoaderComponent";

const SESSION_TIMEOUT = 8 * 60 * 60 * 1000;

function App() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("user");
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    const logoutTimer = setTimeout(handleLogout, SESSION_TIMEOUT);

    const resetTimeout = () => {
      clearTimeout(logoutTimer);
      setTimeout(handleLogout, SESSION_TIMEOUT);
    };

    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, [handleLogout]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const handleStart = () => setIsLoading(true);
    const handleComplete = () => setIsLoading(false);

    window.addEventListener("beforeunload", handleStart);
    return () => {
      window.removeEventListener("beforeunload", handleStart);
    };
  }, []);

  const customNavigate = (to) => {
    setIsLoading(true);
    navigate(to);
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <>
      {isLoading && <LoaderComponent />}
      <Routes>
        <Route path="login" element={<PublicRoute element={Login} />} />
        <Route
          path="reset-password/:optionalParam"
          element={<PublicRoute element={ResetPassword} />}
        />
        <Route
          path="forgot-password"
          element={<PublicRoute element={ForgotPassword} />}
        />
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="login" replace />} />
          <Route
            path="institutions"
            element={<PrivateRoute element={Institutions} />}
          />
          <Route
            path="institution-details"
            element={<PrivateRoute element={InstitutionDetails} />}
          />
          <Route
            path="dashboard"
            element={<PrivateRoute element={AdminDashboard} />}
          />
          <Route
            path="add-institutions"
            element={<PrivateRoute element={AddInstitution} />}
          />
          <Route
            path="add-enquiry"
            element={<PrivateRoute element={AddEnquiry} />}
          />
          <Route
            path="/enquiry-view-logs/:enquiryid"
            element={<EnquiryViewLogs />}
          />
          <Route path="users" element={<PrivateRoute element={Users} />} />
          <Route path="profile" element={<PrivateRoute element={Profile} />} />
          <Route path="add-user" element={<PrivateRoute element={AddUser} />} />
          <Route
            path="add-packages"
            element={<PrivateRoute element={AddPackages} />}
          />
          <Route
            path="all-packages"
            element={<PrivateRoute element={AllPackages} />}
          />
          <Route
            path="/package-view-logs/:packagedetailsid"
            element={<PackageViewLogs />}
          />
          <Route path="hotels" element={<PrivateRoute element={Hotels} />} />
          <Route
            path="add-hotel"
            element={<PrivateRoute element={AddHotel} />}
          />
          <Route
            path="transportation"
            element={<PrivateRoute element={Transportations} />}
          />
          <Route
            path="add-transportation"
            element={<PrivateRoute element={AddTransportation} />}
          />
          <Route path="addons" element={<PrivateRoute element={Addons} />} />
          <Route
            path="add-addons"
            element={<PrivateRoute element={AddAddons} />}
          />
          <Route
            path="add-itinerary"
            element={<PrivateRoute element={AddItinerary} />}
          />
          <Route
            path="all-itinerary"
            element={<PrivateRoute element={AllItinerary} />}
          />
          <Route
            path="itinerary-view-logs/:itinerarydetailsid"
            element={<PrivateRoute element={ItenaryViewLog} />}
          />
          <Route
            path="assigned-institution"
            element={<PrivateRoute element={AssignedInstitution} />}
          />
          <Route
            path="edit-assigned-institution"
            element={<PrivateRoute element={EditAssignedInstitution} />}
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;

import Button from "../customComponents/Button";

const HotelModal = ({ onClose, hotelData }) => {
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString();
  };
  const handleEditClick = () => {
    navigate("/add-hotel", {
      state: {
        hotelinfo: info.row.original,
        type: "edit",
        editflag: true,
      },
    });
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-t-lg shadow-lg w-full h-[50vh] md:h-[75vh] overflow-y-auto p-6 border-b mt-auto">
        <div className="flex justify-between items-center mb-6 border-b">
          <h2 className="text-xl font-semibold">Hotel Details</h2>
          <div className="flex space-x-4 items-center">
            <Button
              type="primary"
              label="Edit Hotel"
              onClick={handleEditClick}
            />
            <a
              onClick={onClose}
              className="text-gray-500 hover:text-black cursor-pointer"
            >
              <span className="material-symbols-outlined">close</span>
            </a>
          </div>
        </div>
        <div className="mt-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 pb-6 border-b">
          <div className="mt-7">
            <div className="font-semibold">Hotel Name</div>
            <div className="mt-2">
              <div>{hotelData.hotelName}</div>
            </div>
          </div>
          <div className="mt-7">
            <div className="font-semibold">Hotel Type</div>
            <div className="mt-2">
              <div>{hotelData.roomType}</div>
            </div>
          </div>
          <div className="mt-7">
            <div className="font-semibold">City (Destination)</div>
            <div className="mt-2">
              <div>{hotelData.city}</div>
            </div>
          </div>
          <div className="mt-7">
            <div className="font-semibold">Accommodation Type</div>
            <div className="mt-2">
              <div>{hotelData.accommodation}</div>
            </div>
          </div>
          <div className="mt-7">
            <div className="font-semibold">Grade</div>
            <div className="mt-2">
              <div>A</div>
            </div>
          </div>
          <div className="mt-7">
            <div className="font-semibold">Meal Plan</div>
            <div className="mt-2">
              <div>Standard</div>
            </div>
          </div>
          
          <div className="mt-7">
            <div className="font-semibold">Price per head (INR)</div>
            <div className="mt-2">
              <div>{hotelData.price}</div>
            </div>
          </div>

          <div className="mt-7">
            <div className="font-semibold">Season</div>
            <div className="mt-2">
            {" "}
            {formatDate(hotelData.seasonstart)} -{" "}
            {formatDate(hotelData.seasonend)}
          </div>
          <div className="col-span-2">{hotelData.season_priceperhead}</div>
            
          </div>

        </div>

      </div>
    </div>
  );
};

export default HotelModal;

import React, { useEffect, useState } from "react";
import Button from "../customComponents/Button";
import Table from "../customComponents/Table";
import DatePicker from "../customComponents/DatePicker";
import FilterSelect from "../customComponents/FilterSelect";
import HotelModal from "../customComponents/HotelModal";
import { createColumnHelper } from "@tanstack/react-table";
import SearchSelect from "../customComponents/SerachSelect";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/api";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../customComponents/ConfirmationModal";

const Hotels = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [searchText, setSearchData] = useState("");
  const [hotelOptions, setHotelOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [hotel, setHotel] = useState("");
  const [city, setCity] = useState("");
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [formattedDate, setFormattedDate] =useState()


  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const filteredData = initialData.filter((item) => {
      // Convert values to lowercase for case-insensitive comparison
      const matchesSearch =
        !searchText || // Allow all if searchText is empty
        item.hotelname?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.city?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.hoteltype?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.accommodationtype
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        `${item.priceperhead}`.toLowerCase().includes(searchText.toLowerCase());

      const matchesCity =
        !city || city.value === "All City" || item.city === city.value;
      const matchesHotel =
        !hotel || hotel.value === "All Hotel" || item.hoteltype === hotel.value;
        const matchesDay =!formattedDate ||  item.alterCreatedon == formattedDate;


      // Combine filters, allowing partial matches if others are not applied
      return matchesSearch && matchesCity && matchesHotel && matchesDay;
    });

    setTableData(filteredData);
  }, [searchText, city, hotel,selectedDate]);

  const fetchData = async () => {
    try {
      const data = {};
      const result = await post("/getAllHotel", data);

      // Transform result data for table
      const temp = result.data.map((list) => ({
        hotelName: list.hotelname,
        roomType: list.hoteltype,
        city: list.city,  
        accommodation: list?.hotelwise_plan?.[0]?.accommodationType || 'NA',
        price: `₹ ${list?.hotelwise_plan?.[0]?.pricePerHead || 'NA'}`,
        alterCreatedon: new Date(list.createdon)
        .toLocaleDateString("en-GB")
        .replace(/\//g, "-"),
        ...list,
      }));

      // Extract unique city and hotel options
      const citySet = new Set(result.data.map((item) => item.city));
      const hotelSet = new Set(result.data.map((item) => item.hoteltype));

      const cityArray = [
        { label: "All City", value: "All City" },
        ...Array.from(citySet).map((city) => ({
          label: city,
          value: city,
        })),
      ];

      const hotelArray = [
        { label: "All Hotel", value: "All Hotel" },
        ...Array.from(hotelSet).map((hotel) => ({
          label: hotel,
          value: hotel,
        })),
      ];

      // Update states
      setCityOptions(cityArray);
      setHotelOptions(hotelArray);
      setTableData(temp);
      setInitialData(temp);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleClick = () => {
    navigate("/add-hotel");
  };
  const handleDateChange = (filterDate) => {
    // setSelectedDate(date);

    const date = new Date(filterDate);
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    
    const formattedDate = `${day}-${month}-${year}`;
    console.log(formattedDate);
    setFormattedDate(formattedDate)
        setSelectedDate(filterDate);

  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("slNo", {
      header: "Sl. No",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("hotelName", {
      header: "HOTEL NAME",
    }),
    columnHelper.accessor("roomType", {
      header: "ROOM TYPE",
    }),
    columnHelper.accessor("city", {
      header: "CITY",
    }),
    columnHelper.accessor("accommodation", {
      header: "ACCOMMODATION TYPE",
    }),
    columnHelper.accessor("price", {
      header: "PRICE PER HEAD",
    }),
    columnHelper.accessor("actions", {
      header: "ACTIONS",
      cell: (info) => (
        <div className="flex gap-2">
          <button
            onClick={() =>
              navigate("/add-hotel", {
                state: {
                  hotelinfo: info.row.original,
                  type: "edit",
                  editflag: true,
                },
              })
            }
            className="p-2 bg-gray-200 rounded-md"
          >
            <span className="material-symbols-outlined">edit</span>
          </button>
          <button
            className="p-2 bg-gray-200 rounded-md"
            onClick={() => handleViewClick(info.row.original)}
          >
            <span className="material-symbols-outlined">visibility</span>
          </button>
          <button
            onClick={() => handleDeleteClick(info.row.original)}
            className="p-2 bg-gray-200 text-red-500 rounded-md"
          >
            <span className="material-symbols-outlined">delete</span>
          </button>
        </div>
      ),
    }),
  ];

  const data = [
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
    {
      hotelName: "Kodai_2020",
      roomType: "3 Star",
      city: "Bengaluru",
      accommodation: "Double",
      price: "₹ 4500",
    },
  ];

  const handleViewClick = (hotel) => {
    setSelectedHotel(hotel);
    setIsModalOpen(true);
  };
  const handleDeleteClick = async (data) => {
    setDeleteModal(true);
    setDeleteItem(data);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedHotel(null);
  };

  const handleFormSelection = (e, type) => {
    console.log(e, "eee");
    if (type == "hotel") {
      if (e.label === "All City") {
        setHotel("");
      } else {
        setHotel(e);
      }
    } else {
      if (e.label === "All Hotel") {
        setCity("");
      } else {
        setCity(e);
      }
    }
  };
  const handleConfirmDelete = async () => {
    try {
      const result = await post("/deleteHotel", deleteItem);
      toast.success("Hotel deleted successfully");
      setDeleteModal(false);
      fetchData();
    } catch (error) {
      toast.error("Hotel delete failed");
      setDeleteModal(false);
    }
  };
  const handleCloseModal = async () => {
    setDeleteModal(false);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-primary-black text-[28px] font-semibold">
            Hotels
          </h2>
          <p className="text-[14px]">
            <span className="font-bold">Hotels</span> /{" "}
            <span className="font-regular">Hotel List</span>
          </p>
        </div>
        <Button
          type="primary"
          label="Add Hotel"
          onClick={() => navigate("/add-hotel")}
        />
      </div>

      {/* Filter Fields */}
      <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-1 lg:flex lg:justify-between">
        <div className="w-full md:w-5/12">
          <input
            type="text"
            className="outline-none p-2 rounded-l-lg w-full  h-[40px]"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => handleSearch(e)}
          />
        </div>

        {/* 
           const [hotelOptions,setHotelOptions] = useState([]);
            const [cityOptions,setCityOptions] = useState([]); */}
        <div className="w-full md:w-auto md:flex md:gap-4">
          <div className="sm:w-full md:w-full lg:w-52">
            <SearchSelect
              placeholder="Hotel Type"
              value={hotel}
              onChange={(e) => handleFormSelection(e, "hotel")}
              options={hotelOptions}
            />
          </div>
          <div className="sm:w-full md:w-full lg:w-52">
            <FilterSelect
              placeholder="City"
              value={city}
              options={cityOptions}
              onChange={(e) => handleFormSelection(e, "city")}
            />
          </div>
          <div className="sm:w-full md:w-full lg:w-auto">
           <DatePicker
            selectedDate={selectedDate}
            onDateChange={(date) => handleDateChange(date, "dateoftravel")}
          />
           </div>

        </div>
      </div>

      <div className="bg-white rounded-xl border-gray-300 mt-7 pb-6">
        <Table columns={columns} data={tableData} />
      </div>
      <ConfirmationModal
        isOpen={isDeleteModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title="Delete Hotel"
        message={`Are you sure you want to delete the hotel "${deleteItem?.hotelname}"?`}
      />

      {/* Hotel Details Modal */}
      {isModalOpen && (
        <HotelModal
          isOpen={isModalOpen}
          onClose={closeModal}
          hotelData={{
            ...selectedHotel,
            seasonDisplay:
              selectedHotel.seasonstart && selectedHotel.seasonend
                ? `${new Date(selectedHotel.seasonstart).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  }
                )} - ${new Date(selectedHotel.seasonend).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  }
                )}`
                : "No Season",
          }}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Hotels;

import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select";
import { useEffect, useState } from "react";
import UploadHotel from "../customComponents/UploadHotel";
import RadioButton from "../customComponents/RadioButton";
import DatePicker from "../customComponents/DatePicker";
import { post } from "../utils/api";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../widgets/Loader";
import { ToastContainer, toast } from "react-toastify";
import { MultiNewSelect } from "../customComponents/NewMultiSelect";

const AddHotel = () => {
  const location = useLocation();
  const { hotelinfo, type } = location.state || {};
  const isEdit = !!hotelinfo?.hotelid;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedValue, setSelectedValue] = useState("single");
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  const [showSeasonalFields, setShowSeasonalFields] = useState(false);
  const [hotelTypeOptions, setHotelTypeOptions] = useState([]);
  const [accommodationOptions, setAccommodationOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [mealPlanOptions, setMealPlanOptions] = useState([]);
  const [selectedHotelType, setSelectedHotelType] = useState(null);
  const [selectedAccommodation, setSelectedAccommodation] = useState(null);
  const [hotelName, setHotelName] = useState("");
  const [city, setCity] = useState("");
  const [pricePerHead, setPricePerHead] = useState("");
  const [seasonalPrice, setSeasonalPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dropdownDataLoaded, setDropdownDataLoaded] = useState(false);
  const [fields, setFields] = useState([
    {
      accommodationType: "",
      mealPlan: "",
      pricePerHead: "",
      seasonalPrice: "",
    },
  ]);

  // const handleFieldChange = (index, field, value) => {
  //   const updatedFields = [...fields];
  //   updatedFields[index][field] = value;
  //   setFields(updatedFields);
  // };

  // const addNewFieldSet = (index) => {
  //   const updatedFields = [...fields];
  //   // Add a new fieldset below the current one
  //   updatedFields.splice(index + 1, 0, {
  //     accommodationType: "",
  //     mealPlan: "",
  //     pricePerHead: "",
  //   });
  //   setFields(updatedFields);
  // };
  const addNewFieldSet = (index) => {
    setFields((prevFields) => {
      const newField = {
        accommodationType: "",
        mealPlan: "",
        pricePerHead: "",
      };

      // Create new array with empty field inserted after current index
      const updatedFields = [
        ...prevFields.slice(0, index + 1),
        newField,
        ...prevFields.slice(index + 1),
      ];

      return updatedFields;
    });
  };

  const deleteFieldSet = (index) => {
    const updatedFields = [...fields];
    if (updatedFields.length > 1) {
      updatedFields.splice(index, 1);
      setFields(updatedFields);
    }
  };

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        await Promise.all([
          fetchHotelType(),
          fetchAccommodationType(),
          fetchGrade(),
          fetchMealPlan(),
        ]);
        setDropdownDataLoaded(true); // Mark options as loaded
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchAllOptions();
  }, []);

  // useEffect(() => {
  //   fetchHotelType();
  //   fetchAccommodationType();
  //   fetchGrade();
  //   fetchMealPlan();
  // }, []);

  useEffect(() => {
    if (isEdit && dropdownDataLoaded) {
      prePopulateValue(); // Now dropdown options are ready
    }
  }, [dropdownDataLoaded]);

  const prePopulateValue = async () => {
    try {
      const userInstitutionIds = hotelinfo?.gradeid
        ? Array.isArray(hotelinfo?.gradeid)
          ? hotelinfo?.gradeid
          : hotelinfo?.gradeid.replace(/[{}]/g, "").split(",")
        : [];

      const selectedGrade = gradeOptions.filter((list) =>
        userInstitutionIds.includes(list.id.toString())
      );
      setSelectedGrade(selectedGrade);

      // setSelectedMealPlan(hotelinfo?.mealplan);
      setSelectedHotelType(hotelinfo?.hoteltype || "");
      // setSelectedAccommodation(hotelinfo?.accommodationtype);

      setSeasonalPrice(hotelinfo?.season_priceperhead);
      // setPricePerHead(hotelinfo?.priceperhead);
      setCity(hotelinfo?.city);
      setHotelName(hotelinfo?.hotelname);
      setStartDate(new Date(hotelinfo?.seasonstart));
      setEndDate(new Date(hotelinfo?.seasonend));
      setShowSeasonalFields(!!hotelinfo?.season_priceperhead);
      setFields(hotelinfo?.hotelwise_plan);

      // const addNewFieldSet = (index) => {
      //   const updatedFields = [...fields];
      //   // Add a new fieldset below the current one
      //   updatedFields.splice(index + 1, 0, {
      //     accommodationType: "",
      //     mealPlan: "",
      //     pricePerHead: "",
      //   });
      //   setFields(updatedFields);
      // };
    } catch (error) {}
  };

  const fetchHotelType = async () => {
    try {
      const payload = { type: "hoteltype" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.hoteltypeid,
        })),
      ];
      setHotelTypeOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAccommodationType = async () => {
    try {
      const payload = { type: "accommodationtype" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.accommodationtypeid,
        })),
      ];
      setAccommodationOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchGrade = async () => {
    try {
      const payload = { type: "grade" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.gradeid,
        })),
      ];
      setGradeOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchMealPlan = async () => {
    try {
      const payload = { type: "mealplan" };
      const result = await post("/getOptionList", payload);
      const options = [
        ...result.data.map((list) => ({
          label: list.label,
          value: list.label,
          id: list.mealplanid,
        })),
      ];
      setMealPlanOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleDateChange = (date, type) => {
    if (type == "startdate") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleFormSelection = (e, type) => {
    let value = e.value;
    if (type == "grade") {
      setSelectedGrade(e);
    } else if (type == "mealplan") {
      setSelectedMealPlan(value);
    } else if (type == "hoteltype") {
      setSelectedHotelType(value);
    } else if (type == "accommodationtype") {
      setSelectedAccommodation(value);
    }
  };

  const handleChange = (e, type) => {
    let value = e.target.value;
    if (type == "hotelname") {
      setHotelName(value);
    } else if (type == "city") {
      setCity(value);
    } else if (type == "priceperhead") {
      setPricePerHead(value);
    } else if (type == "seasonalprice") {
      setSeasonalPrice(value);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (!selectedHotelType) {
        toast.error("Hotel Type is required");
        return;
      }

      if (!hotelName) {
        toast.error("Hotel name is required");
        return;
      }

      if (!city) {
        toast.error("City is required");
        return;
      }

      if (!selectedGrade || selectedGrade.length === 0) {
        toast.error("Please select at least one Grade");
        setLoading(false);
        return;
      }
      // debugger
      //       if(!accommodationType){
      //         toast.error("Accommodation Type is required");
      //         return
      //       };

      //       if(!selectedMealPlan){
      //         toast.error("Meal Plan is required");
      //         return
      //       }
      //       if(!pricePerHead){
      //         toast.error("Price per head is required");
      //         return
      //       };

      fields.forEach((field, index) => {
        if (!field.accommodationType || field.accommodationType.trim() === "") {
          throw new Error(
            `Accommodation Type is required for plan ${index + 1}.`
          );
        }
        if (!field.mealPlan || field.mealPlan.trim() === "") {
          throw new Error(`Meal Plan is required for plan ${index + 1}.`);
        }
        if (!field.pricePerHead || field.pricePerHead.trim() === "") {
          throw new Error(`Price Per Head is required for plan ${index + 1}.`);
        }
      });

      if (showSeasonalFields) {
        if (!startDate) {
          toast.error("Season start date is required");
          return;
        }

        if (!endDate) {
          toast.error("Season end date is required");
          return;
        }
        if (!seasonalPrice) {
          toast.error("Seasonal price is required");
          return;
        }
      }
      const payload = {
        // gradeid: selectedGrade.map(
        //   (grade) => gradeOptions.find((opt) => opt.label === grade.value)?.id
        // ),
        gradeid: Array.isArray(selectedGrade)
          ? `{${selectedGrade.map((opt) => opt?.id).join(",")}}`
          : selectedGrade?.id
          ? `{${selectedGrade.id}}`
          : null,
        mealplanid: mealPlanOptions.filter(
          (list) => list.label == selectedMealPlan
        )?.[0]?.id,
        hoteltypeid: hotelTypeOptions.filter(
          (list) => list.label == selectedHotelType
        )?.[0]?.id,
        accommodationtypeid: accommodationOptions.filter(
          (list) => list.label == selectedAccommodation
        )?.[0]?.id,
        seasonstart: startDate,
        seasonend: endDate,
        hotelname: hotelName,
        city: city,
        priceperhead: pricePerHead,
        season_priceperhead: seasonalPrice,
        type: hotelinfo?.hotelid ? "update" : "insert",
        hotelid: hotelinfo?.hotelid,
        hotelwise_planVal: fields,
      };

      // hotelwise_planVal
      await post("/addHotel", payload);
      toast.success(
        hotelinfo?.hotelid
          ? "Hotel updated successfully!"
          : "Hotel added successfully!"
      );
      setTimeout(() => {
        navigate("/hotels");
      }, 2000);
    } catch (error) {
      toast.error(error.message || "An error occurred while adding the hotel."); // Show toast error message for any exceptions
    } finally {
      setLoading(false);
    }
  };

  const hotelwiseHandleChange = (e, index, fieldName) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = {
        ...updatedFields[index],
        [fieldName]: e?.target?.value ? e.target.value : e.value,
      };
      return updatedFields;
    });
    if (fieldName === "seasonalPrice") {
      setSeasonalPrice(e.target.value);
    }
  };

  return (
    <div>
      {loading ? <Loader /> : <></>}
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            Hotels
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Master Data</span> /
            <span className="font-regular">Add Hotel</span>
          </p>
        </div>

        <div className="flex gap-4">
          {hotelinfo?.hotelid ? (
            <Button type="primary" label="Update" onClick={handleSave} />
          ) : (
            <Button type="primary" label="Save" onClick={handleSave} />
          )}
          <Button
            type="secondary"
            label="Cancel"
            onClick={() => navigate("/hotels")}
          />
        </div>
      </div>
      <Paper className="my-8 pt-6 pb-6">
        <h1 className="text-[16px] font-semibold">Upload Type</h1>
        <div className="mt-4 space-y-4">
          <RadioButton
            name="customRadio"
            value="single"
            checked={selectedValue === "single"}
            onChange={handleRadioChange}
            label="Single Upload"
          />
          <RadioButton
            name="customRadio"
            value="bulk"
            checked={selectedValue === "bulk"}
            onChange={handleRadioChange}
            label="Bulk Upload"
            className={"ml-4"}
          />
        </div>
        {selectedValue === "single" && (
          <>
            <Paper className="my-8 border">
              <h1 className="text-[20px] font-bold mb-4">Hotel Details</h1>
              <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    Hotel Type<span className="text-red-500">*</span>
                  </label>
                  <Select
                    placeholder="Select Type..."
                    options={hotelTypeOptions}
                    onChange={(e) => handleFormSelection(e, "hoteltype")}
                    value={hotelTypeOptions.find(
                      (option) => option.value === selectedHotelType
                    )}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">
                    Hotel Name<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    placeholder="Enter Hotel Name..."
                    value={hotelName}
                    onChange={(e) => handleChange(e, "hotelname")}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2">
                    City (Destination)<span className="text-red-500">*</span>
                  </label>
                  <InputText
                    placeholder="Enter City..."
                    onChange={(e) => handleChange(e, "city")}
                    value={city}
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold">
                    Grade<span className="text-red-500">*</span>
                  </label>
                  <MultiNewSelect
                    options={gradeOptions}
                    value={selectedGrade}
                    placeHolder="Select Grade..."
                    onChange={(e) => handleFormSelection(e, "grade")}
                    isMulti={true}
                  />
                </div>
              </div>
              <div className="col-span-4 flex items-center gap-2 mt-4 mb-4">
                <input
                  type="checkbox"
                  id="seasonalPrice"
                  checked={showSeasonalFields}
                  onChange={(e) => setShowSeasonalFields(e.target.checked)}
                />
                <label
                  htmlFor="seasonalPrice"
                  className="text-[16px] font-semibold"
                >
                  Seasonal Price
                </label>
              </div>
              {showSeasonalFields && (
                <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div>
                    <label className="text-[16px] font-semibold">
                      Season Start Date<span className="text-red-500">*</span>
                    </label>
                    <DatePicker
                      selectedDate={startDate}
                      onDateChange={(date) =>
                        handleDateChange(date, "startdate")
                      }
                      placeholderText="Start Date"
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="text-[16px] font-semibold">
                      Season End Date<span className="text-red-500">*</span>
                    </label>
                    <DatePicker
                      selectedDate={endDate}
                      onDateChange={(date) => handleDateChange(date, "enddate")}
                      placeholderText="End Date"
                      className="w-full "
                    />
                  </div>
                </div>
              )}
              <div className="grid">
                {fields?.map((field, index) => (
                  <div
                    key={index}
                    className="grid  grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4 items-center rounded-xl border-gray-300 p-4 mt-4 mb-2 border"
                  >
                    {/* Accommodation Type */}
                    <div>
                      <label className="text-[16px] font-semibold mb-2">
                        Accommodation Type
                        <span className="text-red-500">*</span>
                      </label>
                      <Select
                        options={accommodationOptions}
                        value={accommodationOptions.find(
                          (option) => option.value === field.accommodationType
                        )}
                        onChange={(e) =>
                          hotelwiseHandleChange(e, index, "accommodationType")
                        }
                        placeholder="Select Accommodation..."
                      />
                    </div>

                    {/* Meal Plan */}
                    <div>
                      <label className="text-[16px] font-semibold">
                        Meal Plan<span className="text-red-500">*</span>
                      </label>
                      <Select
                        options={mealPlanOptions}
                        value={mealPlanOptions.find(
                          (option) => option.value === field.mealPlan
                        )}
                        placeholder="Select Meal Plan..."
                        onChange={(e) =>
                          hotelwiseHandleChange(e, index, "mealPlan")
                        }
                      />
                    </div>

                    {/* Price per head */}
                    <div>
                      <label className="text-[16px] font-semibold">
                        Price per head (INR)
                        <span className="text-red-500">*</span>
                      </label>
                      <InputText
                        placeholder="Enter Price..."
                        onChange={(e) =>
                          hotelwiseHandleChange(e, index, "pricePerHead")
                        }
                        value={field.pricePerHead}
                      />
                    </div>
                    {showSeasonalFields && (
                      <div>
                        <label className="text-[16px] font-semibold">
                          Seasonal Price per Head (INR)
                          <span className="text-red-500">*</span>
                        </label>
                        <InputText
                          placeholder="Enter Seasonal Price..."
                          onChange={(e) =>
                            hotelwiseHandleChange(e, index, "seasonalPrice")
                          }
                          value={field.seasonalPrice || ""}
                        />
                      </div>
                    )}
                    {/* Buttons */}
                    <div className="flex pt-8">
                      {index === fields.length - 1 ? (
                        <button
                          className="p-2 rounded bg-black text-white flex justify-center items-center"
                          onClick={() => addNewFieldSet(index)}
                        >
                          <span className="material-symbols-outlined">add</span>
                        </button>
                      ) : (
                        <button
                          className="p-2 rounded bg-red-500 text-white flex justify-center items-center"
                          onClick={() => deleteFieldSet(index)}
                        >
                          <span className="material-symbols-outlined">
                            delete
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
          </>
        )}
        <ToastContainer />

        {selectedValue === "bulk" && (
          <Paper className="my-8 border">
            <h1 className="text-[20px] font-bold mb-4">Upload File</h1>
            <UploadHotel />
          </Paper>
        )}
      </Paper>
    </div>
  );
};

export default AddHotel;

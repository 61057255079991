import React, { useState, useEffect } from "react";
import Button from "../customComponents/Button";
import Table from "../customComponents/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { post } from "../utils/api";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../customComponents/ConfirmationModal";
import DatePicker from "../customComponents/DatePicker";
import AddonModal from "../customComponents/AddonModal";
import { ToastContainer, toast } from "react-toastify"; 

const Addons = () => {
  const [addonsData, setAddonsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [formattedDate, setFormattedDate] =useState();
  const [initialData, setInitialData] = useState([]);
  const [searchText, setSearchData] = useState("");




  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("slNo", {
      header: "Sl. No",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("addonitem", {
      header: "ADDON ITEM",
    }),
    columnHelper.accessor(
      (row) => {
        return row.addonprice === "null"
          ? row.season_priceperhead
          : row.addonprice;
      },
      {
        header: "PRICE PER HEAD",
        id: "priceperhead",
        cell: (info) => `₹ ${info.getValue()}`,
      }
    ),
    columnHelper.accessor(
      (row) => {
        if (row.seasonstart && row.seasonend) {
          const startDate = new Date(row.seasonstart).toLocaleDateString();
          const endDate = new Date(row.seasonend).toLocaleDateString();
          return `${startDate} - ${endDate}`;
        }
        return "N/A";
      },
      {
        header: "SEASON",
        id: "season",
      }
    ),
    columnHelper.accessor("actions", {
      header: "ACTIONS",
      cell: (info) => (
        <div className="flex gap-2">
          <button
            className="p-2 bg-gray-200 rounded-md"
            onClick={() =>
              navigate("/add-addons", {
                state: { editData: info.row.original },
              })
            }
          >
            <span className="material-symbols-outlined">edit</span>
          </button>
          <button
            className="p-2 bg-gray-200 rounded-md"
            onClick={() => {
              setSelectedAddon(info.row.original); 
              setShowVisibilityModal(true); 
            }}
          >
            <span className="material-symbols-outlined">visibility</span>
          </button>

          <button
            className="p-2 bg-gray-200 text-red-500 rounded-md"
            onClick={() => handleDeleteClick(info.row.original)}
          >
            <span className="material-symbols-outlined">delete</span>
          </button>
        </div>
      ),
    }),
  ];

  const fetchAddons = async () => {
    try {
      const response = await post("/getAllAddons", {});
      const temp = response.data.map((list) => ({
        alterCreatedon: new Date(list.createdon)
        .toLocaleDateString("en-GB")
        .replace(/\//g, "-"),
        ...list,
      }));
      setAddonsData(temp);
      setFilteredData(temp);
      setInitialData(temp);

    } catch (error) {
      console.error("Error fetching addons:", error);
    }
  };




  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  useEffect(() => {
    const filteredData = initialData.filter((item) => {
      // Convert values to lowercase for case-insensitive comparison
      const matchesSearch =
        !searchText || // Allow all if searchText is empty
        item.addonitem?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.addonprice?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.seasonstart?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.seasonend?.toLowerCase().includes(searchText.toLowerCase());

    

        const matchesDay =!formattedDate ||  item.alterCreatedon == formattedDate;


      // Combine filters, allowing partial matches if others are not applied
      return matchesSearch &&  matchesDay;
    });

    setFilteredData(filteredData);
  }, [searchText,selectedDate]);

  const handleDeleteClick = (item) => {
    setDeleteItem(item);
    setIsDeleteModal(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModal(false);
    setDeleteItem(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await post("/deleteAddon", { addonid: deleteItem.addonid });   
         toast.success("Addon deleted successfully");
      fetchAddons(); // Refresh the list
      handleCloseModal();
    } catch (error) {
      toast.error("Addon deleted failed");
      console.error("Error deleting addon:", error);
    }
  };




  useEffect(() => {
    fetchAddons();
  }, []);

  const handleDateChange = (filterDate) => {
    // setSelectedDate(date);

    const date = new Date(filterDate);
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    
    const formattedDate = `${day}-${month}-${year}`;
    console.log(formattedDate);
    setFormattedDate(formattedDate)
        setSelectedDate(filterDate);

  };


  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[28px] font-semibold">
            Addons
          </h2>
          <p className="text-[14px]">
            <span className="font-bold">Master Data</span> /
            <span className="font-regular">Addons</span>
          </p>
        </div>
        <div className="flex mt-4 justify-end gap-4">
          <Button
            type="primary"
            label="Add Addons"
            onClick={() => navigate("/add-addons")}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 md:flex md:justify-between">
        <div className="w-full">
          <input
             type="text"
             className="outline-none p-2 rounded-l-lg w-full sm:w-5/12 h-[40px]"
             placeholder="Search..."
             value={searchText}
             onChange={handleSearch}
          />
        </div>
        <div className="w-full md:w-auto md:flex md:gap-4">
          <div>
            <DatePicker
              placeholder="Select Destination..."
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
            />
          </div>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isDeleteModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        title="Delete Addon"
        message={`Are you sure you want to delete the Addon "${deleteItem?.addonitem}"?`}
      />
      <div className="bg-white rounded-xl border-gray-300 mt-7 pb-6">
        <Table columns={columns} data={filteredData} />
      </div>
      {showVisibilityModal && (
        <AddonModal
          onClose={() => setShowVisibilityModal(false)}
          addonData={selectedAddon || {}}
        />
      )}
           <ToastContainer />
    </div>
    
  );
};

export default Addons;

import React, { useEffect, useState } from "react";
import Table from "../customComponents/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { post } from "../utils/api";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Users = () => {
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("username", {
      header: "USER NAME",
      cell: ({ row }) => {
        return (
          <div className="flex items-center">
            {row?.original?.profilephoto ? (
              <img
                src={row?.original?.profilephoto}
                alt="profile_pic"
                className="w-8 h-8 rounded-full mr-3"
              />
            ) : (
              <span className="material-symbols-outlined w-8 h-8 mr-3 rounded-full flex items-center justify-center bg-gray-200">
                person
              </span>
            )}
            <p>{row?.original?.username}</p>
          </div>
        );
      },
    }),
    columnHelper.accessor("role", {
      header: "DESIGNATION",
    }),
    columnHelper.accessor("email", {
      header: "EMAIL",
    }),
    columnHelper.accessor("mobilenumber", {
      header: "MOBILE NUMBER",
    }),
    columnHelper.accessor("assigncity", {
      header: "ASSIGN CITY",
    }),
    columnHelper.accessor((row) => row.actions, {
      header: "ACTIONS",
      cell: ({ row }) => (
        <div className="flex">
          <button
            className="p-2 bg-gray-200 rounded-md mr-2"
            onClick={() => setSelectedUser(row.original)}
          >
            <span className="material-symbols-outlined">visibility</span>
          </button>
          {user.role !== "Sales Manager" && (
            <>
              <button
                className="p-2 bg-gray-200 rounded-md mr-2"
                onClick={() =>
                  navigate("/add-user", {
                    state: { user: row.original, type: "edit", isEdit: true },
                  })
                }
              >
                <span className="material-symbols-outlined">edit</span>
              </button>
              <button
                className="p-2 bg-red-200 rounded-md"
                onClick={() => {
                  setSelectedUser(row.original);
                  setShowDeleteModal(true);
                }}
              >
                <span className="material-symbols-outlined">delete</span>
              </button>
            </>
          )}
        </div>
      ),
    }),
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {};
        const response = await post("/getAllUser", payload);
        const filteredData = response.data.filter(
          (user) => user.role !== "Admin"
        );
        setData(filteredData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async () => {
    try {
      const payload = { userid: selectedUser.userid };
      await post("/updateUserStatus", payload);
      setData(data.filter((user) => user.userid !== selectedUser.userid));
      setShowDeleteModal(false);
      setSelectedUser(null);
      toast.success("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user");
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            All User
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Users </span> /
            <span className="font-regular">All User</span>
          </p>
        </div>
      </div>
      <div className="py-8">
        <Table columns={columns} data={data} />
      </div>

      {selectedUser && !showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-6  h-[40rem] lg:h-[28rem] overflow-y-auto">
              <div className="flex justify-end">
                <button className="p-2" onClick={() => setSelectedUser(null)}>
                  <span className="material-symbols-outlined">close</span>
                </button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-5 gap-6 items-center">
                <div className="md:col-span-1 flex justify-center md:justify-start">
                  {selectedUser?.profilephoto ? (
                    <img
                      className="rounded-full border-4 border-white w-32 h-32"
                      src={selectedUser.profilephoto}
                      alt="Profile"
                    />
                  ) : (
                    <span className="material-symbols-outlined w-28 h-28 rounded-full flex items-center justify-center bg-gray-200 text-7xl">
                      person
                    </span>
                  )}
                </div>
                <div className="md:col-span-4">
                  <h2 className="text-3xl font-semibold text-gray-800">
                    {selectedUser?.username || "N/A"}
                  </h2>
                  <p className="text-gray-500">{selectedUser?.role}</p>
                  <div className="mt-2 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="mt-7 items-center justify-center">
                      <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                        mail
                      </span>
                      <div className="mt-2">
                        <p className="text-[14px] leading-5 text-gray-500">
                          {selectedUser?.email || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="mt-7">
                      <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                        call
                      </span>
                      <div className="mt-2">
                        <p className="text-[14px] leading-5 text-gray-500">
                          {selectedUser?.mobilenumber || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="mt-7">
                      <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                        location_on
                      </span>
                      <div className="mt-2">
                        <p className="text-[14px] leading-5 text-gray-500">
                          {selectedUser?.assigncity || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="mt-7">
                      <span className="material-symbols-outlined text-[22px] leading-5 text-gray-500">
                        person_add
                      </span>
                      <div className="mt-2">
                        <p className="text-[14px] leading-5 text-gray-500">
                          {selectedUser?.role || "N/A"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selectedUser?.role !== "Admin" && (
                <div className="mt-7">
                  <h3 className="text-lg font-semibold text-gray-800 mb-7">
                    Activities
                  </h3>
                  <div className="grid w-full  h-full grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="col-span-1">
                      <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                        <p className="text-[20px] font-semibold">
                          {selectedUser?.openedcount}
                        </p>
                        <p className="text-[14px] font-normal">
                          Opened Enquiry
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                        <p className="text-[20px] font-semibold">
                          {selectedUser?.confirmedcount}
                        </p>
                        <p className="text-[16px] font-normal">
                          Confirmed Enquiry
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                        <p className="text-[20px] font-semibold">
                          {selectedUser?.closedcount}
                        </p>
                        <p className="text-[14px] md:text-[16px] font-normal">
                          Closed Enquiry
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="p-2 bg-[#EFEFF0] rounded-lg text-center text-[#797979]">
                        <p className="text-[20px] font-semibold">
                          {selectedUser?.cancelledcount}
                        </p>
                        <p className="text-[16px] font-normal">
                          Cancelled Enquiry
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Delete User</h2>
            <p>
              Are you sure you want to delete user{" "}
              <strong>{selectedUser?.username}</strong>?
            </p>
            <div className="flex justify-end mt-6 ">
              <button
                className="px-4 py-2 bg-gray-200 rounded-md mr-2"
                onClick={() => {
                  setShowDeleteModal(false);
                  setSelectedUser(null);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Users;

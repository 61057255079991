import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FilterSelect from "../customComponents/FilterSelect";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { post } from "../utils/api";

const data = [
  { name: "2019", value: 200 },
  { name: "2020", value: 250 },
  { name: "2021", value: 220 },
  { name: "2022", value: 240 },
  { name: "2023", value: 230 },
  { name: "2024", value: 220 },
  { name: "2025", value: 220 },
];
const AdminDashboard = () => {
  const [selectedtimePeriod, setSelectedtimePeriod] = useState({
    value: "Yearly",
    label: "Yearly",
  });
  const [canFetchData, setCanFetchData] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "All",
    value: "",
    id: "",
  });
  const [selectedName, setSelectedName] = useState({
    label: "All",
    value: "",
    id: "",
  });
  const [selectedEnquiry, setSelectedEnquiry] = useState({
    label: "All",
    value: "",
    id: "",
  });
  const [selectedPersonStatus, setSelectedPersonStatus] = useState({
    label: "All",
    value: "",
    id: "",
  });

  const [selectedSalesTimePeriod, setSelectedSalesTimePeriod] = useState({
    value: "Yearly",
    label: "Yearly",
  });

  const [selectedSalesStatus, setSelectedSalesStatus] = useState({
    label: "All",
    value: "",
    id: "",
  });

  const [selectedSalesName, setSalesSelectedName] = useState({
    label: "All",
    value: "",
    id: "",
  });

  const [selectedSchoolTimePeriod, setSelectedSchoolTimePeriod] = useState({
    value: "Yearly",
    label: "Yearly",
  });

  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [salesPersonOptions, setSalesPersonOptions] = useState([]);
  const [enquiryStatusOptions, setEnquiryStatusOptions] = useState([]);
  const [fuelifeStatusOptions, setFuelifeStatusOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);

  const [error, setError] = useState(false);
  const [schoolVisitData, setSchoolVisitData] = useState([]);
  const [totalSumData, setTotalSumData] = useState([]);
  const [itineraryCount, setItineraryCount] = useState(0);
  const [packageCount, setPackageCount] = useState(0);

  console.log(selectedSchoolTimePeriod, "selectedSchoolTimePeriod");

  const user = JSON.parse(localStorage.getItem("user"));

  const handletimePeriod = (option) => setSelectedtimePeriod(option);
  const handleStatusChange = (option) => setSelectedStatus(option);
  const handleNameChange = (option) => setSelectedName(option);
  const handleEnquiryChange = (option) => setSelectedEnquiry(option);
  const handlePersonStatusChange = (option) => setSelectedPersonStatus(option);

  //Sales performance
  const handleSalestimePeriod = (option) => setSelectedSalesTimePeriod(option);
  const handleSalesStatusChange = (option) => setSelectedSalesStatus(option);
  const handleSalesNameChange = (option) => setSalesSelectedName(option);

  //School visited
  const handleSchoolTimePeriod = (option) =>
    setSelectedSchoolTimePeriod(option);

  const shouldShowRevenue =
    user?.role === "Admin" || user?.role === "Backend Team Manager";
  const shouldShowSchoolVisited = user?.role !== "Sales Executive";

  const timePeriodOptions = [
    { value: "Yearly", label: "Yearly" },
    { value: "Last6Months", label: "Last 6 Months" },
    { value: "Monthly", label: "Monthly" },
    { value: "Daily", label: "Daily" },
  ];

  const itinerarySpan =
    user?.role === "Backend Team" && "Sales Manager"
      ? "lg:col-span-6"
      : "lg:col-span-3";

  const enquiryOptions = [
    { value: "Domestic", label: "Domestic" },
    { value: "Day Outing", label: "Day Outing" },
    { value: "International", label: "International" },
  ];

  const personStatusOptions = [
    { value: "Assign", label: "Assign" },
    { value: "Visited", label: "Visited" },
    { value: "Not Visited", label: "Not Visited" },
  ];

  const fetchSalesPerformance = async () => {
    try {
      setLoading(true);
      setError(false);
      const payload = {
        date: selectedSalesTimePeriod?.value || "Yearly",
        enquirystatusid: selectedStatus?.value || null,
        userid: selectedName?.value || null,
      };
      const response = await post("/getSalesPerformance", payload);
      if (response.status) {
        // Check if data exists and has required properties
        if (response?.data && response?.data.length > 0) {
          const transformedData = response.data.map((list) => ({
            name: list.name,
            value: parseInt(list.value),
          }));

          // const transformedData = [
          //   {
          //     name: response.data.name,
          //     value: parseInt(response.data.value),
          //   },
          // ];
          // if (selectedSalesTimePeriod.value === "Last6Months") {
          //   setSalesData(getLast6MonthsData(transformedData));
          // } else {
          setSalesData(transformedData);
          // }
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching sales performance:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSalesStatus && selectedSalesName) {
      setCanFetchData(true);
      fetchSalesPerformance();
    }
  }, [selectedSalesStatus, selectedSalesName, selectedSalesTimePeriod]);

  const fetchSalesPersons = async () => {
    try {
      const payload = { type: "salesusers" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((person) => ({
        label: person.label,
        value: person.userid,
        id: person.id,
      }));
      options.unshift({ label: "All", value: "", id: "" });
      setSalesPersonOptions(options);
    } catch (error) {
      console.error("Error fetching sales persons:", error);
    }
  };

  const fetchFueLifeStatus = async () => {
    try {
      const payload = { type: "status" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((status) => ({
        label: status.label,
        value: status.value,
      }));
      options.unshift({ label: "All", value: "", id: "" });
      setFuelifeStatusOptions(options);
    } catch (error) {
      console.error("Error fetching enquiry status:", error);
    }
  };

  const fetchPackageType = async () => {
    try {
      const payload = { type: "packagetype" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((status) => ({
        label: status.label,
        value: status.value,
      }));
      options.unshift({ label: "All", value: "", id: "" });
      setPackageOptions(options);
    } catch (error) {
      console.error("Error fetching enquiry status:", error);
    }
  };

  const getLast6MonthsData = (data) => {
    const today = new Date();
    const last6Months = [];

    for (let i = 5; i >= 0; i--) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const monthYear = date.toLocaleString("default", {
        month: "short",
        year: "numeric",
      });
      last6Months.push(monthYear);
    }

    return data.filter((item) => last6Months.includes(item.name));
  };

  const fetchEnquiryStatus = async () => {
    try {
      const payload = { type: "enquirystatus" };
      const result = await post("/getOptionList", payload);
      const options = result.data.map((status) => ({
        label: status.label,
        value: status.value,
        id: status.enquirystatusid,
        aliasname: status.aliasname,
      }));
      options.unshift({ label: "All", value: "", id: "", aliasname: "All" });
      setEnquiryStatusOptions(options);
    } catch (error) {
      console.error("Error fetching enquiry status:", error);
    }
  };
  const fetchSchoolVisited = async () => {
    try {
      setLoading(true);
      const payload = {
        userid: selectedName?.value || null,
        date: selectedtimePeriod?.value || "Yearly",
        statusid: selectedPersonStatus?.value || null,
      };
      const response = await post("/getSchoolVisited", payload);
      if (response.status) {
        if (response.data && response.data.length > 0) {
          const transformedData = response.data.map((list) => ({
            name: list.name,
            value: parseInt(list.value),
          }));

          // if (selectedSchoolTimePeriod.value === "Last6Months") {
          //   setSchoolVisitData(getLast6MonthsData(transformedData));
          // } else {
          setSchoolVisitData(transformedData);
          // }
        } else {
          setSchoolVisitData([]);
        }
      }
    } catch (error) {
      console.error("Error fetching school visits:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTotalSumEnquiry = async () => {
    try {
      setLoading(true);
      const payload = {
        packagetypeid: selectedEnquiry?.value || null,
        date: selectedtimePeriod?.value || "Yearly",
      };
      const response = await post("/getTotalSumEnquiry", payload);
      if (response.status) {
        if (response.data && response.data.length > 0) {
          const transformedData = response.data.map((list) => ({
            name: list.name,
            value: parseFloat(list.total_amount),
          }));

          // if (selectedtimePeriod.value === "Last6Months") {
          //   setTotalSumData(getLast6MonthsData(transformedData));
          // } else {
          setTotalSumData(transformedData);
          //}
        } else {
          setTotalSumData([]);
        }
      }
    } catch (error) {
      console.error("Error fetching total sum:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchCounts = async () => {
    try {
      const itineraryResponse = await post("/getOptionList", {
        type: "itinerarycount",
      });
      const packageResponse = await post("/getOptionList", {
        type: "packagecount",
      });

      if (itineraryResponse.status) {
        setItineraryCount(itineraryResponse.data[0].count);
      }

      if (packageResponse.status) {
        setPackageCount(packageResponse.data[0].count);
      }
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };
  useEffect(() => {
    fetchSalesPersons();
    fetchEnquiryStatus();
    fetchFueLifeStatus();
    fetchPackageType();
  }, []);
  useEffect(() => {
    if (selectedName && selectedPersonStatus) {
      fetchSchoolVisited();
    }
  }, [selectedName, selectedPersonStatus, selectedSchoolTimePeriod]);

  useEffect(() => {
    if (selectedEnquiry) {
      fetchTotalSumEnquiry();
    }
  }, [selectedEnquiry, selectedtimePeriod]);

  useEffect(() => {
    fetchCounts();
  }, []);
  console.log("<<<totalSumData>>>", totalSumData);
  return (
    <div>
      {/* Header */}
      <h2 className="text-primary-black text-[28px] font-semibold mb-4">
        Dashboard
      </h2>

      {/* Main Grid */}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-6">
        <div className="col-span-12 sm:col-span-6 lg:col-span-6 bg-white rounded-lg shadow p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-[16px] font-semibold">Sales Performance</h2>
          </div>
          <div className="flex flex-col gap-4 sm:flex-row md:gap-4 mb-4">
            <div className="w-full md:w-40 lg:w-52">
              <FilterSelect
                options={timePeriodOptions}
                onChange={handleSalestimePeriod}
                value={selectedSalesTimePeriod}
                placeholder="Yearly"
              />
            </div>
            <div className="w-full md:w-40 lg:w-52">
              <FilterSelect
                options={enquiryStatusOptions}
                onChange={handleSalesStatusChange}
                value={selectedSalesStatus}
                placeholder="Select Status"
              />
            </div>
            <div className="w-full md:w-40 lg:w-52">
              <FilterSelect
                options={salesPersonOptions}
                onChange={handleSalesNameChange}
                value={selectedSalesName}
                placeholder="Select Sales Person"
              />
            </div>
          </div>
          <div className="h-64 w-full">
            {!canFetchData ? (
              <div className="relative h-full">
                {/* Dummy Graph with reduced opacity */}
                <div className="absolute inset-0 opacity-20">
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={data}>
                      <defs>
                        <linearGradient
                          id="dummyGradient"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#6b68c7"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#6b68c7"
                            stopOpacity={0.1}
                          />
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                      <XAxis
                        dataKey="name"
                        stroke="#666"
                        fontSize={12}
                        tickLine={false}
                      />
                      <YAxis
                        stroke="#666"
                        fontSize={12}
                        tickLine={false}
                        axisLine={false}
                      />
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#6b68c7"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#dummyGradient)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>

                {/* Overlay with selection guidance */}
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80">
                  <div className="text-center space-y-6 transform transition-all duration-500">
                    <div className="text-2xl font-semibold text-[#6b68c7] animate-pulse">
                      Your Sales Data Visualization Awaits
                    </div>

                    <div className="flex flex-col items-center space-y-4">
                      <div className="flex items-center space-x-3 cursor-pointer transform hover:scale-105 transition-transform">
                        <div
                          className={`h-4 w-4 rounded-full ${
                            selectedName
                              ? "bg-[#6b68c7]"
                              : "bg-gray-300 animate-ping"
                          }`}
                        />
                        <span
                          className={`text-lg ${
                            selectedName
                              ? "text-[#6b68c7] font-medium"
                              : "text-gray-600"
                          }`}
                        >
                          Select Sales Person
                        </span>
                      </div>

                      <div className="w-32 border-t border-gray-200" />

                      <div className="flex items-center space-x-3 cursor-pointer transform hover:scale-105 transition-transform">
                        <div
                          className={`h-4 w-4 rounded-full ${
                            selectedStatus
                              ? "bg-[#6b68c7]"
                              : "bg-gray-300 animate-ping"
                          }`}
                        />
                        <span
                          className={`text-lg ${
                            selectedStatus
                              ? "text-[#6b68c7] font-medium"
                              : "text-gray-600"
                          }`}
                        >
                          Select Enquiry Status
                        </span>
                      </div>
                    </div>

                    <div className="text-gray-500 italic mt-4">
                      Complete the selections above to unlock your data insights
                    </div>
                  </div>
                </div>
              </div>
            ) : loading ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#6b68c7]" />
              </div>
            ) : error ? (
              <div className="flex flex-col items-center justify-center h-full">
                <div className="text-red-500 mb-4">
                  <svg
                    className="w-16 h-16 animate-bounce"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-red-500 mb-2">
                  Data Fetch Failed
                </h3>
                <p className="text-gray-600 mb-4">
                  Unable to load sales performance data
                </p>
                <button
                  onClick={fetchSalesPerformance}
                  className="px-4 py-2 bg-[#6b68c7] text-white rounded-md hover:bg-opacity-90 transition-all"
                >
                  Try Again
                </button>
              </div>
            ) : salesData.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full">
                <div className="text-[#6b68c7] mb-4">
                  <svg
                    className="w-16 h-16"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-[#6b68c7] mb-2">
                  No Sales Data Available
                </h3>
                <p className="text-gray-500 text-center">
                  No sales performance data found for selected filters
                </p>
                <p className="text-gray-400 text-sm mt-2">
                  Try selecting different time period or sales person
                </p>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={salesData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient
                      id="colorGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#6b68c7" stopOpacity={0.8} />
                      <stop
                        offset="95%"
                        stopColor="#6b68c7"
                        stopOpacity={0.1}
                      />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                  <XAxis
                    dataKey="name"
                    stroke="#666"
                    fontSize={12}
                    tickLine={false}
                  />
                  <YAxis
                    stroke="#666"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "white",
                      border: "none",
                      borderRadius: "0.5rem",
                      boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
                      padding: "0.75rem",
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#6b68c7"
                    strokeWidth={2}
                    fillOpacity={1}
                    fill="url(#colorGradient)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        {/* Your Total Revenue */}
        {shouldShowRevenue && (
          <div className="col-span-12 sm:col-span-6 lg:col-span-6 bg-white rounded-lg shadow p-4">
            <div className="flex justify-between items-center mb-2">
              <div>
                <h2 className="text-primary-black text-[28px] font-semibold">
                  ₹{totalSumData?.[0]?.value || 0}
                </h2>
                <p className="text-[#939393]-600 font-medium mb-3">
                  Your total revenue so far
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 sm:flex-row md:gap-4 mb-4">
              <div className="w-full md:w-40 lg:w-52">
                <FilterSelect
                  options={timePeriodOptions}
                  onChange={handletimePeriod}
                  value={selectedtimePeriod}
                  placeholder="Yearly"
                />
              </div>
              <div className="w-full md:w-40 lg:w-52">
                <FilterSelect
                  options={packageOptions}
                  onChange={handleEnquiryChange}
                  value={selectedEnquiry}
                  placeholder="Day Outing"
                />
              </div>
            </div>
            <div className="h-64 w-full">
              {loading ? (
                <div className="flex items-center justify-center h-full">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#8884d8]" />
                </div>
              ) : totalSumData.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="text-[#8884d8] mb-4">
                    <svg
                      className="w-16 h-16"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold text-[#8884d8] mb-2">
                    No Revenue Data
                  </h3>
                  <p className="text-gray-500 text-center">
                    No revenue information available for selected period
                  </p>
                  <p className="text-gray-400 text-sm mt-2">
                    Try selecting a different package type or time period
                  </p>
                </div>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={totalSumData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient
                        id="colorGradient"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor="#8884d8"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#8884d8"
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                    <XAxis
                      dataKey="name"
                      stroke="#666"
                      fontSize={12}
                      tickLine={false}
                    />
                    <YAxis
                      stroke="#666"
                      fontSize={12}
                      tickLine={false}
                      axisLine={false}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "white",
                        border: "none",
                        borderRadius: "0.5rem",
                        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
                        padding: "0.75rem",
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      strokeWidth={2}
                      fillOpacity={1}
                      fill="url(#colorGradient)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        )}
        {/* School Visited */}
        {shouldShowSchoolVisited && (
          <div className="col-span-12 sm:col-span-6 lg:col-span-6 bg-white rounded-lg shadow p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-[16px] font-semibold">School Visited</h2>
            </div>
            {/* Filters */}
            <div className="flex flex-col gap-4 sm:flex-row md:gap-4 mb-4">
              <div className="w-full md:w-40 lg:w-52">
                <FilterSelect
                  options={timePeriodOptions}
                  onChange={handleSchoolTimePeriod}
                  value={selectedSchoolTimePeriod}
                  placeholder="Yearly"
                />
              </div>
              <div className="w-full md:w-40 lg:w-52">
                <FilterSelect
                  options={salesPersonOptions}
                  onChange={handleNameChange}
                  value={selectedName}
                  placeholder="Select..."
                />
              </div>
              <div className="w-full md:w-40 lg:w-52">
                <FilterSelect
                  options={fuelifeStatusOptions}
                  onChange={handlePersonStatusChange}
                  value={selectedPersonStatus}
                  placeholder="Visited"
                />
              </div>
            </div>
            {/* School visited chart section : */}
            <div className="h-64 w-full">
              {loading ? (
                <div className="flex items-center justify-center h-full">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#8884d8]" />
                </div>
              ) : schoolVisitData.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="text-[#8884d8] mb-4">
                    <svg
                      className="w-16 h-16"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold text-[#8884d8] mb-2">
                    No School Visits Found
                  </h3>
                  <p className="text-gray-500 text-center">
                    No school visits recorded for the selected criteria
                  </p>
                  <p className="text-gray-400 text-sm mt-2">
                    Try adjusting your filters or selecting a different time
                    period
                  </p>
                </div>
              ) : (
                // <ResponsiveContainer width="100%" height="100%">
                //   <AreaChart
                //     data={schoolVisitData}
                //     margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                //   >
                //     {/* Existing chart configuration */}
                //   </AreaChart>
                // </ResponsiveContainer>

                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={schoolVisitData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient
                        id="colorGradient"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor="#6b68c7"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#6b68c7"
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                    <XAxis
                      dataKey="name"
                      stroke="#666"
                      fontSize={12}
                      tickLine={false}
                    />
                    <YAxis
                      stroke="#666"
                      fontSize={12}
                      tickLine={false}
                      axisLine={false}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "white",
                        border: "none",
                        borderRadius: "0.5rem",
                        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
                        padding: "0.75rem",
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="value"
                      stroke="#6b68c7"
                      strokeWidth={2}
                      fillOpacity={1}
                      fill="url(#colorGradient)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        )}
        {/* Total Itinerary */}
        <div
          className={`col-span-12 sm:col-span-6 ${itinerarySpan} bg-white rounded-lg shadow p-4 text-center flex flex-col justify-center items-center`}
        >
          <h3 className="text-[16px] font-semibold">Total Itinerary</h3>
          <div className="text-primary-black text-[28px] font-semibold">
            {itineraryCount}
          </div>
          <p className="text-gray-600 font-normal mt-2">
            Customized travel schedules with
          </p>
          <p className="text-gray-600 font-normal">
            day-wise plans and activities test
          </p>
        </div>

        {/* Total Packages */}
        <div
          className={`col-span-12 sm:col-span-6 ${itinerarySpan} bg-white rounded-lg shadow p-4 text-center flex flex-col justify-center items-center`}
        >
          <h3 className="text-[16px] font-semibold">Total Packages</h3>
          <div className="text-primary-black text-[28px] font-semibold">
            {packageCount}
          </div>
          <p className="text-gray-600 font-normal">
            Ready-to-book travel offerings with
          </p>
          <p className="text-gray-600 font-normal">
            complete pricing and inclusions
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

import React, { useEffect, useState } from "react";
import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import { get } from "../utils/api";
import { useParams } from "react-router-dom";

const PackageViewLogs = () => {
  const [auditLogs, setAuditLogs] = useState([]);
  const { packagedetailsid } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAuditLogs();
  }, []);

  const fetchAuditLogs = async () => {
    try {
      setLoading(true);
      const response = await get(
        `/getAllPackageAudit?packagedetailsid=${packagedetailsid}`
      );
      if (response.status && response.data) {
        const parsedLogs = response.data.map((log) => ({
          ...log,
          updateddata:
            typeof log.updateddata === "string"
              ? JSON.parse(log.updateddata)
              : log.updateddata,
        }));
        setAuditLogs(parsedLogs);
      }
    } catch (error) {
      console.error("Error fetching audit logs:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h2 className="text-primary-black text-[24px] font-semibold">
            View Log
          </h2>
          <p className="text-[12px]">
            <span className="font-bold">Package_{packagedetailsid}</span> /
            <span className="font-regular">View log</span>
          </p>
        </div>

        <div className="flex gap-4 mt-4 md:mt-0 justify-end">
          <Button type="primary" label="Download Log" />
        </div>
      </div>
      <Paper className="my-8 pt-6 pb-6">
        {loading ? (
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        ) : auditLogs.length > 0 ? (
          auditLogs.map((log) => (
            <div
              key={log.auditid}
              className="grid gap-2 grid-cols-1 mb-6 hover:bg-gray-50 p-4 rounded-lg transition-all"
            >
              <div className="flex flex-col md:flex-row items-start md:items-center bg-gray-100 p-3 rounded-lg w-fit shadow-sm">
                <h3 className="text-sm font-bold text-gray-700">
                  Changes on {log.updateddata?.packagename || "Package"}
                </h3>
                <span className="mt-1 md:mt-0 md:ml-2 text-sm text-gray-500">
                  | By {log.changed_by} On {formatDate(log.createdon)}
                </span>
              </div>

              <div className="border-l-2 border-gray-400 pl-4 mt-2">
                <div
                  className={`${
                    log.updateddata?.type === "edited"
                      ? "bg-[#F9834E]"
                      : "bg-green-700"
                  } text-white text-xs px-3 py-1 inline-block rounded-full font-medium`}
                >
                  {(log.updateddata?.type || "updated")
                    .charAt(0)
                    .toUpperCase() +
                    (log.updateddata?.type || "updated").slice(1)}
                </div>
                <div className="mt-3 space-y-2">
                  {log.updateddata &&
                    typeof log.updateddata === "object" &&
                    Object.entries(log.updateddata).map(
                      ([key, value]) =>
                        key !== "type" && (
                          <p key={key} className="flex items-center py-1">
                            <span className="font-semibold text-[14px] text-gray-800 w-48">
                              {key
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </span>
                            <span className="text-gray-600">
                              {value instanceof Date
                                ? formatDate(value)
                                : String(value)}
                            </span>
                          </p>
                        )
                    )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-64">
            <div className="text-6xl mb-4">📝</div>
            <h3 className="text-xl font-semibold text-gray-700 mb-2">
              No Logs Found
            </h3>
            <p className="text-gray-500">
              There are no activity logs available for this package.
            </p>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default PackageViewLogs;
